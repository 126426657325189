import '@assets/sharedStyles/formItems.scss';
import '@assets/sharedStyles/pageItems.scss';
import { Tabs } from 'antd';
import ITSStakeHolders from './Tabs/ITSStakeHoldersTab';
import UsersActivity from './Tabs/UsersActivityTab';
import Users from './Tabs/UsersTab';

function Pharmacy() {
  const tabItems = [
    {
      key: 'Pharmacy1',
      label: 'Kullanıcılar',
      children: <Users />
    },
    {
      key: 'Pharmacy2',
      label: 'Eczane Hareketleri',
      children: <UsersActivity />
    },
    {
      key: 'Pharmacy3',
      label: 'İTS Paydaşları',
      children: <ITSStakeHolders />
    }
  ];

  return (
    <div className="kemi_pages_tabs">
      <Tabs defaultActiveKey="Pharmacy1" items={tabItems} />
    </div>
  );
}

export default Pharmacy;
