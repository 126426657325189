import { CaretLeftOutlined } from '@ant-design/icons';
import PharmacyRequestAddProduct from '@src/components/pharmacy_components/pharmacy_requests/PharmacyRequestAddProduct/PharmacyRequestAddProduct';
import ReadTextFromTxt from '@src/components/shared/ReadTextFromTxt/ReadTextFromTxt';
import _ from 'lodash';

const UploadExcelPage = ({
  requestData,
  setRequestData,
  setCurrentStep,
  currentStep
}) => {
  function decideDesc() {
    return requestData.type === 'STOCK'
      ? 'İlgili ürüne ait zarara uğrayan karekodları yükleyiniz. Firmanın bilgilendirici duyurularına Duyurular kısmından ulaşabilirsiniz.'
      : 'Kamu kurum iskontosu geri ödemesine ait karekodları yükleyiniz. Firmanın bilgilendirici duyurularına Duyurular kısmından ulaşabilirsiniz.';
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between'
      }}
    >
      <h2 className="pharmacyNewRequest__title">{requestData.moduleName}</h2>

      <h3 className="pharmacyNewRequest__desc">{decideDesc()}</h3>
      <h3
        className="pharmacyNewRequest__desc"
        style={{ color: 'red', fontWeight: 'bold' }}
      >
        Tek başvuruda birden fazla ürün yükleyebilirsiniz!
      </h3>
      {requestData.type === 'STOCK' ? undefined : (
        <h3
          className="pharmacyNewRequest__desc"
          style={{ color: 'red', fontWeight: 'bold' }}
        >
          Yalnızca İTS'ye satış bildirimi yaptığınız ürünleri yükleyiniz!
        </h3>
      )}
      <div className="pharmacyNewRequest_readtext">
        <ReadTextFromTxt
          requestData={requestData}
          setRequestData={setRequestData}
        />
      </div>
      <h2 className="pharmacyNewRequest__desc_secondary">veya</h2>
      <PharmacyRequestAddProduct
        requestData={requestData}
        setRequestData={setRequestData}
        setCurrentStep={setCurrentStep}
      />
      <button
        shape="round"
        className="pharmacyNewRequest__button kemi_form_icon_button_ghost"
        style={{ width: '90px' }}
        onClick={() => {
          setCurrentStep(currentStep - 1);
          if (requestData.type === 'STOCK') {
            setRequestData(
              _.omit(requestData, [
                'barcodes',
                'wholesalerId',
                'moduleGtins',
                'moduleId',
                'type',
                'wholesalerId'
              ])
            );
          } else {
            setRequestData(
              _.omit(requestData, [
                'barcodes',
                'wholesalerId',
                'moduleGtins',
                'moduleId',
                'type'
              ])
            );
          }
        }}
      >
        <CaretLeftOutlined /> Geri
      </button>
    </div>
  );
};
export default UploadExcelPage;
