import { Divider, Row } from 'antd';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

// modal content for ReadExcelFromXls

function ModalContent({ rejected, accepted }) {
  return (
    <div>
      <h4>Ecza Deposu Yükleme Sonucu</h4>
      {accepted.length > 0 && (
        <>
          <Divider />
          <h3>Uygun Formattaki Depolar</h3>
          <div
            style={{
              fontSize: '13px',
              borderRadius: '8px',
              backgroundColor: '#82ce34',
              padding: '10px',
              color: '#444',
              maxHeight: '350px',
              overflow: 'auto',
            }}
          >
            {accepted.map((warehouse) => {
              return (
                <Row justify="space-between" key={uuidv4()}>
                  <div>{warehouse.name ? warehouse.name : 'isim yok'} </div>
                  <div>{warehouse.gln ? warehouse.gln : 'GLN yok'}</div>
                </Row>
              );
            })}
          </div>
        </>
      )}

      {rejected.length > 0 && (
        <>
          <Divider />
          <h3>Formata Uymayan Depolar</h3>
          <div
            style={{
              fontSize: '13px',
              borderRadius: '8px',
              backgroundColor: '#f15e5e',
              padding: '10px',
              color: '#444',
              maxHeight: '350px',
              overflow: 'auto',
            }}
          >
            {rejected.map((warehouse) => {
              return (
                <Row justify="space-between" key={uuidv4()}>
                  <div>{warehouse.name ? warehouse.name : 'isim yok'} </div>
                  <div>{warehouse.gln ? warehouse.gln : 'GLN yok'}</div>
                </Row>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default ModalContent;
