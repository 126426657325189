import { axios_get } from '@src/utils/rest_axios';
import { Empty } from 'antd';
import { useEffect, useState } from 'react';
import { CollapseNotificationCompany } from './CollapseNotificationCompany';

export const ExistingNotifications = ({ callback, setCallback }) => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    async function fetchNotifications() {
      const result = await axios_get('/companypages/notifications');
      setNotifications(result.data);
    }
    fetchNotifications();
  }, [callback]);
  return notifications.length > 0 ? (
    notifications.map((notification) => {
      return (
        <CollapseNotificationCompany
          key={notification.notificationId}
          notification={notification}
          callback={callback}
          setCallback={setCallback}
        />
      );
    })
  ) : (
    <Empty />
  );
};
