import { usePost } from '@src/api/methods';
import { getColumnSearchProps } from '@src/utils/getColumnSearchProps';
import { Button, Table } from 'antd';
import { useEffect, useRef, useState } from 'react';
import './UsersTab.scss';
import NewCompanyModal from './UsersTab/NewCompanyModal';
import NewUserModal from './UsersTab/NewUserModal';

function UsersTab() {
  const [isNewCompanyUserModalVisible, setIsNewCompanyUserModalVisible] =
    useState(false);
  const [isNewCompanyModalVisible, setIsNewCompanyModalVisible] =
    useState(false);
  const { data, loading, mutate } = usePost('admin/users');

  useEffect(() => {
    mutate({
      userType: 'WORKER'
    });
  }, [mutate]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'İsim - Soyisim',
      key: 'nameAndSurname',
      render: (record) => `${record.name} ${record.surname}`
    },
    {
      title: 'Firma',
      dataIndex: ['company', 'name'],
      key: 'companyName'
    },
    {
      title: 'E-Posta',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps(
        'email',
        handleSearch,
        handleReset,
        searchInput,
        searchText,
        searchedColumn
      )
    },

    { title: 'Üyelik Tarihi', dataIndex: 'createdAt', key: 'createdAt' }
  ];

  const handleNewCompanyUserModalVisible = () => {
    setIsNewCompanyUserModalVisible(false);
  };

  const handleNewCompanyModalVisible = () => {
    setIsNewCompanyModalVisible(false);
  };

  return (
    <div className="company-users-tab__">
      <div className="buttons-container">
        <Button
          style={{ width: 150, marginRight: '15px' }}
          className="kemi_form_btn_purple"
          onClick={() => setIsNewCompanyModalVisible(true)}
        >
          Yeni Firma Oluştur
        </Button>
        <Button
          style={{ width: 160 }}
          className="kemi_form_btn_purple"
          onClick={() => setIsNewCompanyUserModalVisible(true)}
        >
          Yeni Kullanıcı Oluştur
        </Button>
      </div>
      <Table
        scroll={{ x: 1499 }}
        columns={columns}
        dataSource={data}
        loading={loading}
      />
      <NewCompanyModal
        key={'adminNewCompany'}
        handleModalVisible={handleNewCompanyModalVisible}
        isModalVisible={isNewCompanyModalVisible}
      />
      <NewUserModal
        key={'companyNewUser'}
        handleModalVisible={handleNewCompanyUserModalVisible}
        isModalVisible={isNewCompanyUserModalVisible}
        refecthCompanyUsers={mutate}
      />
    </div>
  );
}

export default UsersTab;
