import '@assets/sharedStyles/formItems.scss';
import { Empty } from 'antd';
import { useEffect, useState } from 'react';
import FakeItsFormPage from './FakeItsFormPage';
import './FakePharmacyNewRequest.scss';
import FakeResultPage from './FakeResultPage';
import FakeSelectModulePage from './FakeSelectModulePage';
import FakeUploadExcelPage from './FakeUploadExcelPage';
import FakeSelectCompanies from './components/SelectCompanies/FakeSelectCompanies';

const FakePharmacyNewRequest = ({ setNewRequestInfo }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [requestData, setRequestData] = useState({}); // todo add attr as null but just to be cleanbarcodes: []
  const [itsResult, setITSResult] = useState(undefined);
  useEffect(() => {
    setNewRequestInfo((preValue) => ({ ...preValue, isOpen: true }));
  }, []);
  useEffect(() => {
    setNewRequestInfo((preValue) => ({
      ...preValue,
      currentStep: currentStep
    }));
  }, [currentStep]);

  function decideStep() {
    if (currentStep === 1) {
      return (
        <FakeSelectCompanies
          requestData={requestData}
          setCurrentStep={setCurrentStep}
          setRequestData={setRequestData}
        />
      );
    } else if (currentStep === 2) {
      return (
        <FakeSelectModulePage
          currentStep={currentStep}
          requestData={requestData}
          setCurrentStep={setCurrentStep}
          setRequestData={setRequestData}
        />
      );
    } else if (currentStep === 3) {
      return (
        <FakeUploadExcelPage
          setRequestData={setRequestData}
          setCurrentStep={setCurrentStep}
          requestData={requestData}
          currentStep={currentStep}
        />
      );
    } else if (currentStep === 4) {
      return (
        <FakeItsFormPage
          setRequestData={setRequestData}
          setCurrentStep={setCurrentStep}
          requestData={requestData}
          currentStep={currentStep}
          setITSResult={setITSResult}
        />
      );
    } else if (currentStep === 5) {
      return <FakeResultPage requestData={requestData} />;
    } else {
      return (
        <div>
          <Empty />
        </div>
      );
    }
  }
  return (
    <div>
      {/* <Grid container direction="column" columns={3} alignItems="flex-start">
        {decideStep()}
      </Grid>
      <Stepper
        className="fakePharmacyNewRequest__stepper"
        activeStep={currentStep - 1}
        alternativeLabel
      >
        <Step key="1">
          <StepLabel>Firma Seçimi</StepLabel>
        </Step>
        <Step key="2">
          <StepLabel>İşlem Seçimi</StepLabel>
        </Step>
        <Step key="3">
          <StepLabel>
            Barkod Yükleme
            <br /> Depo Seçimi
          </StepLabel>
        </Step>
        <Step key="4">
          <StepLabel>Doğrulama</StepLabel>
        </Step>
        <Step key="5">
          <StepLabel>Sonuç</StepLabel>
        </Step>
      </Stepper> */}
    </div>
  );
};

export default FakePharmacyNewRequest;
