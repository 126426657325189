import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import '@assets/sharedStyles/formItems.scss';
import { useConfirmationDialog } from '@src/components/shared/ConfirmationPopup/ConfirmationDialog';
import { store } from '@src/store';
import {
  GTIN_NUMBER_DIGIT_MESSAGE,
  GTIN_NUMBER_PATTERN_MESSAGE,
  GTIN_NUMBER_REGEX,
  GTIN_NUMBER_REQUIRED_MESSAGE
} from '@src/utils/formRules/formRules';
import { axios_patch } from '@utils/rest_axios';
import { Button, Form, Input, message } from 'antd';
import axios from 'axios';
import './UpdateProductControlAdmin.scss';
const UpdateProductControlAdmin = ({
  companyProduct,
  callBack,
  companyId,
  reload
}) => {
  const [form] = Form.useForm();
  const { getConfirmation } = useConfirmationDialog();
  // for update
  const onFinish = async (values) => {
    const data = {
      gtin: values.gtin,
      companyId: companyId
    };
    if (values.gtin !== companyProduct.gtin) {
      try {
        const productControlUpdateRes = await axios_patch(
          `/admin/companies-products/${companyProduct.productCompanyId}`,
          data
        );
        if (productControlUpdateRes.status === 200) {
          callBack(!reload);
          message.success('Güncelleme Başarılı!', 5);
        }
      } catch ({ response }) {
        message.error('Güncelleme Başarısız!', 5);
      }
    } else {
      message.warning('Değişiklik Bulunmuyor!', 5);
    }
  };
  // for delete
  const handleDelete = async () => {
    const confirmed = await getConfirmation({
      title: 'GTIN’i silmek istediğinizden emin misiniz?',
      message: '',
      cancelText: 'Vazgeç',
      confirmText: 'Onayla'
    });
    if (confirmed) {
      try {
        await axios.delete(
          `/admin/companies-products/${companyProduct.productCompanyId}`,
          {
            data: {
              companyId: companyId
            },
            headers: { Authorization: `Bearer ${store.getState().auth.token}` }
          }
        );

        callBack(!reload);
        message.success('GTIN Silme Başarılı!', 5);
      } catch ({ response }) {
        message.error('GTIN Silinemedi', 5);
      }
    }
  };
  return (
    <>
      <Form
        initialValues={{ gtin: companyProduct.gtin }}
        form={form}
        name="update-product-control"
        onFinish={onFinish}
      >
        <div className="updateProductControl">
          <Form.Item
            name="gtin"
            label="GTIN"
            rules={[
              {
                required: true,
                message: GTIN_NUMBER_REQUIRED_MESSAGE
              },
              {
                min: 14,
                message: GTIN_NUMBER_DIGIT_MESSAGE
              },
              {
                max: 14,
                message: GTIN_NUMBER_DIGIT_MESSAGE
              },
              {
                pattern: GTIN_NUMBER_REGEX,
                message: GTIN_NUMBER_PATTERN_MESSAGE
              }
            ]}
          >
            <Input className="kemi_form_input" />
          </Form.Item>
          <Form.Item>
            <div className="updateProductControl__buttons">
              <Button
                className="kemi_form_btn_purple form_btn_margin"
                shape="round"
                htmlType="submit"
                type="primary"
              >
                <EditOutlined />
                Güncelle
              </Button>
              <Button
                className="kemi_form_btn_purple form_btn_margin"
                shape="round"
                danger
                type="secondary"
                onClick={handleDelete}
              >
                <DeleteOutlined /> GTIN Sil
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default UpdateProductControlAdmin;
