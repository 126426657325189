import { Form, Input } from 'antd';

export const ModuleNotFieldFormItem = () => {
  return (
    <Form.Item name="moduleNote">
      <Input.TextArea
        className="kemi_form_input_grey"
        placeholder="Modül açıklaması girin"
        autoSize={{ minRows: 3, maxRows: 5 }}
      />
    </Form.Item>
  );
};
