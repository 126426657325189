import '@assets/sharedStyles/modal.scss';
import {
  GLN_NUMBER_DIGIT_MESSAGE,
  GLN_NUMBER_PATTERN_MESSAGE,
  GLN_NUMBER_REGEX,
  GLN_NUMBER_REQUIRED_MESSAGE
} from '@src/utils/formRules/formRules';
import { axios_post_public } from '@src/utils/rest_axios';
import { Button, Form, Input, Modal } from 'antd';
import { useState } from 'react';
import { GrFormClose } from 'react-icons/gr';

const PharmacyLoginIssuesFlowModals = ({
  isModalVisible,
  errorMessage,
  handleModalVisible,
  inactivePharmacyValues
}) => {
  const [step, setStep] = useState(0);
  const [glnResponse, setGlnResponse] = useState();
  const [currentGln, setCurrentGln] = useState('');
  const [formGLNRequest] = Form.useForm();

  const handleGLNRequest = async (value) => {
    setCurrentGln(value.gln);
    try {
      const response = await axios_post_public('/gln', { gln: value.gln });
      setGlnResponse(response.data);
    } catch (error) {
      throw error;
    } finally {
      setStep(1);
    }
  };

  const steps = {
    AccountNotActiveError: [
      {
        step: 0,
        title: 'Aktif Edilmemiş Üyelik',
        context: (
          <div className="modal-context-container">
            <p
              className="modal-context-info"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <span>Üyeliğiniz aktif görünmemektedir.</span>
              <span style={{ marginTop: '5px' }}>
                Siteye giriş yapmak ve şifre sıfırlayabilme işlemleri için
                öncelikle üyeliğiniz aktive etmelisiniz.
              </span>
              <span style={{ marginTop: '5px' }}>
                {inactivePharmacyValues?.gln} GLN'niz ile eşleşmiş İTS'ye
                kayıtlı {inactivePharmacyValues?.email} adresinize aktivasyon
                maili tekrar yönlendirilmiştir.
              </span>
              <span style={{ marginTop: '5px' }}>
                Spam/gereksiz klasörünüzü kontrol etmeyi unutmayınız. Linkin
                geçerliliği 1 gündür.
              </span>
            </p>
            <div className="modal-context-footer">
              <Button
                className="modal-button"
                onClick={() => handleModalVisible((value) => !value)}
              >
                Tamam
              </Button>
            </div>
          </div>
        )
      }
    ],
    NoUserFoundError: [
      {
        step: 0,
        title: 'Kayıtlı Olmayan E-posta',
        context: (
          <div className="modal-context-container">
            <p className="modal-context-info">
              Bu e-posta adresine bağlı bir üyelik bulunmamaktadır.
            </p>
            <Form
              className="modal-context-form"
              form={formGLNRequest}
              onFinish={handleGLNRequest}
            >
              <Form.Item
                name="gln"
                rules={[
                  {
                    required: true,
                    message: GLN_NUMBER_REQUIRED_MESSAGE
                  },
                  {
                    min: 13,
                    message: GLN_NUMBER_DIGIT_MESSAGE
                  },
                  {
                    max: 13,
                    message: GLN_NUMBER_DIGIT_MESSAGE
                  },
                  {
                    pattern: GLN_NUMBER_REGEX,
                    message: GLN_NUMBER_PATTERN_MESSAGE
                  }
                ]}
              >
                <Input placeholder="868XXXXXXXXXX" />
              </Form.Item>
            </Form>
            <div className="modal-context-footer">
              <Button
                className="modal-button"
                onClick={() => formGLNRequest.submit()}
              >
                GLN ile kontrol et
              </Button>
            </div>
          </div>
        )
      },
      {
        step: 1,
        title: 'Kayıtlı Olmayan E-posta',
        context: (
          <div className="modal-context-container">
            {glnResponse ? (
              <p className="modal-context-info">
                {currentGln} GLN ile ilişkilendirilmiş mail adresi{' '}
                {glnResponse.email} olarak görünmektedir. Bu adresle giriş
                sağlayabilirsiniz veya şifre sıfırlama linkini bu adrese talep
                edebilirsiniz. Değişiklik ihtiyacı halinde sağ alt köşede
                bulunan destek hattından bizimle iletişime geçiniz.
              </p>
            ) : (
              <p className="modal-context-info">
                Bu GLN ile eşleşen bir üyelik bulunmamaktadır. Giriş yapabilmek
                için öncelikle Kaydol butonundan üye olup üyeliğinizi aktive
                etmeniz gerekmektedir.
              </p>
            )}
            <div className="modal-context-footer">
              <Button
                className="modal-button"
                onClick={() => {
                  handleModalVisible((value) => !value);
                  setGlnResponse(null);
                  setStep(0);
                }}
              >
                Tamam
              </Button>
            </div>
          </div>
        )
      }
    ]
  };

  const currentStep = steps[errorMessage] ? steps[errorMessage][step] : null;

  const CloseIcon = () => (
    <div className="close-icon-container">
      <GrFormClose size={25} color="#fffafe" />
    </div>
  );

  return (
    <Modal
      className="modal"
      centered
      open={isModalVisible}
      closeIcon={<CloseIcon />}
      footer={null}
      title={currentStep?.title}
      onCancel={() => handleModalVisible()}
    >
      {currentStep && currentStep.context}
    </Modal>
  );
};

export default PharmacyLoginIssuesFlowModals;
