export const errors = {
  200: {
    OK: {
      en: 'Okay',
      tr: {
        title: 'Geçerli İstek',
        message: 'Ok',
        color: 'default',
      },
    },
  },
  400: {
    InvalidOrExpiredTokenError: {
      en: 'Invalid or expired Token',
      tr: {
        title: 'Hatalı Giriş',
        message: 'Lütfen tekrar deneyiniz.',
        color: 'default',
      },
    },
    WrongITSCredentialsError: {
      en: 'Wrong ITS login credentials',
      tr: {
        title: 'Hatalı Giriş',
        message:
          'Girmiş olduğunuz İTS kullanıcı adı veya şifrede hata bulunmaktadır. Lütfen doğru bilgileri giriniz.',
        color: 'default',
      },
    },
    UserAlreadyActiveError: {
      en: 'UserAlreadyActiveError',
      tr: {
        title: 'Hata',
        message: 'Üye Zaten Aktif!',
        color: 'default',
      },
    },
    UserAlreadyExists: {
      en: 'User Already Exists',
      tr: {
        title: 'Hata',
        message: 'Bu bilgilere ait bir hesap zaten bulunmaktadır.',
        color: 'danger',
      },
    },
  },
  401: {
    UserNotAuthorizedError: {
      en: 'Unauthorized',
      tr: {
        title: 'Yetkilendirilmemiş İstek',
        message: 'İlgili işleme ait yetkiniz bulunmamaktadır.',
        color: 'default',
      },
    },
    Unauthorized: {
      en: 'Unauthorized',
      tr: {
        title: 'Yetkilendirilmemiş İstek',
        message: 'İlgili işleme ait yetkiniz bulunmamaktadır.',
        color: 'default',
      },
    },
    UnauthorizedError: {
      en: 'This user can not do this work!',
      tr: {
        title: 'Yetkilendirilmemiş İstek',
        message: 'İlgili işleme ait yetkiniz bulunmamaktadır.',
        color: 'default',
      },
    },
    WrongCredentialsError: {
      en: 'Wrong Credentials Error',
      tr: {
        title: 'Yanlış Giriş Bilgileri',
        message: 'Bilgilerinizi kontrol edip lütfen tekrar deneyiniz.',
        color: 'danger',
      },
    },
  },
  403: {
    Forbidden: {
      en: 'UnauthorizedError',
      tr: {
        title: 'Hatalı İstek',
        message: 'İlgili işleme ait yetkiniz bulunmamaktadır.',
        color: 'danger',
      },
    },
    AccountNotActive: {
      en: 'AccountNotActive',
      tr: {
        title: 'Hesabınız aktive edilmemiştir',
        message:
          'Lütfen mail adresinize ulaştırılan aktivasyon mailini kontrol ediniz.',
        color: 'danger',
      },
    },
  },
  404: {
    NoModuleFound: {
      en: 'Module Not Found',
      tr: {
        title: 'Hata',
        message: 'İşlem yapılmak istenen modül bulunamadı.',
        color: 'danger',
      },
    },
    NoNotificationFoundError: {
      en: 'Notification not found',
      tr: {
        title: 'Hata',
        message: 'İşlem yapılmak istenen duyuru bulunamadı.',
        color: 'danger',
      },
    },
    NoProductCompanyFound: 'NoProductCompanyFound',
    CompanyNotFound: 'Company Not Found',
    'No User found with this email': '*******Not found.',
    'No User or Refresh token found': '*****No User or Refresh token found',
    NoUserFoundError: {
      en: 'No User Found',
      tr: {
        title: 'Hata',
        message: 'Aranan kullanıcı bulunamadı.',
        color: 'danger',
      },
    },
    'No request could be found': '******No request could be found',
    'No Notification could be found!': '*****No Notification could be found!',
    NoWholesalerFoundError: {
      en: 'No Wholesaler Found',
      tr: {
        title: 'Hata',
        message: 'Herhangi bir ecza deposu bulunamadı.',
        color: 'danger',
      },
    },
    NoWholesalerFound: {
      en: 'No Wholesaler Found',
      tr: {
        title: 'Hata',
        message: 'Herhangi bir ecza deposu bulunamadı.',
        color: 'danger',
      },
    },
    StakeholderDoesNotExist: {
      en: 'StakeholderDoesNotExist',
      tr: {
        title: 'Hata',
        message: 'Aramanızla eşleşen bir paydaş bulunamadı.',
        color: 'danger',
      },
    },
    StakeholderDoesNotExistsError: {
      en: 'StakeholderDoesNotExistsError',
      tr: {
        title: 'Hata',
        message: 'Aramanızla eşleşen bir paydaş bulunamadı.',
        color: 'danger',
      },
    },
    NoRequestFoundError: {
      en: 'NoRequestFoundError',
      tr: {
        title: 'Hata',
        message: 'Aramanızla eşleşen bir istek bulunamadı.',
        color: 'danger',
      },
    },
    NoRequestFound: {
      en: 'NoRequestFoundError',
      tr: {
        title: 'Hata',
        message: 'Aramanızla eşleşen bir istek bulunamadı.',
        color: 'danger',
      },
    },
  },
  409: {
    UserAlreadyExists: {
      en: 'User Already Exists',
      tr: {
        title: 'Hata',
        message: 'Bu bilgilere ait bir hesap zaten bulunmaktadır.',
        color: 'danger',
      },
    },
  },
  500: {
    InternalServerError: {
      en: 'Internal Server Error',
      tr: {
        title: 'Hata',
        message:
          'Beklenmeyen bir hata ile karşılaşıldı. Daha sonra tekrar deneyiniz.',
        color: 'danger',
      },
    },
    PasswordResetRequired: {
      en: 'Password Reset Required',
      tr: {
        title: 'Hata',
        message:
          'İTS kullanıcı bilgileriniz güncel olmadığı için işleme devam edilememiştir. Bilgilerinizi its.gov.tr adresi üzerinden güncelledikten sonra tekrar başvuru yapmanızı rica ederiz.',
        color: 'danger',
      },
    },
    UsernameOrPasswordFalseError: {
      en: 'Username or password is false',
      tr: {
        title: 'Hatalı Giriş',
        message:
          'Girmiş olduğunuz İTS kullanıcı adı veya şifrede hata bulunmaktadır. Lütfen doğru bilgileri giriniz.',
        color: 'default',
      },
    },
  },
};

// export function getErrorMessage(response) {
//   if (response.status === 404 && !response.data.name) {
//     return {
//       title: 'Hata',
//       message:
//         'Beklenmeyen bir hata ile karşılaşıldı. Daha sonra tekrar deneyiniz.',
//       confirmText: 'Tamam',
//       type: 'danger',
//     };
//   }

//   const correspondingError =
//     errors[response.data.statusCode][response.data.name].tr ||
//     errors[500].InternalServerError.tr;

//   return {
//     title: correspondingError.title,
//     message: correspondingError.message,
//     confirmText: 'Tamam',
//     type: correspondingError.color,
//   };
// }

export function getErrorMessage(response) {
  const { status, data } = response;

  // Default error message in case of an undefined error
  const defaultErrorMessage = {
    title: 'Hata',
    message:
      'Beklenmeyen bir hata ile karşılaşıldı. Daha sonra tekrar deneyiniz.',
    confirmText: 'Tamam',
    type: 'danger',
  };

  if (status === 404 && !data.name) {
    return defaultErrorMessage;
  }

  // If the status code or error name doesn't exist in the errors object, return the default error message
  if (!errors[data.statusCode] || !errors[data.statusCode][data.name]) {
    return defaultErrorMessage;
  }

  const correspondingError =
    errors[data.statusCode][data.name].tr || errors[500].InternalServerError.tr;

  return {
    title: correspondingError.title,
    message: correspondingError.message,
    confirmText: 'Tamam',
    type: correspondingError.color,
  };
}
