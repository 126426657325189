import { Layout } from 'antd';
import './Layouts.scss';
// components
import Footer from '@src/components/public_components/Footer';
import PharmacyNotificationContextProvider from '@src/components/shared/PharmacyNotificationContextProvider/PharmacyNotificationContextProvider';
import UserContextProvider from '@src/components/shared/UserContextProvider/UserContextProvider';
import { FloatButton } from 'antd';
import LayoutHeader from '../components/shared/LayoutHeader/LayoutHeader';
import Navbar from '../components/shared/Navbar/Navbar';

const PharmacyLayout = ({ children }) => {
  return (
    <UserContextProvider>
      <PharmacyNotificationContextProvider>
        <Layout className="layout">
          <Navbar />
          <Layout className="layout__background">
            <LayoutHeader />
            <div id="page-container">
              {children}
              <FloatButton.BackTop />
            </div>
            <Footer isAuthenticated={true} />
          </Layout>
        </Layout>
      </PharmacyNotificationContextProvider>
    </UserContextProvider>
  );
};

export default PharmacyLayout;
