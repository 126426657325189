import { useEffect, useState } from 'react';
import Tour from 'reactour';
import './TutorialTour.scss';
import steps from './steps';
function TutorialTour({ newRequestInfo }) {
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [step, setStep] = useState(0);
  const [showButtons, setShowButtons] = useState(true);

  // if fake pharmacy new request steps changed then handle tour steps
  useEffect(() => {
    handlePharmacyNewRequestSteps();
    return () => enableBodyScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newRequestInfo]);

  const closeTour = () => {
    setIsTourOpen(false);
  };

  // disable scrolling to avoid any problem
  const disableBodyScroll = () => {
    document.body.style.overflow = 'hidden';
  };
  // enable scrolling after tutorial tour
  const enableBodyScroll = () => {
    document.body.style.overflow = 'auto';
  };

  const handlePharmacyNewRequestSteps = () => {
    disableBodyScroll();
    if (!newRequestInfo.isOpen) {
      return;
    }
    setIsTourOpen(false);

    setTimeout(() => {
      switch (newRequestInfo.currentStep) {
        case 1:
          setStep(4);
          setShowButtons(false);
          break;
        case 2:
          setStep(5);
          setShowButtons(false);
          break;
        case 3:
          setStep(6);
          setShowButtons(true);
          break;
        case 4:
          setStep(11);
          setShowButtons(true);
          break;
        case 5:
          setStep(13);
          setShowButtons(true);
          break;
        default:
      }
      setIsTourOpen(true);
    }, 300);
  };
  // if change step then handle tour steps
  const handleCurrentStep = (currentStep) => {
    switch (currentStep) {
      case 3:
        setShowButtons(false);
        break;
      case 9:
        setShowButtons(false);
        setTimeout(() => {
          setIsTourOpen(false);
          setTimeout(() => {
            setStep(10);
            setIsTourOpen(true);
          }, 300);
        }, 4500);
        break;
      case 12:
        setShowButtons(false);
        break;
      default:
    }
  };

  const BackButton = <div style={{ display: 'none' }}></div>;

  const CloseButton = (
    <button
      className="tutorial_tour__done_button"
      onClick={() => {
        window.location.href = '/basvurular';
      }}
    >
      Bekleyen Başvurular
    </button>
  );

  return (
    <Tour
      getCurrentStep={(currentStep) => handleCurrentStep(currentStep)}
      isOpen={isTourOpen}
      steps={steps}
      onRequestClose={closeTour}
      startAt={step}
      maskSpace={15}
      showButtons={showButtons}
      disableDotsNavigation={true}
      className="tutorial_tour"
      closeWithMask={false}
      showCloseButton={false}
      lastStepNextButton={CloseButton}
      prevButton={BackButton}
    />
  );
}

export default TutorialTour;
