import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import { axios_get } from '@utils/rest_axios';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import './Dashboard.scss';
import { GraphCard } from './GraphCard';
import InfoCard from './StatComponents/InfoCard';
import KKIStatCard from './StatComponents/KKIStatCard';
import StocklossStatCard from './StatComponents/StocklossStatCard';
import { TotalUserStatCard } from './TotalUserStatCard';

const Dashboard = () => {
  const [stats, setStats] = useState({});

  const { getNotification } = useNotificationPopup();

  useEffect(() => {
    async function getCompanyStats() {
      try {
        const result = await axios_get('/companypages/stats');
        setStats(result.data);
      } catch ({ response }) {
        if (response && response.status !== 401) {
          getNotification({
            ...getErrorMessage(response)
          });
        }
      }
    }

    getCompanyStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="companyDashboard">
      <InfoCard stats={stats} />
      <Row justify="space-around">
        <Col xs={16} sm={16} md={12} lg={12} xl={12} xxl={8}>
          <Row justify="center">
            <KKIStatCard stats={stats} />
          </Row>
        </Col>
        <Col xs={16} sm={16} md={12} lg={12} xl={12} xxl={8}>
          <Row justify="center">
            <StocklossStatCard stats={stats} />
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={8}>
          <Row justify="center">
            <TotalUserStatCard stats={stats} />
          </Row>
        </Col>
      </Row>
      <GraphCard stats={stats} />
    </div>
  );
};

export default Dashboard;
