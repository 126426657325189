import { EditOutlined } from '@ant-design/icons';
import { REQUIRED_INPUT_MESSAGE } from '@src/utils/formRules/formRules';
import { axios_patch } from '@utils/rest_axios';
import { Button, Form, Input, message } from 'antd';
import './UpdateProductName.scss';

const UpdateProductName = ({
  product_name,
  companyProducts,
  callBack,
  reload
}) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    if (values.name !== product_name) {
      async function update(moduleProductCompany) {
        const data = {
          name: values.name
        };
        const productCompanyId =
          moduleProductCompany.company_product.productCompanyId;

        await axios_patch(`/companies-products/${productCompanyId}`, data);
      }
      for (let moduleProductCompany of companyProducts) {
        update(moduleProductCompany)
          .then((response) => {
            message.success('Güncelleme Başarılı!', 5);
            callBack(!reload);
          })
          .catch((err) => {
            message.error('Güncelleme Başarısız!', 5);
          });
      }
    } else {
      message.warning('Değişiklik Bulunmuyor!', 5);
    }
  };
  return (
    <>
      <Form
        initialValues={{ name: product_name }}
        form={form}
        name="update-product-name"
        onFinish={onFinish}
      >
        <div className="updateProductName">
          <Form.Item
            name="name"
            label="Ürün İsmi"
            rules={[
              {
                required: true,
                message: REQUIRED_INPUT_MESSAGE
              }
            ]}
          >
            <Input className="kemi_form_input" />
          </Form.Item>
          <Form.Item>
            <div className="updateProductName__buttons">
              <Button
                className="kemi_form_btn_purple form_btn_margin"
                shape="round"
                htmlType="submit"
              >
                <EditOutlined />
                Güncelle
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default UpdateProductName;
