import { toDate } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

// we are using localized DatePicker so it gives to the selected date as localized date's midnight
// and if we send its value as default it will be show previous day when convert it epoch
// so we add local time offset but this function not cover all timezone
export function addLocalOffsetToDateObject(date) {
  const dateObject = new Date(date);
  const epoch = dateObject.getTime();

  // for now for turkey -180
  const timezoneOffset = dateObject.getTimezoneOffset();

  // may this solution not work for all timezones, carefull
  const offsetMilliseconds = Math.abs(timezoneOffset) * 60 * 1000;

  const adjustedEpoch = epoch + offsetMilliseconds;
  const dateWithOffset = new Date(adjustedEpoch);

  return dateWithOffset;
}

// default behaviour of time packages or javascript is show date value as localized
// so we need to cover this situation for showing date as UTC date
export function formatDateToUTCString(dateString, dateFormat) {
  const parsedDate = toDate(dateString);

  // Format the date string in UTC
  return formatInTimeZone(parsedDate, 'UTC', dateFormat);
}

export function formatDateToTurkish(timestamp, includeHours = false) {
  const date = new Date(+timestamp);

  // Convert the date to Turkey's time zone using toLocaleDateString
  const options = {
    timeZone: 'Europe/Istanbul',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    ...(includeHours && {
      hour: '2-digit',
      minute: '2-digit'
    })
  };

  // Format the date as dd.MM.yyyy
  return date.toLocaleDateString('tr-TR', options);
}
// When u struggle with date things
// https://stackoverflow.com/questions/2698725/comparing-date-part-only-without-comparing-time-in-javascript/38050824#38050824
