import { PharmacyNotificationContext } from '@src/components/shared/PharmacyNotificationContextProvider/PharmacyNotificationContextProvider';
import { Collapse } from 'antd';
import { useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { IoMailOpenOutline, IoMailOutline } from 'react-icons/io5';
import { axios_patch } from '@src/utils/rest_axios';

const { Panel } = Collapse;

export const CollapseNotificationPharmacy = ({ notification, arrayIndex }) => {
  const { notifications, setNotifications } = useContext(
    PharmacyNotificationContext
  );

  let collapseFlag = false;

  function collapseOpenChange() {
    if (!collapseFlag) {
      handleRead();
      collapseFlag = true;
    }
  }

  async function handleRead() {
    if (!notifications[arrayIndex]?.isRead) {
      const data = {
        notificationId: notification.notification.notificationId,
      };
      const result = await axios_patch('/pharmacypages/notifications', data);
      if (result.status === 200) {
        let draftNotifications = [...notifications];
        draftNotifications[arrayIndex].isRead = true;
        setNotifications(draftNotifications);
      }
    }
  }

  const mailReadInfoIcon = () => {
    return (
      <>
        {notification.isRead ? (
          <IoMailOpenOutline
            style={{
              fontWeight: '800',
              fontSize: '22px',
              margin: 0,
            }}
          />
        ) : (
          <IoMailOutline
            style={{
              fontWeight: '800',
              fontSize: '22px',
              color: '#0E51DA',
              margin: 0,
            }}
          />
        )}
      </>
    );
  };

  return (
    <Collapse onChange={collapseOpenChange}>
      <Panel
        header={`${notification.notification?.title} - ${notification.notification?.module?.name}`}
        extra={mailReadInfoIcon()}
        key={notification.notification?.notificationId}
      >
        <ReactMarkdown>{notification.notification?.context}</ReactMarkdown>
      </Panel>
    </Collapse>
  );
};
