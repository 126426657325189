import '@assets/sharedStyles/pageItems.scss';
import AdminStockActiveRequests from '@src/components/admin_components/pharmacyRequests/AdminStockActiveRequests/AdminStockActiveRequests';

export default function AdminStockRequestsPage({ moduleType }) {
  return (
    <div className="kemi_pages_tabs">
      <AdminStockActiveRequests moduleType={moduleType} />
    </div>
  );
}
