import { Button } from 'antd';
import React from 'react';

function TableDecideButtons({
    allRequestsCount,
    handleRequestsBulkUpdate,
    setSelectedRowRequestIds,
    setIsBulkUpdateEnable,
    isBulkUpdateEnable,
    detailCardType,
    handlePostSelectedRowsAccepted,
    handlePostSelectedRowsRejected,
    handleDeleteSelectedRows,
    handleAllPendingRequests,
}) {
    return (
        <div style={{ display: 'flex' }}>
            {isBulkUpdateEnable ? (
                <div
                    style={{
                        display: 'inline-flex',
                        flexDirection: 'column',
                        padding: '7px 7px 0px 7px',
                        marginBottom: '15px',
                    }}
                >
                    <p
                        style={{
                            display: 'inline',
                            marginBottom: '5px',
                            fontWeight: 'bolder',
                            fontSize: '15px',
                            textAlign: 'center',
                        }}
                    >
                        Toplu bildirim göndermek üzeresiniz. Mevcut toplam başvuru sayısı{' '}
                        <span style={{ color: '#193b54' }}>"{allRequestsCount}"</span>
                    </p>
                    <div>
                        <Button
                            style={{ width: '180px' }}
                            className="tablecomponent__postbtn tablecomponent__postbtn__accept"
                            onClick={() => handleRequestsBulkUpdate('ACCEPTED')}
                        >
                            Ödendi Bildirimi Gönder
                        </Button>
                        <Button
                            style={{ width: '155px' }}
                            className="tablecomponent__postbtn tablecomponent__postbtn__reject"
                            onClick={() => handleRequestsBulkUpdate('REJECTED')}
                        >
                            Ret Bildirimi Gönder
                        </Button>
                        <Button
                            className="tablecomponent__postbtn"
                            type="primary"
                            style={{ width: '150px' }}
                            onClick={() => {
                                setIsBulkUpdateEnable(false);
                                setSelectedRowRequestIds([]);
                            }}
                        >
                            Bildirimi İptal Et
                        </Button>
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        display: 'inline-flex',
                        flexDirection: 'column',
                        padding: '7px 7px 0px 7px',
                        marginBottom: '15px',
                    }}
                >
                    <div>
                        <Button
                            style={{ width: '180px' }}
                            className="tablecomponent__postbtn tablecomponent__postbtn__accept"
                            onClick={handlePostSelectedRowsAccepted}
                        >
                            Ödendi Bildirimi Gönder
                        </Button>
                        <Button
                            style={{ width: '155px' }}
                            className="tablecomponent__postbtn tablecomponent__postbtn__reject"
                            onClick={handlePostSelectedRowsRejected}
                        >
                            Ret Bildirimi Gönder
                        </Button>
                        {detailCardType === 'admin' && !isBulkUpdateEnable ? (
                            <>
                                <Button
                                    style={{ width: '100px' }}
                                    type="primary"
                                    className="tablecomponent__postbtn tablecomponent__postbtn__delete"
                                    onClick={handleDeleteSelectedRows}
                                >
                                    İsteği Sil
                                </Button>
                                <Button
                                    style={{ width: '120px' }}
                                    type="primary"
                                    className="tablecomponent__postbtn"
                                    onClick={handleAllPendingRequests}
                                >
                                    Tekrar Sorgula
                                </Button>
                            </>
                        ) : undefined}
                    </div>
                </div>
            )}
        </div>
    );
}

export default TableDecideButtons;
