import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import './landing.scss';

const Footer = () => {
  const { role, isAuthenticated } = useSelector((state) => state.auth);

  return (
    <div className="footer">
      <Row justify="space-between">
        <Col xs={24} sm={16} md={12} lg={8} className="footerColConatiner">
          <p className="footerText">
            Copyright © Kemi Portal. Tüm Hakları Saklıdır.
          </p>
        </Col>
        {isAuthenticated === true && role === 'Pharmacist' && (
          <Col xs={24} sm={16} md={12} lg={8} className="footerColConatiner">
            <a className="footerAnchorText" href="/kvkk-metni" target="_blank">
              KVKK ve Gizlilik Politikası
            </a>

            <a
              className="footerAnchorText"
              href="/uyelik-sozlesmesi"
              target="_blank"
            >
              Kullanım Koşulları
            </a>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Footer;
