import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import { axios_patch } from '@src/utils/rest_axios';
import {
  EMAIL_MESSAGE,
  EMAIL_REQUIRED,
  GLN_NUMBER_DIGIT_MESSAGE,
  GLN_NUMBER_PATTERN_MESSAGE,
  GLN_NUMBER_REGEX,
  GLN_NUMBER_REQUIRED_MESSAGE,
  PHONE_REGEX
} from '@utils/formRules/formRules';
import { Button, Form, Input, message } from 'antd';
import _ from 'lodash';
import { useEffect } from 'react';

function UpdateStakeholder({ stakeholder }) {
  const { getNotification } = useNotificationPopup();
  const [updateStakeholderForm] = Form.useForm();
  useEffect(() => {
    updateStakeholderForm.setFieldsValue({
      companyName: stakeholder.companyName,
      email: stakeholder.email,
      address: stakeholder.address,
      city: stakeholder.city,
      phone: stakeholder.phone,
      gln: stakeholder.gln
    });
  }, [stakeholder, updateStakeholderForm]);
  const SubmitForm = async (values) => {
    try {
      const updatePayload = _.omit(values, ['companyName']);
      await axios_patch(`/admin/stakeholder/${values.gln}`, updatePayload);
      message.success('Güncelleme Başarılı! Eczane guncellendi!', 5);
    } catch ({ response }) {
      if (response && response.status !== 401) {
        getNotification({
          ...getErrorMessage(response)
        });
      }
    }
  };

  return (
    <div>
      <Form
        name="updateStakeholderForm"
        colon={true}
        form={updateStakeholderForm}
        onFinish={SubmitForm}
        layout={'horizontal'}
        className="Form"
        wrapperCol={{
          md: { span: 16 }
        }}
      >
        <Form.Item
          name="gln"
          label="Eczane Gln"
          rules={[
            {
              required: true,
              message: GLN_NUMBER_REQUIRED_MESSAGE
            },
            {
              min: 13,
              message: GLN_NUMBER_DIGIT_MESSAGE
            },
            {
              max: 13,
              message: GLN_NUMBER_DIGIT_MESSAGE
            },
            {
              pattern: GLN_NUMBER_REGEX,
              message: GLN_NUMBER_PATTERN_MESSAGE
            }
          ]}
        >
          <Input
            className="kemi_form_input_grey fixed_width_input"
            placeholder="Eczane gln"
          />
        </Form.Item>
        <Form.Item
          name="companyName"
          label="Eczane ismi"
          rules={[
            {
              required: true,
              message: 'Eczane ismi giriniz.'
            }
          ]}
        >
          <Input
            className="kemi_form_input_grey fixed_width_input"
            placeholder="Eczane ismi"
            contentEditable={false}
          />
        </Form.Item>
        <Form.Item
          name="city"
          label="Sehir"
          rules={[
            {
              required: true,
              message: 'Sehir giriniz.'
            }
          ]}
        >
          <Input
            className="kemi_form_input_grey fixed_width_input"
            placeholder="Eczane Sehri"
          />
        </Form.Item>

        <Form.Item
          name="address"
          label="Adres"
          rules={[
            {
              required: true,
              message: 'Adres giriniz.'
            }
          ]}
        >
          <Input
            className="kemi_form_input_grey fixed_width_input"
            placeholder="Eczane adresi"
          />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              ...EMAIL_REQUIRED,
              message: EMAIL_MESSAGE
            }
          ]}
        >
          <Input
            className="kemi_form_input_grey fixed_width_input"
            placeholder="Eczane email"
          />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Telefon"
          rules={[
            {
              required: true,
              pattern: PHONE_REGEX,
              message: 'Lütfen geçerli bir telefon numarası giriniz.'
            }
          ]}
        >
          <Input
            className="kemi_form_input_grey fixed_width_input"
            placeholder="Eczane telefon"
          />
        </Form.Item>

        <Form.Item>
          <Button className="kemi_form_btn_dark" htmlType="submit">
            Güncelle
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default UpdateStakeholder;
