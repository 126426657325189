import '@assets/sharedStyles/modal.scss';
import { queryClient } from '@src/App';
import { usePost } from '@src/api/methods';
import { Button, Form, Input, Modal, message } from 'antd';
import { GrFormClose } from 'react-icons/gr';
function NewCompanyModal({
  isModalVisible,
  handleModalVisible,
  refecthAdminUsers
}) {
  const [newAdminUserForm] = Form.useForm();

  const { loading, mutate } = usePost('admin/companies');

  const submitForm = (values) => {
    mutate(values, {
      onSuccess: () => {
        message.success('Yeni firma oluşturuldu!', 5);
        handleModalVisible();
        newAdminUserForm.resetFields();
        queryClient.invalidateQueries(['companies']);
      },
      onError: () => {
        message.error('Yeni firma oluşturma başarısız!', 5);
      }
    });
  };

  const CloseIcon = () => (
    <div className="close-icon-container">
      <GrFormClose size={25} color="#fffafe" />
    </div>
  );

  return (
    <Modal
      className="modal"
      centered
      open={isModalVisible}
      closeIcon={<CloseIcon />}
      footer={null}
      title={'Yeni Firma Oluştur'}
      onCancel={() => handleModalVisible()}
    >
      <div className="modal-context-container">
        <Form
          className="modal-context-form"
          form={newAdminUserForm}
          onFinish={submitForm}
        >
          <Form.Item
            name="name"
            rules={[{ required: true, message: 'Lütfen firma ismi girin!' }]}
          >
            <Input placeholder={'İsim'} />
          </Form.Item>
        </Form>
        <div className="modal-context-footer">
          <Button
            className="modal-button"
            onClick={newAdminUserForm.submit}
            loading={loading}
          >
            Tamam
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default NewCompanyModal;
