import { EditOutlined } from '@ant-design/icons';
import { axios_patch } from '@utils/rest_axios';
import { Button, Form, Input, message } from 'antd';
import './ModuleNote.scss';

export const ModuleNote = ({ module, callBack, reload }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    if (values.moduleNote !== module.moduleNote) {
      async function update() {
        const data = {
          moduleNote: values.moduleNote
        };

        await axios_patch(`/modules/${module.moduleId}`, data);
      }
      update()
        .then((response) => {
          message.success('Güncelleme Başarılı!', 5);
          callBack(!reload);
        })
        .catch((err) => {
          message.error('Güncelleme Başarısız!', 5);
        });
    } else {
      message.warning('Değişiklik Bulunmuyor!', 5);
    }
  };
  return (
    <>
      <Form
        initialValues={{ moduleNote: module.moduleNote }}
        form={form}
        name="update-module-note"
        onFinish={onFinish}
      >
        <div className="updateModuleNote">
          <div className="moduleNoteInput">
            <Form.Item name="moduleNote">
              <Input.TextArea
                className="kemi_form_input_grey"
                placeholder="Modül açıklaması girin"
                autoSize={{ minRows: 5, maxRows: 10 }}
              />
            </Form.Item>
          </div>
          <div className="moduleNoteButton">
            <Form.Item>
              <div className="updateModuleNote__buttons">
                <Button
                  className="kemi_form_btn_purple foletrm_btn_margin"
                  shape="round"
                  htmlType="submit"
                  type="primary"
                >
                  <EditOutlined />
                  Güncelle
                </Button>
              </div>
            </Form.Item>
          </div>
        </div>
      </Form>
    </>
  );
};
