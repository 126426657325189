import {
  CloseOutlined,
  MinusCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import '@assets/sharedStyles/formItems.scss';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { addLocalOffsetToDateObject } from '@src/utils/date.helpers';
import { getErrorMessage } from '@src/utils/errors';
import {
  DATE_REQUIRED_MESSAGE,
  PRICE_REQUIRED_MESSAGE
} from '@src/utils/formRules/formRules';
import { axios_post } from '@utils/rest_axios';
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Row,
  Space,
  message
} from 'antd';
import { Fragment, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import './AddModulePriceAdmin.scss';

const AddModulePriceAdmin = ({ module, callBack, reload }) => {
  const [form] = Form.useForm();
  const [popup, setPopup] = useState(false);
  const { getNotification } = useNotificationPopup();

  const SubmitForm = async (values) => {
    if (values.moduleControls.length === 0) {
      message.error(
        'Fiyat bilgisi bulunamadi. En az bir fiyat girilmelidir!',
        5
      );
    }
    if (values.moduleControls.length > 0) {
      let errors = [];
      for (let moduleControl of values.moduleControls) {
        const moduleControlPriceBeginningEpoch =
          moduleControl.priceBeginning.valueOf();

        const priceBeginningDateWithOffset = addLocalOffsetToDateObject(
          moduleControlPriceBeginningEpoch
        );
        const priceBeginningEpochWithOffset =
          priceBeginningDateWithOffset.valueOf();

        const payload = {
          moduleId: module.moduleId,
          // eslint-disable-next-line no-useless-escape
          price: +moduleControl.price.replace(/\./g, '').replace(/\,/g, '.'),
          priceBeginning: priceBeginningEpochWithOffset
        };
        try {
          await axios_post('/admin/module-controls', payload);
        } catch ({ response }) {
          if (response && response.status !== 401) {
            errors.push(response);
            getNotification({
              ...getErrorMessage(response)
            });
          }
        }
        callBack(!reload);
      }
      if (errors.length === 0) {
        message.success('Güncelleme Başarılı! Fiyat bilgisi eklendi', 5);
      }
    }
  };

  function getForm() {
    return (
      <Form
        form={form}
        onFinish={SubmitForm}
        initialValues={{ moduleControls: [''] }}
      >
        <Form.List name="moduleControls">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  className="addModulePrice__card__form__space"
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'price']}
                    label="İlaç Fiyatı"
                    rules={[
                      {
                        required: true,
                        message: PRICE_REQUIRED_MESSAGE
                      }
                    ]}
                  >
                    <NumericFormat
                      customInput={Input}
                      className="kemi_form_input_grey Form__input__price"
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale={true}
                      defaultValue="0,00"
                      isNumericString={true}
                    />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    label="Başlangıç Tarihi:"
                    name={[name, 'priceBeginning']}
                    rules={[
                      {
                        required: true,
                        message: DATE_REQUIRED_MESSAGE
                      }
                    ]}
                  >
                    <DatePicker
                      className="kemi_form_input"
                      placeholder={['Başlangıç Tarihi:']}
                      format={'DD.MM.YYYY'}
                    />
                  </Form.Item>

                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  className="kemi_form_btn_purple"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Fiyat bilgisi ekle
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button
            className="kemi_form_btn_dark"
            type="primary"
            htmlType="submit"
          >
            Kaydet
          </Button>
        </Form.Item>
      </Form>
    );
  }
  return (
    <Fragment>
      {popup === false && (
        <Button
          className="addModulePrice__button kemi_form_btn_purple"
          onClick={() => setPopup(true)}
        >
          Fiyat Ekle
        </Button>
      )}

      {popup === true && (
        <Card className="addModulePrice__card">
          <Row justify="space-between" className="addModulePrice__card__row">
            <h3 className="addModulePrice__card__row__title">Fiyat Ekle</h3>
            <Button danger onClick={() => setPopup(false)}>
              <CloseOutlined />
            </Button>
          </Row>
          {getForm()}
        </Card>
      )}
    </Fragment>
  );
};

export default AddModulePriceAdmin;
