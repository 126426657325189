import { QuestionCircleOutlined } from '@ant-design/icons';
import InfoVideoFrame from '@src/components/shared/InfoVideoFrame/InfoVideoFrame';
import { Button, Row, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import './PharmacyInfoPages.scss';

const PharmacyInfoGuide = () => {
  return (
    <div className="content_container_tooltip">
      <Row justify="center">
        <Tooltip
          className="tooltip_abs"
          trigger="click"
          placement="right"
          title={
            'Telefon ekranlarında tanıtım videomuz nasıl başlatılır: Tam ekran moduna geçmek için 2 kez video çerçevesine tıklayınız veya oynatıcıyı kaydırınız.'
          }
        >
          <QuestionCircleOutlined className="video_help_icon" />
        </Tooltip>
        <InfoVideoFrame />
      </Row>

      <Link to="/">
        <Button className="kemi_form_btn_purple kemi_info_btn">
          Kemi Portalı Keşfet
        </Button>
      </Link>
    </div>
  );
};

export default PharmacyInfoGuide;
