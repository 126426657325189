import { CaretLeftOutlined } from '@ant-design/icons';
import SelectCompanyModules from '@src/components/pharmacy_components/pharmacy_requests/SelectCompanyModules/SelectCompanyModules';
import _ from 'lodash';

const SelectModulePage = ({
  setRequestData,
  requestData,
  setCurrentStep,
  currentStep
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between'
      }}
    >
      <SelectCompanyModules
        setRequestData={setRequestData}
        requestData={requestData}
        setCurrentStep={setCurrentStep}
      />
      <div>
        <button
          className="pharmacyNewRequest__button kemi_form_icon_button_ghost"
          shape="round"
          onClick={() => {
            setCurrentStep(currentStep - 1);
            setRequestData(_.omit(requestData, ['companyId', 'companyName']));
          }}
        >
          <CaretLeftOutlined /> Geri
        </button>
      </div>
    </div>
  );
};

export default SelectModulePage;
