import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import Highlighter from 'react-highlight-words';

/**
 * Returns the search properties for a column in an Ant Design table.
 *
 * @param {string} dataIndex - The data index of the column.
 * @param {function} handleSearch - The function to handle the search action.
 * @param {function} handleReset - The function to handle the reset action.
 * @param {React.RefObject} searchInput - The reference to the search input element.
 * @param {string} searchText - The current search text.
 * @param {string} searchedColumn - The column that is currently being searched.
 * @returns {object} The search properties for the column.
 */

export const getColumnSearchProps = (
  dataIndex,
  handleSearch,
  handleReset,
  searchInput,
  searchText,
  searchedColumn
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={searchInput}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
  onFilter: (value, record) => {
    const recordValue = Array.isArray(dataIndex)
      ? dataIndex.reduce((acc, key) => acc[key], record)
      : record[dataIndex];
    return recordValue
      ? recordValue.toString().toLowerCase().includes(value.toLowerCase())
      : '';
  },
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.current?.select(), 100);
    }
  },
  render: (text) =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{
          backgroundColor: '#ffc069',
          padding: 0
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )
});
