import '@assets/sharedStyles/pageItems.scss';
import { Card } from 'antd';

export const PharmacyInfoQA = () => {
  return (
    <Card className="pharmacyProfile__card">
      <h3 className="kemi_pages_text_title">
        Kemi Portal’ı hangi amaçla kullanabilirim?
      </h3>
      <p>
        Kemi Portal, ilaç firmalarının ödeme yapmak için talep ettiği İTS satış
        kayıtlarını, tek tek karekodların ekran görüntüsünü alarak göndermek
        yerine, bu iş yükünden kurtulup sadece ihtiyaç olunan ticari bilgileri
        güvenli ve hızlı bir şekilde ilgili ilaç firmasına ulaştırarak ödeme
        işlemini şeffaf bir şekilde takip etmenize yardımcı olan bir asistanız
        olarak tasarlanmıştır.
      </p>
      <h3 className="kemi_pages_text_title">
        Cep telefonu numaram ne için kullanılacak?
      </h3>
      <p>
        İlaç firmalarına yaptığınız başvurulara ilişkin bir durum oluştuğu
        takdirde size ulaşabileceğimiz ve sadece ilgili koşulları kabul
        ettiğiniz takdirde ticari bilgilendirmeleri alabileceğiniz bir kanal
        olarak kullanılacaktır.
      </p>
      <h3 className="kemi_pages_text_title">
        Ürün karekodlarımın işleme alınmama sebebi nedir?
      </h3>
      <p>
        İlaç firmasının, başvuru olarak kabul edeceği GTIN numarası dışında bir
        GTIN numarasına ait karekodla başvurduğunuz takdirde bu başvuru işleme
        alınmaz ve firmaya ulaştırılmaz.
      </p>
      <h3 className="kemi_pages_text_title">Şifrelerim depolanıyor mu?</h3>
      <p>
        İTS kullanıcı bilgileriniz hiçbir koşul altında depolanmaz. Yapacağınız
        her bir başvuru için kullanıcı bilgilerinizi yeniden girmeniz talep
        edilecektir.
      </p>
      <h3 className="kemi_pages_text_title">
        Ödemeler ne zaman tarafıma ulaşacak?
      </h3>
      <p>
        İlaç firması kendi tarafından ödemeleri ilgili ecza deposuna yaptıktan
        sonra başvurunuzu “ödendi” statüsüne çekerek size bildirim gönderir.
        Ödemelerin tarafınıza ulaşması için ecza deponuzla iletişime geçmeniz
        gerekmektedir.
      </p>
      <h3 className="kemi_pages_text_title">Ödemelerim nasıl hesaplanıyor? </h3>
      <p>
        Başvurularınıza dair ödemeleriniz, ilaç firmanızın ilgi tarih
        aralıklarına kaydetmiş olduğu fiyatlar baz alınarak hesaplanır.
      </p>
      <h3 className="kemi_pages_text_title">Üyelikten nasıl ayrılırım?</h3>
      <p>
        Üyelikten çıkma talebinizi info@kemiportal.com adresine e-posta atarak
        iletebilirsiniz. Üyeliğiniz kapandığında bilgilendirileceksiniz.
      </p>
    </Card>
  );
};
