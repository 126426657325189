import { Row } from 'antd';
import React from 'react';
import './NotificationPopup.scss';

/**
 * Notification Popup
 * @usage const { getNotification } = useNotificationPopup();
 * @param title text
 * @param message text
 * @param confirmText text
 * @param type success, default, danger, info, warning
 * @example getNotification({
            title: `Modül durumu olarak değiştirilecektir.`,
            message: 'Emin misiniz?',
            confirmText: 'Onayla',
            type: 'success',
          }); 
 */

const NotificationPopup = ({
  open,
  title,
  confirmText,
  message,
  onConfirm,
  type,
}) => {
  /*
  <Row justify="end">
                <div className="close-btn" onClick={onConfirm}>
                  <CloseOutlined />
                </div>
              </Row>
  */
  return (
    <>
      {open && (
        <div className="notification__container">
          <div className={`notification_popup_container popup_default`}>
            <div className="confirmation_popup_headline">
              <Row justify="end">
                <div className="popup-bar-empty-space"></div>
              </Row>
            </div>

            <Row justify="center">
              <h3 className="popup__title">{title}</h3>
            </Row>

            <Row justify="center">
              <p className="popup__message">{message}</p>
            </Row>

            <Row justify="center">
              {confirmText && (
                <button
                  className="popup__btn popup__btn_confirm"
                  onClick={onConfirm}
                >
                  {confirmText}
                </button>
              )}
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

const NotificationPopupContext = React.createContext({});

const NotificationPopupProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogConfig, setDialogConfig] = React.useState({});

  const openDialog = ({
    title,
    message,
    confirmText,
    actionCallback,
    type,
  }) => {
    setDialogOpen(true);
    setDialogConfig({
      title,
      message,
      confirmText,
      actionCallback,
      type,
    });
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig({});
  };

  const onConfirm = () => {
    resetDialog();
    dialogConfig.actionCallback(true);
  };

  return (
    <NotificationPopupContext.Provider value={{ openDialog }}>
      <NotificationPopup
        open={dialogOpen}
        title={dialogConfig?.title}
        message={dialogConfig?.message}
        cancelText={dialogConfig?.cancelText}
        confirmText={dialogConfig?.confirmText}
        type={dialogConfig?.type}
        onConfirm={onConfirm}
      />
      {children}
    </NotificationPopupContext.Provider>
  );
};

const useNotificationPopup = () => {
  const { openDialog } = React.useContext(NotificationPopupContext);

  const getNotification = ({ ...options }) =>
    new Promise((res) => {
      openDialog({ actionCallback: res, ...options });
    });

  return { getNotification };
};

export default NotificationPopup;
export { NotificationPopupProvider, useNotificationPopup };
