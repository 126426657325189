import { StarTwoTone } from '@ant-design/icons';
import '@assets/sharedStyles/formItems.scss';
import Footer from '@src/components/public_components/Footer';
import useUrlSearchParamQuery from '@src/utils/customHooks/useUrlSearchParamQuery';
import {
  PASSWORD_MESSAGE,
  REQUIRED_INPUT_MESSAGE
} from '@src/utils/formRules/formRules';
import { axios_post } from '@src/utils/rest_axios';
import { Button, Col, Form, Input, message, Row } from 'antd';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import kemi_logo from '../../../assets/img/kemi_logo.png';
import loginImg from '../../../assets/img/loginImg.png';
import './ResetPassword.scss';

function ResetPasswordForm() {
  const [password_form] = Form.useForm();
  const [query] = useState(useUrlSearchParamQuery());
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    const queryObj = queryString.parse(search);

    if (!queryObj.userId && !queryObj.token) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitUpdatePassword = (values) => {
    // get inputs and update user
    if (values.newPassword === values.confirmNewPassword) {
      handleActivate(values.newPassword);
    } else {
      message.error('Yeni Şifreleriniz Eşleşmiyor. Tekrar Deneyiniz', 5);
    }
  };

  async function handleActivate(newPassword) {
    // handle post request
    const resetPwdData = {
      userId: query.get('userId'),
      token: query.get('token'),
      password: newPassword
    };

    try {
      await axios_post('/users/reset-password', resetPwdData);
      message.success(
        'Şifreniz Başarıyla Değiştirildi. Giriş Yapabilirsiniz',
        5
      );
      navigate('/');
    } catch (error) {
      message.error('Hata! Tekrar Deneyiniz.', 5);
    }
  }

  function ResetForm() {
    return (
      <Col span={10} style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <div className="reset-form">
          <Form
            form={password_form}
            onFinish={handleSubmitUpdatePassword}
            className="reset-form"
          >
            <h3
              className="change_password_text_description"
              style={{ textAlign: 'center' }}
            >
              Yeni şifrenizi giriniz
            </h3>

            <Form.Item
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: REQUIRED_INPUT_MESSAGE
                },
                {
                  min: 6,
                  message: PASSWORD_MESSAGE
                }
              ]}
              hasFeedback
            >
              <Input
                placeholder="Yeni şifrenizi giriniz"
                className="change_password_input"
                type="password"
              />
            </Form.Item>

            <Form.Item
              name="confirmNewPassword"
              hasFeedback
              dependencies={['newPassword']}
              rules={[
                {
                  required: true,
                  message: REQUIRED_INPUT_MESSAGE
                },
                {
                  min: 6,
                  message: PASSWORD_MESSAGE
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Girilen şifreler eşleşmiyor!');
                  }
                })
              ]}
            >
              <Input
                placeholder="Yeni şifrenizi tekrar giriniz"
                className="change_password_input"
                type="password"
              />
            </Form.Item>

            <Button type="primary" htmlType="submit">
              Gönder
            </Button>
          </Form>
          <div className="reset-form-back-btn">
            <Button shape="round" type="primary">
              <a href="/" className="kemi_form_icon_button_ghost back_btn">
                Geri Dön
              </a>
            </Button>
          </div>
        </div>
      </Col>
    );
  }

  function InfoSection() {
    return (
      <Col span={14} className="loginpage__img__breakpoint">
        <div className="loginpage__img__container">
          <img className="loginpage__img" src={loginImg} alt="loginpage_img" />

          <Row className="loginpage__form__gridImg" justify="center">
            <div className="loginpage__img__stars__container">
              <StarTwoTone
                className="loginpage__img__stars__icon"
                twoToneColor="#F99A3E"
              />
              <h3 className="loginpage__img__stars__text">KVKK ile tam uyum</h3>
            </div>
            <div className="loginpage__img__stars__container">
              <StarTwoTone
                className="loginpage__img__stars__icon"
                twoToneColor="#F99A3E"
              />
              <h3 className="loginpage__img__stars__text">
                Toplu bildirim kolaylığı
              </h3>
            </div>
            <div className="loginpage__img__stars__container">
              <StarTwoTone
                className="loginpage__img__stars__icon"
                twoToneColor="#F99A3E"
              />
              <h3 className="loginpage__img__stars__text">
                Yüksek kullanıcı deneyimi
              </h3>
            </div>
          </Row>
        </div>
      </Col>
    );
  }

  return (
    <>
      <div className="kemi_login_tab">
        <Row justify="end">
          <a href="/">
            <img src={kemi_logo} className="kemi_logo" alt="navbar-logo" />
          </a>{' '}
        </Row>

        <Row justify="space-around">
          <InfoSection />
          <ResetForm />
        </Row>
      </div>
      <Footer />
    </>
  );
}

export default ResetPasswordForm;
