import '@assets/sharedStyles/formItems.scss';
import '@assets/sharedStyles/pageItems.scss';

import { Tabs } from 'antd';
import UsersActivity from './Tabs/UsersActivityTab';
import Users from './Tabs/UsersTab';

function Admin() {
  const tabItems = [
    {
      key: 'admin1',
      label: 'Kullanıcılar',
      children: <Users />
    },
    {
      key: 'admin2',
      label: 'Admin Hareketleri',
      children: <UsersActivity />
    }
  ];

  return (
    <div className="kemi_pages_tabs">
      <Tabs defaultActiveKey="admin1" items={tabItems} />
    </div>
  );
}

export default Admin;
