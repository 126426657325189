import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import {
  EMAIL_MESSAGE,
  EMAIL_REQUIRED,
  GLN_NUMBER_DIGIT_MESSAGE,
  GLN_NUMBER_PATTERN_MESSAGE,
  GLN_NUMBER_REGEX,
  GLN_NUMBER_REQUIRED_MESSAGE,
} from '@src/utils/formRules/formRules';
import { axios_post } from '@src/utils/rest_axios';
import { Button, Form, Input } from 'antd';

const CreateStakeholdersForm = () => {
  const [updateStakeholderForm] = Form.useForm();
  const { getNotification } = useNotificationPopup();
  const [getStakeholderForm] = Form.useForm();

  const SubmitForm = async (values) => {
    try {
      const getStakeholderResponse = await axios_post(
        `/admin/stakeholder`,
        values
      );
      if (getStakeholderResponse.status === 200) {
        getStakeholderForm.resetFields();
      }
    } catch ({ response }) {
      if (response && response.status !== 401) {
        getNotification({
          ...getErrorMessage(response),
          confirmText: 'Tamam',
        });
      }
    }
  };

  return (
    <div>
      <Form
        name="updateStakeholderForm"
        colon={true}
        form={updateStakeholderForm}
        onFinish={SubmitForm}
        layout={'horizontal'}
        className="Form"
        wrapperCol={{
          md: { span: 16 },
        }}
      >
        <Form.Item
          name="gln"
          label="Eczane Gln"
          rules={[
            {
              required: true,
              message: GLN_NUMBER_REQUIRED_MESSAGE,
            },
            {
              min: 13,
              message: GLN_NUMBER_DIGIT_MESSAGE,
            },
            {
              max: 13,
              message: GLN_NUMBER_DIGIT_MESSAGE,
            },
            {
              pattern: GLN_NUMBER_REGEX,
              message: GLN_NUMBER_PATTERN_MESSAGE,
            },
          ]}
        >
          <Input
            className="kemi_form_input_grey fixed_width_input"
            placeholder="Eczane gln"
          />
        </Form.Item>
        <Form.Item
          name="companyName"
          label="Eczane ismi"
          rules={[
            {
              required: true,
              message: 'Eczane ismi giriniz.',
            },
          ]}
        >
          <Input
            className="kemi_form_input_grey fixed_width_input"
            placeholder="Eczane ismi"
            contentEditable={false}
          />
        </Form.Item>
        <Form.Item
          name="authorized"
          label="Eczaneci ismi"
          rules={[
            {
              required: true,
              message: 'eczaci  ismi giriniz.',
            },
          ]}
        >
          <Input
            className="kemi_form_input_grey fixed_width_input"
            placeholder="Eczaci ismi"
            contentEditable={false}
          />
        </Form.Item>
        <Form.Item
          name="city"
          label="Sehir"
          rules={[
            {
              required: true,
              message: 'Sehir giriniz.',
            },
          ]}
        >
          <Input
            className="kemi_form_input_grey fixed_width_input"
            placeholder="Eczane Sehri"
          />
        </Form.Item>
        <Form.Item name="town" label="Ilce">
          <Input
            className="kemi_form_input_grey fixed_width_input"
            placeholder="Eczane ilce"
          />
        </Form.Item>

        <Form.Item
          name="address"
          label="Adres"
          rules={[
            {
              required: true,
              message: 'Adres giriniz.',
            },
          ]}
        >
          <Input
            className="kemi_form_input_grey fixed_width_input"
            placeholder="Eczane adresi"
          />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              ...EMAIL_REQUIRED,
              message: EMAIL_MESSAGE,
            },
          ]}
        >
          <Input
            className="kemi_form_input_grey fixed_width_input"
            placeholder="Eczane email"
          />
        </Form.Item>
        <Form.Item name="phone" label="Telefon">
          <Input
            className="kemi_form_input_grey fixed_width_input"
            placeholder="Eczane telefon"
          />
        </Form.Item>

        <Form.Item>
          <Button className="kemi_form_btn_dark" htmlType="submit">
            Olustur
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateStakeholdersForm;
