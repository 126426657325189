import '@assets/sharedStyles/formItems.scss';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import { COMPANY_ID_MESSAGE } from '@src/utils/formRules/formRules';
import { axios_get } from '@src/utils/rest_axios';
import { Form, Select } from 'antd';
import { useEffect, useState } from 'react';

function SelectCompanyInput() {
  const [companies, setCompanies] = useState([]);
  const { getNotification } = useNotificationPopup();

  useEffect(() => {
    async function getCompanyModules() {
      try {
        const companiesRes = await axios_get(`/companies`);
        if (companiesRes.status === 200) {
          setCompanies(companiesRes.data);
        }
      } catch ({ response }) {
        if (response && response.status !== 401) {
          getNotification({
            ...getErrorMessage(response)
          });
        }
      }
    }
    getCompanyModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form.Item
      name="companyId"
      label="Firma"
      rules={[
        {
          required: true,
          message: COMPANY_ID_MESSAGE
        }
      ]}
    >
      <Select
        allowClear
        showSearch
        placeholder="Firma Seçimi"
        optionFilterProp="children"
        maxTagCount="responsive"
        filterOption={(input, option) =>
          option.name.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        className="kemi_form_select_grey fixed_width_input kemi_select_company_input"
      >
        {companies.length > 0 &&
          companies.map((company) => {
            return (
              <Select.Option
                key={company.companyId}
                value={company.companyId}
                name={company.name}
              >
                {company.name}
              </Select.Option>
            );
          })}
      </Select>
    </Form.Item>
  );
}

export default SelectCompanyInput;
