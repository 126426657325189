import { FormOutlined } from '@ant-design/icons';
import '@assets/sharedStyles/modal.scss';
import { Modal } from 'antd';
import { useState } from 'react';
import WholesalerUpdateForm from './WholesalerUpdateForm';
function WholesalerUpdateModal({ wholesaler, setCallback }) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  function showModal() {
    setIsModalVisible(true);
  }

  function closeModal() {
    setIsModalVisible(false);
  }
  return (
    <div>
      <FormOutlined onClick={showModal} />
      <Modal
        className="modal"
        title="Ecza Deposu Güncelleme"
        open={isModalVisible}
        onCancel={closeModal}
        maskClosable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <div className="modal-context-container">
          <WholesalerUpdateForm
            wholesaler={wholesaler}
            closeModal={closeModal}
            setCallback={setCallback}
          />
        </div>
      </Modal>
    </div>
  );
}

export default WholesalerUpdateModal;
