import { COMPANY_ID_MESSAGE } from '@src/utils/formRules/formRules';
import { Form, Select, Spin } from 'antd';
const { Option } = Select;

function FilterFormCompanies({ companies }) {
  return (
    <Form.Item
      name="companyId"
      className="stockloss_active_filter_input"
      rules={[
        {
          required: true,
          message: COMPANY_ID_MESSAGE
        }
      ]}
    >
      <Select
        allowClear
        showSearch
        placeholder="Şirket"
        maxTagCount="1"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.gln.toString().toLowerCase().indexOf(input.toLowerCase()) >=
            0 ||
          option.name.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        className="kemi_form_select companies_form_select"
        popupClassName="kemi_form_select_wide_dropdown"
      >
        {companies.length > 0 ? (
          companies.map((company) => {
            return (
              <Option key={company.companyId} value={company.companyId}>
                {company.name}
              </Option>
            );
          })
        ) : (
          <Option disabled>
            <Spin />
          </Option>
        )}
      </Select>
    </Form.Item>
  );
}

export default FilterFormCompanies;
