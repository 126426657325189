import Footer from '@src/components/public_components/Footer';
import './Layouts.scss';

const PublicLayout = ({ children }) => (
  <div className="layout">
    {children}
    {/* <div id="content-wrap"></div> */}
    <Footer />
  </div>
);

export default PublicLayout;
