import { PharmacyNotificationContext } from '@src/components/shared/PharmacyNotificationContextProvider/PharmacyNotificationContextProvider';
import { Card, Empty } from 'antd';
import { useContext } from 'react';
import { CollapseNotificationPharmacy } from './CollapseNotificationPharmacy';
import './PharmacyNotifications.scss';

function PharmacyNotifications() {
    const { notifications } = useContext(PharmacyNotificationContext);

    return (
        <div className="kemi_pages_cards">
            <Card className="PharmacyNotifications__card">
                {notifications.length > 0 ? (
                    notifications.map((notification, index) => {
                        return (
                            <CollapseNotificationPharmacy
                                key={notification.notificationId}
                                notification={notification}
                                arrayIndex={index}
                            />
                        );
                    })
                ) : (
                    <Empty />
                )}
            </Card>
        </div>
    );
}

export default PharmacyNotifications;
