import ITSForm from '@Components/pharmacy_components/pharmacy_requests/ITSForm/ITSForm';
import { CaretLeftOutlined } from '@ant-design/icons';
import _ from 'lodash';

const ItsFormPage = ({
  requestData,
  currentStep,
  setCurrentStep,
  setITSResult,
  setRequestData
}) => {
  return (
    <div>
      <div className="pharmacyNewRequest_container">
        <h2 className="pharmacyNewRequest__title">{requestData.moduleName}</h2>
        <ITSForm
          setRequestData={setRequestData}
          requestData={requestData}
          setCurrentStep={setCurrentStep}
          setITSResult={setITSResult}
        />
      </div>
      <button
        shape="round"
        className="pharmacyNewRequest__button kemi_form_icon_button_ghost"
        onClick={() => {
          setCurrentStep(currentStep - 1);
          if (requestData.type === 'STOCK') {
            setRequestData(_.omit(requestData, ['barcodes', 'wholesalerId']));
          } else {
            setRequestData(
              _.omit(requestData, ['barcodes', 'itsUsername', 'itsPassword'])
            );
          }
        }}
      >
        <CaretLeftOutlined /> Geri
      </button>
    </div>
  );
};
export default ItsFormPage;
