import '@assets/sharedStyles/formItems.scss';
import { EMAIL_MESSAGE, EMAIL_REQUIRED } from '@src/utils/formRules/formRules';
import { axios_post } from '@src/utils/rest_axios';
import { Button, Col, Form, Input, message } from 'antd';
import { useState } from 'react';
import PharmacyResetPasswordIssuesFlowModals from './PharmacyResetPasswordIssuesFlowModals';
import './ResetPassword.scss';

const ResetPasswordRequest = ({ setPage_sections }) => {
  const [emailForm] = Form.useForm();
  const [isRequestSuccess, setIsRequestSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [inactivePharmacyValues, setInactivePharmacyValues] = useState();
  const [
    pharmacyResetPasswordIssuesFlowModalVisible,
    setIsPharmacyResetPasswordIssuesFlowModalVisible
  ] = useState(false);

  const handleSubmit = async (values) => {
    try {
      const response = await axios_post(
        '/users/reset-password-request',
        values
      );

      if (response.status === 200) {
        setIsRequestSuccess(true);
        message.success(
          'Şifre sıfırlama talebiniz iletilmiştir! Şifre sıfırlamak için kayıtlı e-postanızı kontrol ediniz.',
          5
        );
      }
    } catch (error) {
      if (error.response.data.name === 'AccountNotActiveError') {
        setInactivePharmacyValues({
          gln: error.response.data.gln,
          email: error.response.data.email
        });
      }
      setErrorMessage(error.response.data.name);
      setIsPharmacyResetPasswordIssuesFlowModalVisible((value) => !value);
    }
  };

  const handleModalVisible = () => {
    setIsPharmacyResetPasswordIssuesFlowModalVisible((value) => !value);
  };

  function EmailForm() {
    return (
      <div className="EmailForm">
        <Form
          form={emailForm}
          onFinish={handleSubmit}
          className="change_password_form1"
        >
          <h1>Şifremi Unuttum</h1>
          <h3 className="change_password_text_description">
            Yeni şifre belirleme kodunu gönderebilmemiz için kayıtlı e-posta
            adresinizi giriniz.
          </h3>
          <Form.Item
            name="email"
            rules={[
              {
                ...EMAIL_REQUIRED,
                message: EMAIL_MESSAGE
              }
            ]}
            hasFeedback
          >
            <Input
              className="change_password_input"
              type="email"
              placeholder="E-posta Adresi"
              required
            />
          </Form.Item>
          <Form.Item>
            <Button className="change_password_submit" htmlType="submit">
              Gönder
            </Button>
          </Form.Item>

          <p className="change_password_text_description_small">
            Kayıtlı e-posta adresinizi unuttuysanız veya herhangi bir sorunuz
            varsa sağ alt köşede yer alan Canlı Destek Hattı baloncuğundan bize
            ulaşabilirsiniz.
          </p>
        </Form>
      </div>
    );
  }

  return (
    <div
      className="ResetPasswordRequest"
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <PharmacyResetPasswordIssuesFlowModals
        errorMessage={errorMessage}
        inactivePharmacyValues={inactivePharmacyValues}
        isModalVisible={pharmacyResetPasswordIssuesFlowModalVisible}
        handleModalVisible={handleModalVisible}
      />
      <Col
        xs={20}
        sm={12}
        md={10}
        lg={10}
        xl={10}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        {isRequestSuccess ? (
          <p
            className="change_password_text_description_small"
            style={{ width: '400px', fontSize: 16, marginLeft: '-50px' }}
          >
            Mail adresinize şifre sıfırlama linki gönderilmiştir.
          </p>
        ) : (
          <EmailForm />
        )}
        <div style={{ marginLeft: '-70px' }}>
          <button
            onClick={() => {
              setPage_sections('login-page');
              setIsRequestSuccess(false);
            }}
            className="change_password_submit"
            style={{ height: '40px', width: '100px' }}
          >
            Geri Dön
          </button>
        </div>
      </Col>
    </div>
  );
};

export default ResetPasswordRequest;
