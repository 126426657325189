import React from 'react';
import './PageBlurSpinner.scss';
import Spinner from './Spinner';

function PageBlurSpinner() {
  return (
    <div className="page-blur-spinner">
      <Spinner loadingText="İTS ile doğrulama yapılıyor lütfen bekleyiniz..." />
    </div>
  );
}

export default PageBlurSpinner;
