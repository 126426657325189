import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import { axios_delete } from '@src/utils/rest_axios';
import { Modal, message } from 'antd';
const { confirm } = Modal;

function UserDelete({ userId, setRefresh }) {
  const { getNotification } = useNotificationPopup();

  function confirmDelete() {
    confirm({
      title: 'Kullanıcı Silinecek',
      icon: <ExclamationCircleOutlined />,
      content: 'Emin misiniz?',
      onOk() {
        handleDeleteUser();
      }
    });
  }

  async function handleDeleteUser() {
    try {
      const userDeleteRes = await axios_delete(`/admin/user/`, userId);

      if (userDeleteRes.status === 200) {
        message.success('Kullanıcı Silme Başarılı!', 5);
      }
      setRefresh(true);
    } catch ({ response }) {
      if (response && response.status !== 401) {
        getNotification({
          ...getErrorMessage(response)
        });
      }
    }
  }

  return <DeleteOutlined onClick={confirmDelete} />;
}

export default UserDelete;
