import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import {
  GLN_NUMBER_DIGIT_MESSAGE,
  GLN_NUMBER_PATTERN_MESSAGE,
  GLN_NUMBER_REGEX,
  GLN_NUMBER_REQUIRED_MESSAGE,
} from '@src/utils/formRules/formRules';
import { axios_get } from '@src/utils/rest_axios';
import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import UpdateStakeholder from './UpdateStakeholder';

function GetStakeholderForm() {
  const { getNotification } = useNotificationPopup();
  const [getStakeholderForm] = Form.useForm();
  const [stakeholder, setStakeholder] = useState(null);
  const SubmitForm = async (values) => {
    try {
      const getStakeholderResponse = await axios_get(
        `/admin/stakeholder/${values.gln}`
      );
      if (getStakeholderResponse.status === 200) {
        getStakeholderForm.resetFields();
        setStakeholder(getStakeholderResponse.data);
      }
    } catch ({ response }) {
      if (response && response.status !== 401) {
        getNotification({
          ...getErrorMessage(response),
          confirmText: 'Tamam',
        });
      }
    }
  };

  return (
    <div>
      <Form
        name="updateUserForm"
        autoComplete="off"
        colon={true}
        onFinish={SubmitForm}
        layout={'horizontal'}
        className="Form"
        wrapperCol={{
          md: { span: 16 },
        }}
      >
        <Form.Item
          name="gln"
          label="Eczane Gln"
          rules={[
            {
              required: true,
              message: GLN_NUMBER_REQUIRED_MESSAGE,
            },
            {
              min: 13,
              message: GLN_NUMBER_DIGIT_MESSAGE,
            },
            {
              max: 13,
              message: GLN_NUMBER_DIGIT_MESSAGE,
            },
            {
              pattern: GLN_NUMBER_REGEX,
              message: GLN_NUMBER_PATTERN_MESSAGE,
            },
          ]}
        >
          <Input
            className="kemi_form_input_grey fixed_width_input"
            placeholder="Eczane gln"
          />
        </Form.Item>

        <Form.Item>
          <Button className="kemi_form_btn_dark" htmlType="submit">
            Bul
          </Button>
        </Form.Item>
      </Form>
      {stakeholder && <UpdateStakeholder stakeholder={stakeholder} />}
    </div>
  );
}

export default GetStakeholderForm;
