// import Grid from '@material-ui/core/Grid';
import '@assets/sharedStyles/formItems.scss';
import {
  EMAIL_MESSAGE,
  EMAIL_REQUIRED,
  GLN_NUMBER_DIGIT_MESSAGE,
  GLN_NUMBER_PATTERN_MESSAGE,
  GLN_NUMBER_REGEX,
  GLN_NUMBER_REQUIRED_MESSAGE,
  GSM_REGEX,
  PASSWORD_MESSAGE,
  REQUIRED_INPUT_MESSAGE
} from '@src/utils/formRules/formRules';
import { axios_get_public, axios_post_public } from '@utils/rest_axios';
import { Button, Form, Input, Row, Select } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  CheckClarification,
  CheckKVKK
} from '../shared/ContractCheckboxes/ConractCheckboxes';

import { useConfirmationDialog } from '../shared/ConfirmationPopup/ConfirmationDialog';
import {
  CustomConfirmationDialog,
  useCustomConfirmationDialog
} from '../shared/ConfirmationPopup/CustomConfirmationDialog';

const { Option } = Select;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
};

const PharmacyRegister = () => {
  const [form] = Form.useForm();
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [provinces, setProvinces] = useState([]);
  const { getConfirmation } = useConfirmationDialog();
  const { getCustomConfirmation } = useCustomConfirmationDialog();
  useEffect(() => {
    async function fetchCities() {
      const { data } = await axios_get_public('/cities');
      setCities(data);
    }
    fetchCities();
  }, []);
  useEffect(() => {
    async function fetchProvinces() {
      if (selectedCity) {
        const { data } = await axios_get_public(`/provinces/${selectedCity}`);
        setProvinces(data);
        form.setFieldsValue({ provinceId: null });
      }
    }
    fetchProvinces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCity]);

  const onFinish = async (values) => {
    try {
      const payload = {
        ...values,
        emailConsent: values.clarificationCheck || false,
        phoneConsent: values.clarificationCheck || false
      };
      delete payload.emailConfirmation;
      delete payload.passwordConfirmation;

      const createPayload = _.omit(payload, ['clarificationCheck']);
      const response = await axios_post_public(
        '/signup-pharmacy',
        createPayload
      );

      const confirmed = await getCustomConfirmation({
        title: (
          <Row justify="center">
            <h3 className="popup__title">Tebrikler, Kayıt Başarılı!</h3>
          </Row>
        ),

        message: (
          <Row justify="center">
            <p className="popup__message">
              İTS'ye kayıtlı olduğunuz{' '}
              <span style={{ color: 'red', fontWeight: 'bold' }}>
                {response.data.registerEmail}
              </span>{' '}
              adresine aktivasyon linki yollanmıştır!
            </p>
          </Row>
        ),
        confirmText: 'Tamam'
      });

      if (confirmed) {
        window.location.reload();
      }
    } catch (err) {
      if (err.response.data.name === 'UserAlreadyExists') {
        await getConfirmation({
          title: 'Kullanıcı zaten kayıtlı!',
          message: `Bu email adresiyle zaten bir
          
          kullanıcı bulunmaktadır!`,
          confirmText: 'Tamam'
        });
      } else if (err.response.data.name === 'StakeholderDoesNotExists') {
        await getConfirmation({
          title: 'Hata',
          message: `Girmiş olduğunuz GLN numarası
          
          İTS'ye kayıtlı değildir.!`,
          confirmText: 'Tamam'
        });
      } else if (err.response.data.name === 'PharmacyAlreadyExist') {
        await getConfirmation({
          title: 'Hata',
          message: `Girmiş olduğunuz GLN numarası ile sistemde zaten bir eczane bulunmaktadır!`,
          confirmText: 'Tamam'
        });
      } else {
        await getConfirmation({
          title: 'Kayıt İşlemi Başarısız.',
          message: `Lütfen tekrar deneyiniz!`,
          confirmText: 'Tamam'
        });
      }
    }
  };
  // form parts
  function NameField() {
    return (
      <Form.Item
        name="pharmacyName"
        rules={[
          {
            required: true,
            message: REQUIRED_INPUT_MESSAGE
          }
        ]}
        hasFeedback
      >
        <Input
          onInput={(e) => {
            const correctedValue = e.target.value
              .replace(/i/g, 'İ')
              .toUpperCase();
            e.target.value = correctedValue;
          }}
          className="kemi_form_input_grey"
          placeholder="Eczane İsmi"
        />
      </Form.Item>
    );
  }
  function PharmacistNameField() {
    return (
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: REQUIRED_INPUT_MESSAGE
          }
        ]}
        hasFeedback
      >
        <Input
          onInput={(e) => {
            const correctedValue = e.target.value
              .replace(/i/g, 'İ')
              .toUpperCase();
            e.target.value = correctedValue;
          }}
          className="kemi_form_input_grey"
          placeholder="İsminiz "
        />
      </Form.Item>
    );
  }
  function PharmacistSurnameField() {
    return (
      <Form.Item
        name="surname"
        rules={[
          {
            required: true,
            message: REQUIRED_INPUT_MESSAGE
          }
        ]}
        hasFeedback
      >
        <Input
          onInput={(e) => {
            const correctedValue = e.target.value
              .replace(/i/g, 'İ')
              .toUpperCase();
            e.target.value = correctedValue;
          }}
          className="kemi_form_input_grey"
          placeholder="Soy İsminiz"
        />
      </Form.Item>
    );
  }

  function EmailField() {
    return (
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: REQUIRED_INPUT_MESSAGE
          },
          {
            ...EMAIL_REQUIRED,
            message: EMAIL_MESSAGE
          },
          {
            pattern: /^[^\u00C0-\u017F]*$/,
            message:
              'Geçersiz karakterler içeriyor. Lütfen yalnızca İngilizce karakterler kullanın.'
          }
        ]}
        hasFeedback
      >
        <Input className="kemi_form_input_grey" placeholder="E-posta Adresi" />
      </Form.Item>
    );
  }

  function EmailConfirmationField() {
    return (
      <Form.Item
        name="emailConfirmation"
        rules={[
          {
            required: true,
            message: REQUIRED_INPUT_MESSAGE
          },
          {
            ...EMAIL_REQUIRED,
            message: EMAIL_MESSAGE
          },
          {
            pattern: /^[^\u00C0-\u017F]*$/,
            message:
              'Geçersiz karakterler içeriyor. Lütfen yalnızca İngilizce karakterler kullanın.'
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('email') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('E-posta adresleri eşleşmiyor.'));
            }
          })
        ]}
        hasFeedback
      >
        <Input
          className="kemi_form_input_grey"
          placeholder="E-posta Adresi Tekrar"
          onPaste={(e) => e.preventDefault()}
        />
      </Form.Item>
    );
  }

  function PasswordField() {
    return (
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: REQUIRED_INPUT_MESSAGE
          },
          {
            min: 6,
            message: PASSWORD_MESSAGE
          }
        ]}
        hasFeedback
      >
        <Input
          className="kemi_form_input_grey"
          type="password"
          placeholder="Şifre"
        />
      </Form.Item>
    );
  }

  function PasswordConfirmationField() {
    return (
      <Form.Item
        name="passwordConfirmation"
        rules={[
          {
            required: true,
            message: REQUIRED_INPUT_MESSAGE
          },
          {
            min: 6,
            message: PASSWORD_MESSAGE
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Şifreler eşleşmiyor.'));
            }
          })
        ]}
        hasFeedback
      >
        <Input
          className="kemi_form_input_grey"
          type="password"
          placeholder="Şifre Tekrar"
          onPaste={(e) => e.preventDefault()}
        />
      </Form.Item>
    );
  }

  function GlnField() {
    return (
      <Form.Item
        name="pharmacyGln"
        rules={[
          {
            required: true,
            message: GLN_NUMBER_REQUIRED_MESSAGE
          },
          {
            min: 13,
            message: GLN_NUMBER_DIGIT_MESSAGE
          },
          {
            max: 13,
            message: GLN_NUMBER_DIGIT_MESSAGE
          },
          {
            pattern: GLN_NUMBER_REGEX,
            message: GLN_NUMBER_PATTERN_MESSAGE
          }
        ]}
        hasFeedback
      >
        <Input
          className="kemi_form_input_grey"
          type="number"
          placeholder="Eczane GLN Numaranız"
        />
      </Form.Item>
    );
  }

  function GsmField() {
    return (
      <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: 'Bu alan boş bırakılamaz ve rakam içermelidir!'
          },
          {
            pattern: GSM_REGEX,
            message:
              'Lütfen geçerli bir telefon numarası giriniz. Telefon numarası 0 ile başlamak zorundadır!'
          }
        ]}
        hasFeedback
      >
        <Input
          className="kemi_form_input_grey"
          type="number"
          placeholder="GSM Numaranız"
        />
      </Form.Item>
    );
  }

  function CityField() {
    const options = cities.map((city) => (
      <Option value={city.cityId} key={city.cityId}>
        {city.cityName}
      </Option>
    ));

    return (
      <Form.Item
        name="cityId"
        rules={[
          {
            required: true,
            message: REQUIRED_INPUT_MESSAGE
          }
        ]}
        hasFeedback
      >
        <Select
          className="kemi_form_select_grey"
          placeholder="Şehir Seçiniz"
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onChange={(value) => {
            setSelectedCity(value);
          }}
          notFoundContent={null}
        >
          {options}
        </Select>
      </Form.Item>
    );
  }
  function ProvinceField() {
    const options = provinces.map((province) => (
      <Option value={province.provinceId} key={province.provinceId}>
        {province.provinceName}
      </Option>
    ));

    return (
      <Form.Item
        name="provinceId"
        rules={[
          {
            required: true,
            message: REQUIRED_INPUT_MESSAGE
          }
        ]}
        hasFeedback
      >
        <Select
          className="kemi_form_select_grey"
          placeholder=" İlçe Seçiniz"
          defaultActiveFirstOption={false}
          showArrow={true}
          filterOption={false}
          notFoundContent={null}
        >
          {options}
        </Select>
      </Form.Item>
    );
  }

  return (
    // <Grid container direction="column" alignItems="flex-start">
    <div>
      <Form
        form={form}
        {...layout}
        name="pharmacy-register"
        onFinish={onFinish}
        style={{ width: '300px' }}
        size="large"
      >
        <EmailField />
        <EmailConfirmationField />
        <PasswordField />
        <PasswordConfirmationField />
        <NameField />
        <PharmacistNameField />
        <PharmacistSurnameField />
        <CityField />
        <ProvinceField />
        <GlnField />
        <GsmField />
        <CheckKVKK />
        <CheckClarification />

        <Form.Item>
          <Row justify="center">
            <Button
              className="kemi_form_btn_purple"
              type="primary"
              htmlType="submit"
            >
              Kaydol
            </Button>
          </Row>
        </Form.Item>
      </Form>
      <CustomConfirmationDialog />
      {/* </Grid> */}
    </div>
  );
};

export default PharmacyRegister;
