import { formatDateToTurkish } from '@utils/date.helpers';
import { formatTurkishCurrency } from '@utils/numberFormats';
const ModuleType = {
  STOCK: 'STOCK',
  KKI: 'KKI',
  PAP: 'PAP'
};

const AcceptedBarcodeTable = ({ productList, moduleType }) => {
  const decideTable = () => {
    // STOCK AND PAP
    if (moduleType !== ModuleType.KKI) {
      return (
        <div className="overflow-x-scroll 2xl:overflow-x-hidden 3xl:overflow-x-hidden">
          <table className="bg-softGreen w-full">
            <thead>
              <tr className="flex gap-3 2xl:gap-3 3xl:gap-5 px-5 py-2 text-base 2xl:text-base 3xl:text-lg mt-4">
                <th className="w-[45%] 2xl:w-[35%] 3xl:w-[35%] text-start">
                  İlaç Karekod
                </th>
                <th className="w-[8%] 2xl:w-[8%] 3xl:w-[8%] text-start">
                  Alış Tarihi
                </th>
                <th className="w-[8%] 2xl:w-[8%] 3xl:w-[8%] text-start">
                  Satış Tarihi
                </th>
                <th className="w-[20%] 2xl:w-[20%] 3xl:w-[25%] text-start">
                  Tutar (KDV Hariç)
                </th>
              </tr>
            </thead>
            <tbody>
              {productList.map((product, index) => (
                <tr
                  key={product.barcode}
                  className={`${
                    index === 0 ? 'mt-2' : undefined
                  } flex gap-3 2xl:gap-3 3xl:gap-5 px-5 mb-2 text-sm 2xl:text-sm 3xl:text-base`}
                >
                  <td
                    className="w-[45%] 2xl:w-[35%] 3xl:w-[35%] bg-white rounded-md px-2 py-1 overflow-hidden whitespace-nowrap text-sm"
                    title={product.barcode}
                  >
                    {product.barcode}
                  </td>
                  <td className="w-[8%] 2xl:w-[8%] 3xl:w-[8%]">
                    {product?.product_detail?.pharmacyBuyDate
                      ? formatDateToTurkish(
                          +product.product_detail.pharmacyBuyDate
                        )
                      : '-'}
                  </td>
                  <td className="w-[8%] 2xl:w-[8%] 3xl:w-[8%]">
                    {product?.product_detail?.pharmacySaleDate
                      ? formatDateToTurkish(
                          +product.product_detail.pharmacySaleDate
                        )
                      : '-'}
                  </td>
                  <td className="w-[20%] 2xl:w-[20%] 3xl:w-[25%]">
                    <span className="bg-green text-white rounded-md px-2 py-1">
                      {product?.paymentAmount
                        ? formatTurkishCurrency(product?.paymentAmount, 2)
                        : '-'}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      // KKI
      return (
        <div className="overflow-x-scroll 2xl:overflow-x-hidden 3xl:overflow-x-hidden">
          <table className="bg-softGreen w-full">
            <thead>
              <tr className="flex gap-1 2xl:gap-1 3xl:gap-5 px-5 mb-2 text-base 2xl:text-base 3xl:text-lg mt-4">
                <th className="w-[35%] 2xl:w-[35%] 3xl:w-[26%] text-start">
                  İlaç Karekod
                </th>
                <th className="w-[7%] 2xl:w-[7%] 3xl:w-[8%] text-start">
                  Alış Tarihi
                </th>
                <th className="w-[7%] 2xl:w-[7%] 3xl:w-[8%] text-start">
                  Satış Tarihi
                </th>
                <th className="w-[17%] 2xl:w-[17%] 3xl:w-[15%] text-start">
                  Satış Tipi
                </th>
                <th className="w-[24%] 2xl:w-[24%] 3xl:w-[30%] text-start">
                  Depo
                </th>
                <th className="w-[10%] 2xl:w-[10%] 3xl:w-[10%] text-start">
                  Tutar
                </th>
              </tr>
            </thead>

            <tbody>
              {productList.map((product, index) => (
                <tr
                  key={product.barcode}
                  className={`${
                    index === 0 ? 'mt-2' : undefined
                  } flex gap-1 2xl:gap-1 3xl:gap-5 px-5 mb-2 text-sm 2xl:text-sm 3xl:text-base`}
                >
                  <td
                    className="w-[35%] 2xl:w-[35%] 3xl:w-[26%] bg-white rounded-md px-2 py-1 overflow-hidden whitespace-nowrap text-sm"
                    title={product.barcode}
                  >
                    {product.barcode}
                  </td>
                  <td className="w-[7%] 2xl:w-[7%] 3xl:w-[8%]">
                    {product?.product_detail?.pharmacyBuyDate
                      ? formatDateToTurkish(
                          +product.product_detail.pharmacyBuyDate
                        )
                      : '-'}
                  </td>
                  <td className="w-[7%] 2xl:w-[7%] 3xl:w-[8%]">
                    {product?.product_detail?.pharmacySaleDate
                      ? formatDateToTurkish(
                          +product.product_detail.pharmacySaleDate
                        )
                      : '-'}
                  </td>
                  <td
                    className="w-[17%] 2xl:w-[17%] 3xl:w-[15%] overflow-hidden text-ellipsis whitespace-nowrap"
                    title={product?.product_detail?.pharmacySaleToName ?? '-'}
                  >
                    {product?.product_detail?.pharmacySaleToName ?? '-'}
                  </td>
                  <td
                    className="w-[24%] 2xl:w-[24%] 3xl:w-[30%] overflow-hidden text-ellipsis whitespace-nowrap"
                    title={product?.wholesaler ? product.wholesaler?.name : '-'}
                  >
                    {product?.wholesaler ? product.wholesaler?.name : '-'}
                  </td>
                  <td className="w-[10%] 2xl:w-[10%] 3xl:w-[10%]">
                    <span className="bg-green text-white rounded-md px-2 py-1">
                      {product?.paymentAmount
                        ? formatTurkishCurrency(product?.paymentAmount, 2)
                        : '-'}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  };

  return decideTable();
};

export default AcceptedBarcodeTable;
