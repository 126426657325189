import { SearchOutlined } from '@ant-design/icons';
import { usePost } from '@src/api/methods';
import { Button, DatePicker, Input, Space, Table } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import * as XLSX from 'xlsx';
import './UsersActivityTab.scss';

const { RangePicker } = DatePicker;

const UsersActivity = () => {
  const defaultEndDate = moment();
  const defaultStartDate = moment().subtract(7, 'days');
  const [dateRange, setDateRange] = useState([
    defaultStartDate.valueOf(),
    defaultEndDate.valueOf()
  ]);

  const { data, loading, mutate } = usePost('admin/user-actions');

  // const newData = data
  //   ? [
  //       ...data,
  //       {
  //         actionId: 'bff1dc60-54d5-4523-a180-d5b1e75af4ac',
  //         actionType: 'login',
  //         userEmail: 'ssss',
  //         actionPayload: '{}',
  //         note: 'User login',
  //         createdAt: 1705681902751,
  //         updatedAt: 1705681902751,
  //         user: {
  //           userId: 'b0cbdc48-0229-4bef-96b0-98fea013e8f3',
  //           role: {
  //             name: 'Pharmacist'
  //           },
  //           pharmacy: {
  //             gln: '8680001154174'
  //           }
  //         }
  //       }
  //     ]
  //   : [];

  useEffect(() => {
    mutate({
      userType: 'PHARMACY',
      beginDate: dateRange[0],
      endDate: dateRange[1]
    });
  }, [mutate, dateRange]);

  const handleDateChange = (dates) => {
    setDateRange(dates ? [dates[0].valueOf(), dates[1].valueOf()] : []);
  };

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      const recordValue = Array.isArray(dataIndex)
        ? dataIndex.reduce((acc, key) => acc[key], record)
        : record[dataIndex];
      return recordValue
        ? recordValue.toString().toLowerCase().includes(value.toLowerCase())
        : '';
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => {
      const shouldHighlight =
        Array.isArray(dataIndex) && typeof searchedColumn === 'object'
          ? isSubset(searchedColumn, dataIndex)
          : searchedColumn === dataIndex;

      return shouldHighlight ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      );
    }
  });
  const isSubset = (arr1, arr2) =>
    arr1.every((element) => arr2.includes(element));

  // for now it creates all columns which in existing in data, not just table stuff
  const handleXLSXDownloadButton = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
    XLSX.writeFile(
      workbook,
      `${new Date().toLocaleDateString('tr')} PharmacyUsersActivity.xlsx`
    );
  };

  const columns = [
    {
      title: 'İşlem',
      dataIndex: 'actionType',
      key: 'actionType',
      ...getColumnSearchProps('actionType')
    },
    {
      title: 'GLN',
      dataIndex: ['user', 'pharmacy', 'gln'],
      key: 'gln',
      ...getColumnSearchProps(['user', 'pharmacy', 'gln'])
    },
    // we dont have this
    // { title: 'Eczane İsmi', dataIndex: ['user', 'pharmacy', 'gln'], key: 'name' },
    {
      title: 'E-Posta',
      dataIndex: 'userEmail',
      key: 'email',
      ...getColumnSearchProps('userEmail')
    },
    {
      title: 'Tarih',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => moment(createdAt).format('DD.MM.YYYY HH:mm'),
      filters: [
        { text: 'Bugün', value: 'today' },
        { text: 'Bu Hafta', value: 'thisWeek' },
        { text: 'Bu Ay', value: 'thisMonth' }
      ],
      onFilter: (value, record) => {
        const date = moment(record.createdAt);
        if (value === 'today') {
          return date.isSame(moment(), 'day');
        } else if (value === 'thisWeek') {
          return date.isSame(moment(), 'week');
        } else if (value === 'thisMonth') {
          return date.isSame(moment(), 'month');
        }
        return true;
      }
    }
  ];

  return (
    <div className="pharmacy-users-activity-tab__">
      <div className="form-container">
        <div>
          <RangePicker
            format="DD.MM.YYYY"
            className="kemi_form_select"
            style={{ width: 275 }}
            placeholder={['Başlangıç Tarihi', 'Bitiş Tarihi']}
            onChange={handleDateChange}
            defaultValue={[defaultStartDate, defaultEndDate]}
          />
        </div>
        <div>
          <Button
            style={{ width: '7rem' }}
            className="kemi_form_btn_green"
            onClick={handleXLSXDownloadButton}
          >
            .xlsx İndir
          </Button>
        </div>
      </div>

      <Table
        // scroll={{ y: 480 }}
        columns={columns}
        dataSource={data}
        loading={loading}
      />
    </div>
  );
};

export default UsersActivity;
