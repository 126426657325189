import { Form, Input } from 'antd';

function FilterFormReqId() {
  return (
    <Form.Item name="requestId" className="stockloss_active_filter_input">
      <Input
        type={'number'}
        className="kemi_form_input"
        placeholder="Başvuru No"
      />
    </Form.Item>
  );
}

export default FilterFormReqId;
