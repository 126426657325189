const { Form, Select, Spin } = require("antd");
const { Option } = Select;

const FormCityItem = ({ cities }) => {
  return (
    <Form.Item name="cities" className="stockloss_active_filter_input">
      <Select
        mode="multiple"
        allowClear
        showSearch
        placeholder="Eczane Şehir"
        maxTagCount="responsive"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.value.toString().toLowerCase().indexOf(input.toLowerCase()) >=
          0
        }
        className="kemi_form_select"
      >
        {cities.length > 0 ? (
          cities.map((city) => {
            return (
              <Option key={city.cityId} value={city.cityId}>
                {city.cityName}
              </Option>
            );
          })
        ) : (
          <Option disabled>
            <Spin />
          </Option>
        )}
      </Select>
    </Form.Item>
  );
};

export default FormCityItem;
