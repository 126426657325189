import { useEffect, useState } from 'react';

// for steps-id-9
const Countdown = ({ startTime }) => {
  const [time, setTime] = useState(startTime);

  useEffect(() => {
    const interval = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [time]);

  return (
    <span>
      {' '}
      Bu adım <span style={{ color: '#ffc800' }}>{time}</span> saniye sonra
      otomatik olarak değişecektir, bekleyiniz.
    </span>
  );
};

const steps = [
  {
    id: 0,
    selector: '.first-step',
    content: (
      <div class="row">
        <div class="card">
          <p>
            Bu rehber size Kemi Portal'ın kullanımı konusunda yol göstermek
            amacıyla hazırlanmıştır. Ek olarak herhangi bir sorunuz ve öneriniz
            olduğu takdirde websitesi üzerinde sağ alt köşede bulunan{' '}
            <span style={{ color: '#ffc800' }}>Canlı Destek Hattı</span>'ndan
            bize ulaşabilirsiniz.
          </p>
        </div>
      </div>
    )
  },
  {
    id: 1,
    selector: '.ant-tabs-nav-wrap > div > div:nth-child(1)',
    content: (
      <div class="row">
        <div class="card">
          <p>
            Giriş yaptıktan sonra sizi sayfada ilk karşılayan yer{' '}
            <span style={{ color: '#ffc800' }}>Bekleyen Başvurular</span>{' '}
            sekmesi olacaktır.
          </p>
        </div>
      </div>
    )
  },
  {
    id: 2,
    selector: '.ant-tabs-nav-wrap > div > div:nth-child(2)',
    content: (
      <div class="row">
        <div class="card">
          <p>
            Önceden yaptığınız ve sonuçlanan başvurulara{'  '}
            <span style={{ color: '#ffc800' }}>Geçmiş Başvurular</span>{' '}
            sekmesinden ulaşabilirsiniz.
          </p>
        </div>
      </div>
    ),
    stepInteraction: false
  },
  {
    id: 3,
    selector: '.ant-tabs-nav-wrap > div > div:nth-child(3)',
    content: (
      <div class="row">
        <div class="card">
          <p>
            İlaç firmasına geri ödeme talebinde bulunmak için{'  '}
            <span style={{ color: '#ffc800' }}>Yeni Başvuru</span> sekmesinden
            başvuru oluşturabilirsiniz. Başvuru oluşturmadan önce ürünlerin
            satışını İTS'ye bildirdiğinizden emin olun.
            {'  '}
          </p>
          <hr></hr>
          <p>
            Lütfen <span style={{ color: '#ffc800' }}>Yeni Başvuru</span>{' '}
            sekmesine tıklayınız.
          </p>
        </div>
      </div>
    )
  },
  {
    id: 4,
    selector: '.tutorial-tour__step-5',
    content: (
      <div class="row">
        <div class="card">
          <p>İlgili ilaç firmasını seçelim.</p>
        </div>
      </div>
    )
  },
  {
    id: 5,
    selector: '.tutorial-tour__step-6',
    content: (
      <div class="row">
        <div class="card">
          <p>Başvuru yapmak istediğiniz ürünü seçiniz.</p>
        </div>
      </div>
    )
  },
  {
    id: 6,
    selector: '.TextReader--dragger',
    content: (
      <div class="row">
        <div class="card">
          <p>
            Başvurunuzu toplu bir şekilde yapmak için bu alana, alt alta
            sıralanan ve barkod bilgilerinizi içeren bir{' '}
            <span style={{ color: '#ffc800' }}>.txt</span> dosyası
            yükleyebilirsiniz.
          </p>
        </div>
      </div>
    ),
    stepInteraction: false
  },
  {
    id: 7,
    selector: '.exapmletxt--link',
    content: (
      <div class="row">
        <div class="card">
          <p>
            Dosya formatını bu alana tıklayıp indirdikten sonra içeriğini kendi
            barkodlarınızla güncelleyip yükleyebilirsiniz.
          </p>
        </div>
      </div>
    ),
    stepInteraction: false
  },
  {
    id: 8,
    selector: '.pharmacyRequestAddProduct_add_row_btn',
    content: (
      <div class="row">
        <div class="card">
          <p>
            Yüksek miktarda barkod girişi yapmayacaksanız ise{' '}
            <span style={{ color: '#ffc800' }}>"Barkod Ekle"</span> butonuna
            tıklayarak tekli barkod giriş alanı oluşturabilirsiniz.
          </p>
        </div>
      </div>
    ),
    stepInteraction: false
  },
  {
    id: 9,
    selector: '.tutorial-tour__step-8',
    content: (
      <div class="row">
        <div class="card">
          <p>
            Bu yöntemlerden biriyle elinizdeki barkodları yükleyebilirsiniz.
          </p>
          <hr></hr>
          <p>
            <Countdown startTime={5} />
          </p>
        </div>
      </div>
    ),
    stepInteraction: false
  },
  {
    id: 10,
    selector: '.pharmacyRequestAddProduct__ConfirmButton',
    content: (
      <div class="row">
        <div class="card">
          <p>
            Bu bir rehber turu olduğu için şimdilik barkod girişi ve barkod
            kontrolü yapmayacağız. Turumuza devam edebilmek için lütfen{' '}
            <span style={{ color: '#ffc800' }}>Başvuru Oluştur</span> butonuna
            tıklayınız.
          </p>
        </div>
      </div>
    ),
    stepInteraction: true
  },
  {
    id: 11,
    selector: '.ant-form',
    content: (
      <div class="row">
        <div class="card">
          <p>
            ITS kullanıcı isminizi ve şifrenizi bu alandan girebilirsiniz.
            <p>
              Kullanıcı bilgilerini depolamadığımız için her başvurunuzda bu
              bilgileri girmeniz gerekecektir.
            </p>
            <hr></hr>
            <p>
              Veri Güvenliği ile ilgili detaylı bilgiye ulaşmak için;
              <a
                style={{ color: '#ffc800' }}
                href="/kvkk-metni"
                target="_blank"
              >
                <p>KVKK ve Gizlilik Politikası</p>
              </a>
            </p>
          </p>
        </div>
      </div>
    ),
    stepInteraction: false
  },
  {
    id: 12,
    selector: '.kemi_form_btn_purple',
    content: (
      <div class="row">
        <div class="card">
          <p>
            Başvuru oluşturma sürecinin son adımı olarak{' '}
            <span style={{ color: '#ffc800' }}>İTS ile Doğrula</span> butonuna
            tıklayınız.
          </p>
        </div>
      </div>
    ),
    stepInteraction: true
  },
  {
    id: 13,
    selector: '',
    content: (
      <div class="row">
        <div class="card">
          <p>
            Tebrikler Rehber Turunu tamamladınız. Yapacağınız başvuruların
            detaylarını "Bekleyen Başvurular" sekmesinden inceleyebilirsiniz.
          </p>
        </div>
      </div>
    ),
    stepInteraction: true
  }
];

export default steps;
