import { env } from '@src/env';
import { LOGGED, UNLOGGED } from '@src/redux/reducers/auth';
import { store } from '@src/store';
import axios from 'axios';

export const backendBaseUrl = env.REACT_APP_BACKEND_URL;

export const apiInstance = axios.create({
  baseURL: backendBaseUrl,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

// request
apiInstance.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.token;
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response
apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalConfig = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      originalConfig.url !== '/refresh-token'
    ) {
      return await apiInstance
        .post('/refresh-token', {}, { withCredentials: true })
        .then((res) => {
          const newToken = res.data.accessToken;
          store.dispatch(LOGGED(newToken));

          apiInstance.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${newToken}`;
          originalConfig.headers['Authorization'] = `Bearer ${newToken}`;

          return apiInstance.request(originalConfig);
        })
        .catch((err) => {
          store.dispatch(UNLOGGED());
          return Promise.reject(err);
        });
    } else {
      return Promise.reject(error);
    }
  }
);
