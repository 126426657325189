import _ from 'lodash';

function changeRowAttributes(rows) {
  return rows.map((row) => {
    row.gln = row.GLN;
    row.name = row.İSİM;
    row.city = row.ŞEHİR;
    _.omit(row, ['GLN', 'İSİM', 'ŞEHİR']);
    return row;
  });
}

function validateRows(rows) {
  let warehouses = { accepted: [], rejected: [] };

  rows.forEach((row) => {
    if (row.gln === null || row.name === null) {
      // set rejected
      return warehouses.rejected.push(row);
    } else {
      if (!this.checkIsOnlyNumbers(row.gln)) {
        // set rejected
        return warehouses.rejected.push(row);
      }

      return warehouses.accepted.push(row);
    }
  });
  warehouses.accepted = this.arrayUniqueByKey(warehouses.accepted);
  return warehouses;
}

function checkIsOnlyNumbers(gln) {
  const format = /^\d+$/;
  return format.test(gln);
}

function arrayUniqueByKey(array) {
  return [...new Map(array.map((item) => [item.gln, item])).values()];
}

export const warehouseHelpers = {
  changeRowAttributes: changeRowAttributes,
  validateRows: validateRows,
  checkIsOnlyNumbers: checkIsOnlyNumbers,
  arrayUniqueByKey: arrayUniqueByKey,
};
