import '@assets/sharedStyles/pageItems.scss';
import { statusMap } from '@utils/staticJsonObjects/status';
import { Table, Tabs } from 'antd';
import { useState } from 'react';
import TutorialTour from '../Tour/TutorialTour';
import FakePharmacyNewRequest from './FakePharmacyNewRequest/FakePharmacyNewRequest';
const { TabPane } = Tabs;

const columns = [
  {
    title: 'Başvuru No',
    dataIndex: 'requestId',
    render: (text, row) => {
      return row.requestId;
    }
  },

  {
    title: 'İşlem Adı',
    render: (obj, row) => {
      return row.module.name;
    }
  },
  {
    title: 'Firma Adı',
    render: (obj, row) => {
      return row.module.company.name;
    }
  },
  {
    title: 'Depo İsmi',
    render: (obj, row) => {
      return row.wholesaler ? row.wholesaler.name : '-';
    }
  },
  {
    title: 'Tutar',
    dataIndex: 'totalPayment',
    render: (price, row) =>
      row.requestStatus === 'PENDING' ? '-' : price.toFixed(2)
  },
  {
    title: 'Ödeme Durumu',
    dataIndex: 'requestStatus',
    render: (status, row) => statusMap.get(status)
  }
];

const FakePharmacyDashboard = () => {
  const [pendingPharmacyRequests] = useState([
    {
      requestId: 610,
      applicationDate: 1671209593599,
      requestStatus: 'PENDING',
      totalPayment: 0,
      pharmacyId: '7064ff32-b6f5-402d-8ba9-b163c3997f7b',
      moduleId: '096f4e3d-b320-4b59-a963-3adea4f731b8',
      wholesaler: null,
      module: {
        name: 'MERCURIUM 500 MG KAMU KURUM İSKONTOSU ÖDEMESİ',
        moduleId: '096f4e3d-b320-4b59-a963-3adea4f731b8',
        type: 'KKI',
        company: {
          name: 'Pharma Optimum',
          companyId: 'f223e452-5c97-4a60-a729-11cf9ceb513a'
        }
      }
    },
    {
      requestId: 606,
      applicationDate: 1671208268164,
      requestStatus: 'PENDING',
      totalPayment: 0,
      pharmacyId: '7064ff32-b6f5-402d-8ba9-b163c3997f7b',
      moduleId: '096f4e3d-b320-4b59-a963-3adea4f731b8',
      wholesaler: null,
      module: {
        name: 'MERCURIUM 500 MG KAMU KURUM İSKONTOSU ÖDEMESİ',
        moduleId: '096f4e3d-b320-4b59-a963-3adea4f731b8',
        type: 'KKI',
        company: {
          name: 'Pharma Optimum',
          companyId: 'f223e452-5c97-4a60-a729-11cf9ceb513a'
        }
      }
    },
    {
      requestId: 585,
      applicationDate: 1671206687058,
      requestStatus: 'PENDING',
      totalPayment: 0,
      pharmacyId: '7064ff32-b6f5-402d-8ba9-b163c3997f7b',
      moduleId: '096f4e3d-b320-4b59-a963-3adea4f731b8',
      wholesaler: null,
      module: {
        name: 'MERCURIUM 500 MG KAMU KURUM İSKONTOSU ÖDEMESİ',
        moduleId: '096f4e3d-b320-4b59-a963-3adea4f731b8',
        type: 'KKI',
        company: {
          name: 'Pharma Optimum',
          companyId: 'f223e452-5c97-4a60-a729-11cf9ceb513a'
        }
      }
    }
  ]);
  const [oldPharmacyRequests] = useState([
    {
      requestId: 607,
      applicationDate: 1671208729251,
      requestStatus: 'REJECTED',
      totalPayment: 170,
      pharmacyId: '7064ff32-b6f5-402d-8ba9-b163c3997f7b',
      moduleId: '096f4e3d-b320-4b59-a963-3adea4f731b8',
      wholesaler: null,
      module: {
        name: 'MERCURIUM 500 MG KAMU KURUM İSKONTOSU ÖDEMESİ',
        moduleId: '096f4e3d-b320-4b59-a963-3adea4f731b8',
        type: 'KKI',
        company: {
          name: 'Pharma Optimum',
          companyId: 'f223e452-5c97-4a60-a729-11cf9ceb513a'
        }
      }
    },
    {
      requestId: 604,
      applicationDate: 1671208263962,
      requestStatus: 'REJECTED',
      totalPayment: 0,
      pharmacyId: '7064ff32-b6f5-402d-8ba9-b163c3997f7b',
      moduleId: '096f4e3d-b320-4b59-a963-3adea4f731b8',
      wholesaler: null,
      module: {
        name: 'MERCURIUM 500 MG KAMU KURUM İSKONTOSU ÖDEMESİ',
        moduleId: '096f4e3d-b320-4b59-a963-3adea4f731b8',
        type: 'KKI',
        company: {
          name: 'Pharma Optimum',
          companyId: 'f223e452-5c97-4a60-a729-11cf9ceb513a'
        }
      }
    },
    {
      requestId: 601,
      applicationDate: 1671208174010,
      requestStatus: 'REJECTED',
      totalPayment: 0,
      pharmacyId: '7064ff32-b6f5-402d-8ba9-b163c3997f7b',
      moduleId: '096f4e3d-b320-4b59-a963-3adea4f731b8',
      wholesaler: null,
      module: {
        name: 'MERCURIUM 500 MG KAMU KURUM İSKONTOSU ÖDEMESİ',
        moduleId: '096f4e3d-b320-4b59-a963-3adea4f731b8',
        type: 'KKI',
        company: {
          name: 'Pharma Optimum',
          companyId: 'f223e452-5c97-4a60-a729-11cf9ceb513a'
        }
      }
    }
  ]);
  const [newRequestInfo, setNewRequestInfo] = useState({
    isOpen: false,
    currentStep: 0
  });

  return (
    <>
      <div className="kemi_pages_tabs">
        <TutorialTour newRequestInfo={newRequestInfo} />
        <Tabs defaultActiveKey="1">
          <TabPane tab="Bekleyen Başvurular" key="1">
            <Table
              columns={columns}
              dataSource={pendingPharmacyRequests}
              rowKey="requestId"
            />
          </TabPane>
          <TabPane tab="Geçmiş Başvurular" key="2">
            <Table
              columns={columns}
              dataSource={oldPharmacyRequests}
              rowKey="requestId"
            />
          </TabPane>
          <TabPane tab="Yeni Başvuru" key="3">
            <FakePharmacyNewRequest setNewRequestInfo={setNewRequestInfo} />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default FakePharmacyDashboard;
