import { env } from '@src/env';
import { store } from '@src/store';
import { Spin } from 'antd';
import { useEffect } from 'react';

const Redirect = () => {
  const token = store.getState().auth.token;

  useEffect(() => {
    window.location.href = `${env.REACT_APP_KEMI_PHARMACY_URL}?token=${token}`;
    // window.location.href = `http://localhost:5173?token=${token}`;
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Spin size="large" />
    </div>
  );
};

export default Redirect;
