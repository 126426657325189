import '@assets/sharedStyles/formItems.scss';
import {
  GTIN_NUMBER_DIGIT_MESSAGE,
  GTIN_NUMBER_PATTERN_MESSAGE,
  GTIN_NUMBER_REGEX,
  GTIN_NUMBER_REQUIRED_MESSAGE
} from '@src/utils/formRules/formRules';
import { axios_post } from '@utils/rest_axios';
import { Button, Card, Form, Input, Row, message } from 'antd';
import { useState } from 'react';
import './AddProductControl.scss';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
};

const AddProductControl = ({ module, callBack, reload }) => {
  const [form] = Form.useForm();
  const [popup, setpopup] = useState(false);

  const onFinish = async (values) => {
    const data = {
      name: module.name,
      gtin: values.gtin,
      moduleId: module.moduleId
    };
    try {
      const productCompanyRes = await axios_post('/companies-products', data);

      if (productCompanyRes.status === 200) {
        callBack(!reload);
        message.success('Ekleme Başarılı!', 5);
        form.resetFields();
      }
    } catch (err) {
      message.error('Ekleme Başarısız!', 5);
    }
  };
  return (
    <>
      {popup === false && (
        <Button className="kemi_form_btn_purple" onClick={() => setpopup(true)}>
          GTIN Ekle
        </Button>
      )}

      {popup === true && (
        <Card className="addProductControl__card">
          <Row className="addProductControl__card__row" justify="space-between">
            <h4>GTIN Ekle</h4>
            <Button
              className="addProductControl__card__row__button"
              onClick={() => setpopup(false)}
              danger
            >
              X
            </Button>
          </Row>

          <Row justify="center">
            <Form form={form} {...layout} name="add-gtin" onFinish={onFinish}>
              <Form.Item
                name="gtin"
                type="number"
                label="Yeni GTIN Numarası"
                rules={[
                  {
                    required: true,
                    message: GTIN_NUMBER_REQUIRED_MESSAGE
                  },
                  {
                    min: 14,
                    message: GTIN_NUMBER_DIGIT_MESSAGE
                  },
                  {
                    max: 14,
                    message: GTIN_NUMBER_DIGIT_MESSAGE
                  },
                  {
                    pattern: GTIN_NUMBER_REGEX,
                    message: GTIN_NUMBER_PATTERN_MESSAGE
                  }
                ]}
              >
                <Input className="kemi_form_input" type="number" />
              </Form.Item>
              <Form.Item>
                <Button
                  shape="round"
                  type="primary"
                  htmlType="submit"
                  className="kemi_form_btn_dark"
                >
                  Ekle
                </Button>
              </Form.Item>
            </Form>
          </Row>
        </Card>
      )}
    </>
  );
};

export default AddProductControl;
