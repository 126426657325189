import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import { axios_get } from '@utils/rest_axios';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import PharmacistUpdateForm from './PharmacistUpdateForm';
import './PharmacyProfile.scss';
import UpdatePharmacyForm from './UpdatePharmacyForm';

const PharmacyProfile = () => {
    const [pharmacistInfo, setPharmacitInfo] = useState({});

    const { getNotification } = useNotificationPopup();

    useEffect(() => {
        async function getPharmacist() {
            try {
                const result = await axios_get('/me');
                setPharmacitInfo(result.data);
            } catch ({ response }) {
                if (response && response.status !== 401) {
                    getNotification({
                        ...getErrorMessage(response),
                    });
                }
            }
        }

        getPharmacist();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="kemi_pages_cards">
            <Row justify="center">
                <Col xs={24} sm={16} md={12} lg={10} xl={8}>
                    <PharmacistUpdateForm
                        name={pharmacistInfo.name}
                        surname={pharmacistInfo.surname}
                        email={pharmacistInfo.email}
                        phone={pharmacistInfo.phone}
                        userId={pharmacistInfo.userId}
                    />
                </Col>
                <Col xs={24} sm={16} md={12} lg={10} xl={8}>
                    <UpdatePharmacyForm
                        email={pharmacistInfo.pharmacy?.email}
                        gsm={pharmacistInfo.pharmacy?.gsm}
                        name={pharmacistInfo.pharmacy?.name}
                        phone={pharmacistInfo.pharmacy?.phone}
                        gln={pharmacistInfo.pharmacy?.gln}
                    />
                </Col>
            </Row>
        </div>
    );
};
export default PharmacyProfile;
