import '@assets/sharedStyles/modal.scss';
import { usePost } from '@src/api/methods';
import { Button, Form, Input, Modal, message } from 'antd';
import { GrFormClose } from 'react-icons/gr';

function NewUserModal({
  isModalVisible,
  handleModalVisible,
  refecthAdminUsers
}) {
  const [newAdminUserForm] = Form.useForm();

  const { loading, mutate } = usePost('admin/create-admin');

  const submitForm = (values) => {
    mutate(values, {
      onSuccess: () => {
        message.success('Yeni kullanıcı oluşturuldu!', 5);
        refecthAdminUsers({
          userType: 'ADMIN'
        });
        handleModalVisible();
        newAdminUserForm.resetFields();
      },
      onError: () => {
        message.error('Yeni kullanıcı oluşturma başarısız!', 5);
      }
    });
  };

  const CloseIcon = () => (
    <div className="close-icon-container">
      <GrFormClose size={25} color="#fffafe" />
    </div>
  );

  return (
    <Modal
      className="modal"
      centered
      open={isModalVisible}
      closeIcon={<CloseIcon />}
      footer={null}
      title={'Yeni Admin Kullanıcısı Oluştur'}
      onCancel={() => handleModalVisible()}
    >
      <div className="modal-context-container">
        <Form
          className="modal-context-form"
          form={newAdminUserForm}
          onFinish={submitForm}
        >
          <Form.Item
            name="name"
            rules={[{ required: true, message: 'Lütfen isminizi girin!' }]}
          >
            <Input placeholder={'İsim'} />
          </Form.Item>
          <Form.Item
            name="surname"
            rules={[{ required: true, message: 'Lütfen soyisminizi girin!' }]}
          >
            <Input placeholder={'Soyisim'} />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Lütfen şifrenizi girin!' }]}
          >
            <Input placeholder={'Şifre'} />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Lütfen emailinizi girin!' }]}
          >
            <Input placeholder="example@domain.com" />
          </Form.Item>
        </Form>
        <div className="modal-context-footer">
          <Button
            className="modal-button"
            onClick={() => newAdminUserForm.submit()}
            loading={loading}
          >
            Tamam
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default NewUserModal;
