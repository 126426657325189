/* eslint-disable react-hooks/exhaustive-deps */
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { Button, Col, Empty, List, Result, Row } from 'antd';
import { useEffect } from 'react';

const ResultPage = ({ requestData, itsResult }) => {
  const { getNotification } = useNotificationPopup();

  useEffect(() => {
    async function handleInfoNotification() {
      const confirmed = await getNotification({
        title: 'Talebiniz Alınmıştır!',
        message: `Başvuru talebiniz alınmıştır. Geri ödeme talebinizin "Doğrulanmış" ve tutar hesaplanmış olarak oluştuğundan emin olunuz. İTS doğrulaması sonrası başvurunuz iletilecektir.`,
        confirmText: 'Bekleyen Başvurularımı Gör',
        type: 'success',
      });
      if (confirmed) {
        window.location.reload();
      }
    }
    handleInfoNotification();
  }, []);

  function handleRefresh() {
    window.location.reload();
  }
  function decideListType(comparedCodes) {
    // all approved
    if (
      comparedCodes.acceptedProducts.length > 0 &&
      comparedCodes.rejectedProducts.length === 0
    ) {
      return ProductResultList(comparedCodes.acceptedProducts, 'approved');
    }
    // all rejected
    else if (
      comparedCodes.acceptedProducts.length === 0 &&
      comparedCodes.rejectedProducts.length > 0
    ) {
      return ProductResultList(comparedCodes.rejectedProducts, 'rejected');
    }
    // some of them rejected some of them accepted
    else if (
      comparedCodes.acceptedProducts.length > 0 &&
      comparedCodes.rejectedProducts.length > 0
    ) {
      return (
        <Row justify="space-around">
          <Col xs={24} sm={24} md={24} lg={14} xl={8}>
            {ProductResultList(comparedCodes.acceptedProducts, 'approved')}
          </Col>
          <Col xs={24} sm={24} md={24} lg={14} xl={8}>
            {ProductResultList(comparedCodes.rejectedProducts, 'rejected')}
          </Col>
        </Row>
      );
    }
    // error
    else {
      return (
        <div>
          <Empty />
        </div>
      );
    }
  }

  return (
    <div className="pharmacyNewRequest_container">
      <h2 className="pharmacyNewRequest__title">
        {requestData.moduleName}
        <div>
          <Result
            status="success"
            title="Talebiniz Alınmıştır!"
            subTitle="Lütfen başvurunuzun Bekleyen Başvurular sekmesinde yer aldığından emin olunuz. İTS bilgilerinizin hatalı girilmesi durumunda başvurunuz sistem tarafından otomatik olarak reddedilecek ve size bildirim gönderilecektir, bu takdirde yeni bir başvuru oluşturmanız gerekecektir."
            extra={[
              <Button
                key="1"
                className="kemi_form_btn_purple"
                onClick={handleRefresh}
              >
                Bekleyen Başvurular
              </Button>,
            ]}
          />

          {itsResult && decideListType(itsResult.comparedCodes)}
        </div>
      </h2>
    </div>
  );
};

export default ResultPage;

function ProductResultList(barcodes, type) {
  if (type === 'approved') {
    return (
      <>
        <h4>İşleme Alınan Karekodlar</h4>
        <List
          itemLayout="horizontal"
          dataSource={barcodes}
          renderItem={(barcode) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <CheckCircleOutlined
                    style={{ fontSize: '24px', margin: '5px 0 0 0' }}
                  />
                }
                title={barcode}
                description={`${barcode} karekodlu ürün onaylanmıştır.`}
              />
            </List.Item>
          )}
        />
      </>
    );
  } else if (type === 'rejected') {
    return (
      <>
        <h4>Reddedilen Karekodlar</h4>
        <List
          itemLayout="horizontal"
          dataSource={barcodes}
          renderItem={(barcode) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <CloseCircleOutlined
                    style={{ fontSize: '24px', margin: '5px 0 0 0' }}
                  />
                }
                title={barcode}
                description={`${barcode} karekodlu ürün reddedilmiştir`}
              />
            </List.Item>
          )}
        />
      </>
    );
  }
}
