import { Input } from "antd";
import { v4 as uuidv4 } from "uuid";

export default function ProblemKKI({ problemProducts }) {
  function ProblemKkiTableRows() {
    return problemProducts.length > 0 ? (
      problemProducts.map((product) => {
        return (
          <tr key={uuidv4()}>
            <th>
              <Input
                className="tr-input"
                readOnly
                type="text"
                value={product?.product_problem.barcode}
              />
            </th>
            <th>
              {" "}
              <div key={uuidv4()}>{product?.product_problem.note}</div>
            </th>
          </tr>
        );
      })
    ) : (
      <tr>
        <th>-</th>
        <th>-</th>
      </tr>
    );
  }

  //TODO: if barcodes not unique keep uuid else key={product.id}
  return (
    <div className="requestDetailCard_popup_table_container">
      <table>
        <thead>
          <tr>
            <th className="tr-head">Karekod</th>
            <th className="tr-head">Açıklama</th>
          </tr>
        </thead>
        <tbody>
          <ProblemKkiTableRows />
        </tbody>
      </table>
    </div>
  );
}
