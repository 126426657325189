import percentage from '@src/assets/img/percentage.png';
import StatsCard from '@src/components/shared/StatsCard/StatsCard';
import { Col, Divider, Row, Statistic } from 'antd';

function KKIStatCard({ stats }) {
  function decideValue(val) {
    if (val == null) {
      return '-';
    }
    return val;
  }

  return (
    <StatsCard title="ALTERNATİF KKİ" icon={percentage}>
      <div className="statDividerLineContainer">
        <hr className="statDividerLine" />
      </div>
      <Row justify="space-between">
        <Col>
          <Statistic
            title="Bekleyen Ödeme"
            value={decideValue(stats.pendingKkiPayment)}
            prefix={<small>₺</small>}
          />
        </Col>
        <Col>
          <Statistic
            title="Onaylanan Ödeme"
            value={decideValue(stats.acceptedKkiPayment)}
            prefix={<small>₺</small>}
          />
        </Col>
        <Col>
          <Statistic
            title="Taratılan Karekod Sayısı"
            value={decideValue(stats.pharmacyKkiProducts)}
          />
        </Col>
      </Row>
      <Divider />
      <Row justify="space-around">
        <Col>
          <Statistic
            title="Bekleyen Başvuru"
            value={decideValue(stats.kkiPendingRequests)}
          />
        </Col>
        <Col>
          <Statistic
            title="Onaylanan Başvurular"
            value={decideValue(stats.kkiAcceptedRequests)}
          />
        </Col>
      </Row>
    </StatsCard>
  );
}

export default KKIStatCard;
