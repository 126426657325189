import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';

const IntercomInitializer = () => {
  const auth = useSelector((state) => state.auth);
  const { user, role } = auth;

  const { boot, update, shutdown } = useIntercom();

  useEffect(() => {
    const initializeIntercom = () => {
      const userData = user
        ? {
            ...user,
            customAttributes: {
              verifiedgln: user.gln
            }
          }
        : {};

      if (role && role !== 'Worker') {
        boot({ ...userData, hideDefaultLauncher: false });
      } else if (role && role === 'Worker') {
        update({ hideDefaultLauncher: true });
      } else {
        boot({ ...userData, hideDefaultLauncher: false });
      }
    };

    initializeIntercom();

    return () => {
      shutdown();
    };
  }, [role, user, boot, update, shutdown]);

  return null;
};

export default IntercomInitializer;
