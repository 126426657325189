import { message } from 'antd';

/**
 * Validate Object
 * @param object is given object
 * keys & properties should not be empty
 * @returns boolean value of validation result
 */
export const validateObject = (object) => {
  let Errors = [];

  for (const [key, value] of Object.entries(object)) {
    if (value === null || value === undefined) {
      Errors.push(key);
    }
  }
  if (Errors.length > 0) {
    let text = '';
    Errors.map((field_name) => {
      return (text = text + ',' + field_name);
    });
    message.error('Bu alanlar boş bırakılamaz! Doldurup Tekrar Deneyiniz!', 5);
    return false;
  } else {
    return true;
  }
};

/**
 * Validated GTIN Number
 * @param gtin is gtin number
 * should be exact 14 number and should starts with 0869
 * @returns boolean value of validation result
 */
export const ValidateGtin = (gtin) => {
  if (gtin === null || gtin === undefined) {
    message.error('Bu alanlar boş bırakılamaz! Doldurup Tekrar Deneyiniz!', 5);
    return false;
  } else if (gtin.length !== 14) {
    message.error('GTIN Doğru Uzunlukta Değil! Tekrar Deneyiniz!', 5);
    return false;
  } else {
    if (isNumeric(gtin)) {
      return true;
    } else {
      message.error('GTIN Sayı Değil! Tekrar Deneyiniz!', 5);
      return false;
    }
  }
};
function isNumeric(value) {
  return /^-?\d+$/.test(value);
}
