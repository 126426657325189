import '@assets/sharedStyles/formItems.scss';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import { axios_post } from '@src/utils/rest_axios';
import { Button, Card, Form, Input, message } from 'antd';
import './NewCompany.scss';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
  tail: { span: 24 }
};

function NewCompany() {
  const [newCompanyForm] = Form.useForm();

  const { getNotification } = useNotificationPopup();

  const SubmitForm = async (values) => {
    try {
      const data = {
        name: values.companyName
      };
      const createCompanyRes = await axios_post('/admin/companies', data);
      if (createCompanyRes.status === 200) {
        newCompanyForm.resetFields();
        message.success('Firma Oluşturma Başarılı', 5);
      }
    } catch ({ response }) {
      if (response && response.status !== 401) {
        getNotification({
          ...getErrorMessage(response)
        });
      }
    }
  };
  // Form Parts

  function CompanyUserInfo() {
    return (
      <Form.Item
        name="companyName"
        label="Firma İsmi"
        rules={[
          {
            required: true,
            message: 'Firma ismi giriniz.'
          }
        ]}
      >
        <Input
          className="kemi_form_input_grey fixed_width_input Form_input"
          placeholder="Firma İsmi"
        />
      </Form.Item>
    );
  }

  function CreateModuleForm() {
    return (
      <Form
        form={newCompanyForm}
        name="new_company_user_form"
        initialValues={{ company_gln: [''], users: [''], its_infos: [''] }}
        onFinish={SubmitForm}
        autoComplete="off"
        colon={true}
        layout={'horizontal'}
        className="Form"
        wrapperCol={{
          md: { span: 16 }
        }}
      >
        <h3 className="kemi_form_highlighted_header">Firma Bilgileri</h3>
        <CompanyUserInfo />
        <Form.Item {...layout.tail}>
          <Button className="kemi_form_btn_dark" htmlType="submit">
            Oluştur
          </Button>
        </Form.Item>
      </Form>
    );
  }
  return (
    <Card className="newCompany">
      <CreateModuleForm />
    </Card>
  );
}

export default NewCompany;
