import { MailOutlined } from '@ant-design/icons';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import { axios_post } from '@src/utils/rest_axios';
import { Modal, message } from 'antd';
const { confirm } = Modal;

export function ResendVerifyAccountLink({ userId, setRefresh, pharmacyId }) {
  const { getNotification } = useNotificationPopup();

  function confirmDelete() {
    confirm({
      title: 'Tekrar aktivasyon linki yollanacak emin misiniz?',
      icon: <MailOutlined />,
      content: 'Emin misiniz?',
      onOk() {
        handleSendVerifyLink();
      }
    });
  }

  async function handleSendVerifyLink() {
    try {
      const userDeleteRes = await axios_post(`/admin/verify-resend`, {
        pharmacyId: pharmacyId
      });

      if (userDeleteRes.status === 200) {
        message.success('Yeni aktivasyon linki yollanmistir!', 5);
      }
      setRefresh(true);
    } catch ({ response }) {
      if (response && response.status !== 401) {
        getNotification({
          ...getErrorMessage(response)
        });
      }
    }
  }

  return <MailOutlined onClick={confirmDelete} />;
}
