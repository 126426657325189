import { useConfirmationDialog } from '@src/components/shared/ConfirmationPopup/ConfirmationDialog';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import {
  EMAIL_MESSAGE,
  EMAIL_REQUIRED,
  PHONE_REGEX
} from '@src/utils/formRules/formRules';
import { axios_patch } from '@src/utils/rest_axios';
import { validateObject } from '@src/utils/validate';
import { Button, Card, Col, Form, Input, Row, message } from 'antd';
import { useEffect } from 'react';

const PharmacistUpdateForm = ({ userId, name, surname, email, phone }) => {
  const [form] = Form.useForm();
  const { getConfirmation } = useConfirmationDialog();
  const { getNotification } = useNotificationPopup();
  //const [pharmacy, setpharmacy] = useState({});
  useEffect(() => {
    form.setFieldsValue({
      name: name,
      surname: surname,
      email: email,
      phone: phone
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, surname, email, phone]);

  const onFinish = async (values) => {
    const confirmed = await getConfirmation({
      title: 'Profil Bilgileriniz Güncellenecek',
      message: '',
      cancelText: 'Vazgeç',
      confirmText: 'Onayla'
    });
    if (confirmed) {
      const updatePayload = {
        surname: values.surname,
        name: values.name,
        email: values.email,
        phone: values.phone
      };
      if (validateObject(values)) {
        try {
          const userUpdate = await axios_patch(
            `/user/${userId}`,
            updatePayload
          );
          if (userUpdate.status === 200) {
            message.success(
              'Güncelleme Başarılı! Hesap bilgileriniz başarılı bir şekilde güncellendi!',
              5
            );
          }
        } catch ({ response }) {
          if (response && response.status !== 401) {
            getNotification({
              ...getErrorMessage(response)
            });
          }
        }
      }
    }
  };

  return (
    <Card className="pharmacyProfile__card">
      <Form
        layout="vertical"
        form={form}
        name="profile_infos"
        onFinish={onFinish}
      >
        <Row justify="space-around">
          <Col className="pharmacyProfile__card__col">
            <h3 className="pharmacyProfile__card__header">
              Kullanıcı Bilgileri
            </h3>
            <Form.Item name="name" label="Eczacı İsmi:">
              <Input
                onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                className="kemi_form_input"
              />
            </Form.Item>

            <Form.Item name="surname" label="Eczacı Soyadı:">
              <Input
                onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                className="kemi_form_input"
              />
            </Form.Item>

            <Form.Item
              name="email"
              rules={[
                {
                  ...EMAIL_REQUIRED,
                  message: EMAIL_MESSAGE
                }
              ]}
              label="E-posta Adresi:"
            >
              <Input className="kemi_form_input" />
            </Form.Item>

            <Form.Item
              name="phone"
              label="Telefon:"
              rules={[
                {
                  required: true,
                  message: 'Bu alan boş bırakılamaz ve rakam içermelidir!'
                },
                {
                  pattern: PHONE_REGEX,
                  message: 'Lütfen geçerli bir telefon numarası giriniz.'
                }
              ]}
            >
              <Input className="kemi_form_input" />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end" style={{ justifyContent: 'center' }}>
          <Form.Item>
            <Button
              className="kemi_form_btn_purple pharmacyProfile__card__update__btn"
              htmlType="submit"
            >
              Güncelle
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Card>
  );
};
export default PharmacistUpdateForm;
