import { useEffect, useState } from 'react';
import { barChartOptionsTemplate } from './mockStatDatas';
import ApexChart from '@src/components/shared/ApexChart';
import { Card, Row } from 'antd';

export function GraphCard({ stats }) {
  const [graphData, setGraphData] = useState(barChartOptionsTemplate);

  useEffect(() => {
    function convertDataToGraph(data) {
      const labels = [];
      const seriesData = [];

      data.forEach((element) => {
        if (element.count && element.monthEpoch) {
          seriesData.push(element.count);
          labels.push(element.monthEpoch);
        }
      });

      setGraphData({ ...graphData, labels, series: [{ data: seriesData }] });
    }
    if (stats.monthlyProductData) {
      convertDataToGraph(stats.monthlyProductData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stats]);

  return (
    <Row justify="center">
      <Card className="companyDashboard__graphCard">
        <ApexChart series={graphData.series} options={graphData} />
      </Card>
    </Row>
  );
}
