import './InfoVideoFrame.scss';

const InfoVideoFrame = () => (
  <div className="video-responsive">
    <video
      src="https://kemicdn-engwbucfakeddmgu.z01.azurefd.net/videocontainer/kemi_kullanim_rehberi.mp4"
      controls
      width="1080"
      height="720"
      controlsList="nodownload"
      title="Kemi Kullanım Rehberi"
      frameBorder="0"
    ></video>
  </div>
);

export default InfoVideoFrame;
