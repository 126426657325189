import lockIcon from '@src/assets/img/login.png';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import PageBlurSpinner from '@src/components/shared/Spinner/PageBlurSpinner';
import { getErrorMessage } from '@src/utils/errors';
import { REQUIRED_INPUT_MESSAGE } from '@src/utils/formRules/formRules';
import { axios_post } from '@src/utils/rest_axios';
import { Button, Card, Form, Input, Row } from 'antd';
import _ from 'lodash';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import './ITSForm.scss';

const ITSForm = ({
  setRequestData,
  requestData,
  setCurrentStep,
  setITSResult
}) => {
  const { getNotification } = useNotificationPopup();
  const [isloading, setIsLoading] = useState(false);

  const { auth } = useSelector((state) => state);
  const { user } = auth;
  const [itsUsername, setItsUsername] = useState(`${user.gln}0000`);

  const handleLastDigitChange = (type) => {
    const lastDigit = Number(itsUsername[itsUsername.length - 1]);
    if (
      (type === 'increase' && lastDigit !== 9) ||
      (type === 'decrease' && lastDigit !== 0)
    ) {
      const newLastDigit = type === 'increase' ? lastDigit + 1 : lastDigit - 1;
      const newItsUsername = `${itsUsername.slice(0, -1)}${newLastDigit}`;
      setItsUsername(newItsUsername);
    }
  };

  function getItsForm() {
    const handleSubmit = async (values) => {
      const submissionData = {
        ...values,
        itsUsername: itsUsername
      };
      handleForArray(submissionData);
    };

    return (
      <div>
        {isloading && (
          <div className="full-screen-spinner">
            <PageBlurSpinner />
          </div>
        )}
        <Card className="ITSForm__login_card">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <div
              style={{
                width: '100px',
                height: '100px',
                backgroundColor: '#fff',
                borderRadius: '50%',
                textAlign: 'center',
                alignItems: 'center'
              }}
            >
              <img
                src={lockIcon}
                style={{
                  margin: '15px 0 0 0',
                  height: '64px'
                }}
                alt="formIcon"
              />
            </div>
          </div>
          <div>
            <h1 style={{ textAlign: 'center' }}>
              <b>TALEP DOĞRULAMA</b>
            </h1>
          </div>
          <div
            style={{
              display: 'flex',
              textAlign: 'center',
              marginTop: '-30px'
            }}
          >
            <h3>
              Bilgilerinizi İTS ile güvenli bir şekilde doğrulayabilirsiniz.
            </h3>
          </div>
          <div>
            <Form layout="vertical" autoComplete="off" onFinish={handleSubmit}>
              <Form.Item
                label="İTS Kullanıcı Adı"
                className="ITSForm__login__label"
                name="itsUsername"
              >
                <div style={{ display: 'flex' }}>
                  <Input
                    style={{
                      paddingRight: '0px',
                      borderTopRightRadius: '0',
                      borderBottomRightRadius: '0'
                    }}
                    value={itsUsername}
                    disabled
                    className="kemi_form_input custom-disabled"
                  />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button
                      className="kemi_form_input control_buttons"
                      style={{
                        padding: '0 0 0 0',
                        borderBottomRightRadius: '0',
                        borderBottomWidth: '1px',
                        fontSize: '14px'
                      }}
                      onClick={() => handleLastDigitChange('increase')}
                    >
                      +
                    </Button>
                    <Button
                      className="kemi_form_input control_buttons"
                      style={{
                        padding: '0 0 3px 0',
                        borderTopRightRadius: '0',
                        borderTopWidth: '0px',
                        fontSize: '20px'
                      }}
                      onClick={() => handleLastDigitChange('decrease')}
                    >
                      -
                    </Button>
                  </div>
                </div>
              </Form.Item>
              <Form.Item
                label="İTS Şifreniz"
                name="itsPassword"
                rules={[
                  {
                    required: true,
                    message: REQUIRED_INPUT_MESSAGE
                  }
                ]}
              >
                <Input
                  type="password"
                  placeholder="İTS Şifreniz"
                  className="kemi_form_input"
                />
              </Form.Item>

              <Form.Item>
                <Row justify="center">
                  <Button className="kemi_form_btn_purple" htmlType="submit">
                    İTS ile Doğrula
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          </div>
        </Card>
      </div>
    );
  }

  async function handleForArray(values) {
    setIsLoading(true);
    const payload = {
      itsUsername: values.itsUsername,
      itsPassword: values.itsPassword,
      wholesalerId: requestData.wholesalerId || null,
      barcodes: requestData.barcodes,
      moduleId: requestData.moduleId
    };
    if (requestData.type === 'KKI') {
      try {
        const response = await axios_post(
          `/pharmacypages/kki/requests`,
          _.omit(payload, ['wholesalerId'])
        );
        showData(response.data);
      } catch ({ response }) {
        if (response && response.status !== 401) {
          getNotification({
            ...getErrorMessage(response)
          });
        }
      }
    } else if (requestData.type === 'STOCK') {
      try {
        const response = await axios_post(
          `/pharmacypages/stockloss/requests`,
          payload
        );
        showData(response.data);
      } catch ({ response }) {
        if (response && response.status !== 401) {
          getNotification({
            ...getErrorMessage(response)
          });
        }
      }
    }
    setIsLoading(false);
  }

  function showData(data) {
    setCurrentStep(4);
    // setITSResult(data);
  }

  function decideHeaderText() {
    if (requestData.type === 'KKI') {
      return (
        <>
          <h4 className="ITSForm__desc">
            Kamu kurum iskontosu geri ödemenizin hesaplanması ve
            karekodlarınızın doğrulanması için İTS’ye giriş yapmanız
            gerekmektedir.
            <br style={{ marginBottom: '10px' }} />
            Kemi Portal, sürecin yönetilmesi için sadece gerekli olan bilgileri
            İTS üzerinden doğrular ve hasta ve/veya reçete bilgisi gibi özel
            nitelikli kişisel verilerin paylaşılmasının önüne geçerek sizi
            güvenli tarafta tutar.
            <br style={{ marginBottom: '10px' }} />
            Kullanıcı bilginiz, Kemi Portal dahil sizin dışınızda kimse
            tarafından görüntülenemeyecek, kaydedilmeyecek ve hiçbir kişi
            ve/veya kurumla paylaşılmayacaktır. Hesaplamaların yapılması için
            doğrulanacak bilgiler aşağıdaki gibidir:
            <ul style={{ marginTop: '15px' }}>
              <li>GLN numaranız</li>
              <li>Ürün karekod numarası</li>
              <li>Ürünün satışının yapıldığı kurum</li>
            </ul>
            Hesaplamalarınız firmanın yeni satış modeline geçtiği tarihten sonra
            alınan ürünler için yapılacaktır. Hesaplanan tutarlar KDV hariç
            olarak belirtilir.
          </h4>
        </>
      );
    } else {
      return (
        <>
          <h4 className="ITSForm__desc">
            Stok zararınızın hesaplanması ve karekodlarınızın doğrulanması için
            İTS’ye giriş yapmanız gerekmektedir.
            <br style={{ marginBottom: '10px' }} />
            Kemi Portal, sürecin yönetilmesi için sadece gerekli olan bilgileri
            İTS üzerinden doğrular ve hasta ve/veya reçete bilgisi gibi özel
            nitelikli kişisel verilerin paylaşılmasının önüne geçerek sizi
            güvenli tarafta tutar.
            <br style={{ marginBottom: '10px' }} />
            Kullanıcı bilginiz, Kemi Portal dahil sizin dışınızda kimse
            tarafından görüntülenemeyecek, kaydedilmeyecek ve hiçbir kişi
            ve/veya kurumla paylaşılmayacaktır. Hesaplamaların yapılması için
            doğrulanacak bilgiler aşağıdaki gibidir:
            <ul style={{ marginTop: '15px' }}>
              <li>GLN numaranız</li>
              <li>Ürün karekod numarası</li>
              <li>Ürün alış-satış tarihi/stok durumu</li>
            </ul>
          </h4>
        </>
      );
    }
  }

  return (
    <div>
      <div className="ITSForm__container">
        <div className="ITSForm__header">{decideHeaderText()}</div>
        <Row justify="center">{getItsForm()}</Row>
      </div>
    </div>
  );
};
export default ITSForm;
