import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import { EMAIL_MESSAGE, EMAIL_REQUIRED } from '@src/utils/formRules/formRules';
import { axios_patch } from '@src/utils/rest_axios';
import { Button, Form, Input, InputNumber, Modal, message } from 'antd';

export const ModalContent = ({ user, closeModal, setRefresh }) => {
  const { getNotification } = useNotificationPopup();

  const { confirm } = Modal;

  const [updateUserForm] = Form.useForm();

  function confirmUpdate(values) {
    confirm({
      title: 'Kullanıcı Güncellenecek',
      icon: <ExclamationCircleOutlined />,
      content: 'Emin misiniz?',
      onOk() {
        handleUpdateUser(values);
      }
    });
  }

  async function handleUpdateUser(values) {
    try {
      const userUpdateRes = await axios_patch(
        `/admin/user/${user.userId}`,
        values
      );
      if (userUpdateRes.status === 200) {
        message.success('Kullanıcı Güncelleme Başarılı!', 5);
        closeModal();
        setRefresh(true);
      }
    } catch ({ response }) {
      if (response && response.status !== 401) {
        getNotification({
          ...getErrorMessage(response)
        });
      }
    }
  }

  function SubmitForm(values) {
    confirmUpdate(values);
  }

  return (
    <Form
      form={updateUserForm}
      name="updateUserForm"
      initialValues={{
        name: user.name,
        surname: user.surname,
        email: user.email,
        isActive: user.isActive
      }}
      onFinish={SubmitForm}
      autoComplete="off"
      colon={true}
      layout={'horizontal'}
      className="Form"
      wrapperCol={{
        md: { span: 16 }
      }}
    >
      <Form.Item
        name="name"
        label="İsim"
        rules={[
          {
            required: true,
            message: 'Admin Kullanıcı ismi giriniz.'
          }
        ]}
      >
        <Input
          className="kemi_form_input_grey fixed_width_input"
          placeholder="Admin Kullanıcı İsmi"
        />
      </Form.Item>

      <Form.Item
        name="surname"
        label="Soyisim"
        rules={[
          {
            required: true,
            message: 'Admin Kullanıcı soyismi giriniz.'
          }
        ]}
      >
        <Input
          className="kemi_form_input_grey fixed_width_input"
          placeholder="Admin Kullanıcı Soyismi"
        />
      </Form.Item>

      <Form.Item
        name="email"
        label="E-posta"
        rules={[
          {
            required: true,
            message: 'Admin Kullanıcı e-postası giriniz.'
          },
          {
            ...EMAIL_REQUIRED,
            message: EMAIL_MESSAGE
          }
        ]}
      >
        <Input
          className="kemi_form_input_grey fixed_width_input"
          placeholder="E-posta"
          type="email"
        />
      </Form.Item>

      <Form.Item
        name="isActive"
        label="Aktif"
        rules={[
          {
            required: true
          }
        ]}
      >
        <InputNumber
          min={0}
          max={1}
          className="kemi_form_input_grey fixed_width_input"
          placeholder="Aktif"
          type="number"
        />
      </Form.Item>

      <Form.Item>
        <Button className="kemi_form_btn_dark" htmlType="submit">
          Güncelle
        </Button>
      </Form.Item>
    </Form>
  );
};
