import '@assets/sharedStyles/pageItems.scss';
import KkiActiveRequests from '../../../components/company_components/pharmacy_reqs/KkiRequests/KkiActiveRequests';

const PharmacyKKIRequests = () => {
  return (
    <div className="kemi_pages_tabs">
      <KkiActiveRequests />
    </div>
  );
};

export default PharmacyKKIRequests;
