export const barChartOptionsTemplate = {
  series: [
    {
      name: 'Kutu Sayısı',
      data: [],
    },
  ],
  chart: {
    height: 350,
    type: 'bar',
  },
  stroke: {
    width: [0, 4],
  },
  title: {
    text: 'KKİ Finansal Veriler',
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: [1],
  },
  xaxis: {
    type: 'datetime',
    tickPlacement: 'on',
    labels: {
      format: 'MM.yyyy',
    },
    gridThickness: 2,
  },
  yaxis: [
    {
      title: {
        text: 'Kutu Sayısı',
      },
    },
  ],
  noData: {
    text: 'Yeterli Veri Bulunmamaktadır',
    align: 'center',
    verticalAlign: 'middle',
  },
};
