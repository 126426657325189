// ALL REGEX REQUIRED
export const PHONE_REGEX = new RegExp(
  // eslint-disable-next-line no-useless-escape
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
);

export const GSM_REGEX = new RegExp(
  // eslint-disable-next-line no-useless-escape
  /^(05)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/
);

export const GTIN_NUMBER_REGEX = new RegExp(/^0869+|^0868+/);

export const GLN_NUMBER_REGEX = new RegExp(/^86/);

// email required
export const EMAIL_REQUIRED = {
  required: true,
  type: 'email',
};

// ALL MESSAGES

// imperative input message
export const REQUIRED_INPUT_MESSAGE = 'Bu alan boş bırakılamaz!';

// password message
export const PASSWORD_MESSAGE = 'Şifreniz en az 6 karakter olmalıdır.';

// e-mail message
export const EMAIL_MESSAGE = 'Geçerli bir e-posta adresi giriniz!';

// gtin messages
export const GTIN_NUMBER_REQUIRED_MESSAGE = 'İlaç GTIN numarası giriniz.';
export const GTIN_NUMBER_DIGIT_MESSAGE = '14 haneli olmalıdır.';
export const GTIN_NUMBER_PATTERN_MESSAGE =
  'GTIN numarası 0869 veya 0868 ile başlamalıdır.';

// gln messages
export const GLN_NUMBER_REQUIRED_MESSAGE = 'GLN numarası giriniz.';
export const GLN_NUMBER_DIGIT_MESSAGE = '13 haneli olmalıdır.';
export const GLN_NUMBER_PATTERN_MESSAGE =
  "GLN Numarası '86' ile başlamak zorundadır.";

// companyId messages
export const COMPANY_ID_MESSAGE = 'Firma seçmek zorunludur.';

// price message
export const PRICE_REQUIRED_MESSAGE = 'Fiyat giriniz.';

// date range message
export const DATE_REQUIRED_MESSAGE = 'Tarih aralığı seçiniz.';

export const ITS_GLN_LENGTH = '17 rakamdan oluşmalıdır.';
