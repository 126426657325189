import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import '@assets/sharedStyles/formItems.scss';
import { useConfirmationDialog } from '@src/components/shared/ConfirmationPopup/ConfirmationDialog';
import { addLocalOffsetToDateObject } from '@src/utils/date.helpers';
import { PRICE_REQUIRED_MESSAGE } from '@src/utils/formRules/formRules';
import { axios_delete, axios_patch } from '@utils/rest_axios';
import { Button, DatePicker, Form, Input, message } from 'antd';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import './UpdateModuleControlPrice.scss';

const UpdateModuleControlPrice = ({
  module_control,
  callBack,
  reload,
  type
}) => {
  const [form] = Form.useForm();

  const { getConfirmation } = useConfirmationDialog();

  const onFinish = async (values) => {
    const priceBeginningWithTime = addLocalOffsetToDateObject(
      values.priceBeginning
    );
    const priceBeginningEpochWithOffset = priceBeginningWithTime.valueOf();
    const data = {
      price: values.price,
      priceBeginning: priceBeginningEpochWithOffset
    };

    if (
      module_control.price === values.price &&
      values.priceBeginning === module_control.priceBeginning
    ) {
      message.warning('Değişiklik Bulunmuyor!', 5);
    } else {
      try {
        // handle data about error on above
        const moduleControlRes = await axios_patch(
          `/module-controls/${module_control.moduleControlId}`,
          {
            price: +data.price,
            priceBeginning: data.priceBeginning
          }
        );

        if (moduleControlRes.status === 200) {
          callBack(!reload);
          message.success('Güncelleme Başarılı!', 5);
        }
      } catch ({ response }) {
        message.error('Güncelleme Başarısız!', 5);
      }
    }
  };

  const handleDelete = async () => {
    const confirmed = await getConfirmation({
      title: 'Fiyat aralığını silmek istediğinizden emin misiniz?',
      message: '',
      cancelText: 'Vazgeç',
      confirmText: 'Onayla'
    });
    if (confirmed) {
      try {
        await axios_delete('/module-controls/', module_control.moduleControlId);
        callBack(!reload);
        message.success('Fiyat Silme Başarılı!', 5);
      } catch ({ response }) {
        message.error('Fiyat Silinemedi', 5);
      }
    }
  };
  return (
    <div>
      <Form
        initialValues={{
          price: module_control.price,
          priceBeginning: moment(module_control.priceBeginning)
        }}
        form={form}
        layout={'inline'}
        name="update-module-control-name"
        onFinish={onFinish}
        colon={true}
      >
        <div className="updateModuleControlPrice">
          <div className="updateModuleControlPrice__price">
            <Form.Item
              label={
                type === 'KKI'
                  ? 'Iskonto Tutarı'
                  : type === 'PAP'
                  ? 'Eczaneye Ödenecek Tutar'
                  : 'Fiyatı'
              }
              name="price"
              rules={[
                {
                  required: true,
                  message: PRICE_REQUIRED_MESSAGE
                }
              ]}
            >
              <NumericFormat
                customInput={Input}
                className="kemi_form_input_grey Form__input__price"
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                defaultValue="0,00"
                isNumericString={true}
              />
            </Form.Item>

            <span className="updateModuleControlPrice_date_label_align">
              Başlangıç Tarihi:
            </span>
            <Form.Item name="priceBeginning">
              <DatePicker className="kemi_form_input" format={'DD.MM.YYYY'} />
            </Form.Item>

            <Form.Item name="buttons">
              <Button
                className="updateModuleControlPrice__moreButton__button kemi_form_btn_purple form_btn_margin"
                shape="round"
                htmlType="submit"
              >
                <EditOutlined />
                Güncelle
              </Button>
            </Form.Item>

            <Form.Item name="buttons2">
              <Button
                className="updateModuleControlPrice__moreButton__button kemi_form_btn_purple form_btn_margin"
                shape="round"
                danger
                type="secondary"
                onClick={handleDelete}
              >
                <DeleteOutlined />
                Aralığı Sil
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default UpdateModuleControlPrice;
