// import { Grid } from '@material-ui/core';
import '@src/assets/sharedStyles/formItems.scss';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import useUrlSearchParamQuery from '@src/utils/customHooks/useUrlSearchParamQuery';
import { axios_post_public } from '@src/utils/rest_axios';
import { Button, Form } from 'antd';
import { useState } from 'react';
import './ActivationPage.scss';

function ActivationPage() {
  const [isButtonDeactive, setIsButtonDeactive] = useState(false);
  const [activationForm] = Form.useForm();
  const query = useUrlSearchParamQuery();
  const { getNotification } = useNotificationPopup();

  async function handleActivate(values) {
    setIsButtonDeactive(true);
    const activationInfo = {
      userId: query.get('userId'),
      token: query.get('token')
    };
    try {
      const response = await axios_post_public('/verify', activationInfo);
      if (response.status === 200) {
        const confirmed = await getNotification({
          title: 'Tebrikler, hesabınız aktive edilmiştir.',
          message: 'Bilgileriniz ile giriş yapabilirsiniz.',
          confirmText: 'Tamam'
        });

        if (confirmed) {
          window.location.href = '/';
        }
      }
    } catch (err) {
      const confirmed = await getNotification({
        title: 'Kullanıcı Aktivasyonu Başarısız!',
        message: 'Hesabınız aktifleştirilemedi.',
        confirmText: 'Tamam'
      });

      if (confirmed) {
        window.location.href = '/';
      }
    }
  }

  return (
    <div className="ActivationPage">
      {/* <Grid
        className="ActivationPage__form__grid"
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
      > */}
      <div>
        <h3>
          Aktifleştir butonuna tıklayarak hesabınızı aktifleştirebilirsiniz.
        </h3>
        <Form
          form={activationForm}
          name="activationForm"
          onFinish={handleActivate}
          autoComplete="off"
          colon={true}
          layout={'horizontal'}
          className="ActivationPage__form"
          wrapperCol={{
            md: { span: 16 }
          }}
        >
          <Form.Item>
            <Button
              disabled={isButtonDeactive}
              size="large"
              className="kemi_form_btn_purple"
              htmlType="submit"
            >
              Aktifleştir
            </Button>
          </Form.Item>
        </Form>
        {/* </Grid> */}
      </div>
    </div>
  );
}

export default ActivationPage;
