import { createRoot } from 'react-dom/client';
import App from './App';
import { env } from './env';

const container = document.getElementById('root');
const root = createRoot(container);

if (env.REACT_APP_SHOULD_HIDE_SEO === 'true') {
  const meta = document.createElement('meta');
  meta.name = 'robots';
  meta.content = 'noindex';
  document.head.appendChild(meta);
}

root.render(<App />);
