import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import { useState } from 'react';

// PART BUTTONS
export default function PartButtons({ tabs, setTabs, problemProducts }) {
  const [visitedFlag, setViisitedFlag] = useState(false);

  function decideProblemBtnStyle() {
    if (tabs === 'Problem_Tab') {
      return 'active';
    } else {
      if (problemProducts.length > 0) {
        return 'problem_passive';
      } else {
        return 'passive';
      }
    }
  }

  function decideBadge() {
    if (problemProducts.length > 0) {
      if (tabs === 'Problem_Tab') {
        return <Badge status="error" />;
      } else {
        if (!visitedFlag) {
          return (
            <Badge className="turn-badge" count={problemProducts.length} />
          );
        }
        return <Badge status="error" />;
      }
    }
  }

  function handleDisableClick() {
    if (tabs === 'Accepted_Tab') {
      setTabs('Problem_Tab');
    }
    if (!visitedFlag) {
      setViisitedFlag(true);
    }
  }
  return (
    <div className="requestDetailCard_popup_tabs_buttons">
      <button
        className={`requestDetailCard_popup_tabs_buttons_btn ${
          tabs === 'Accepted_Tab' ? 'active' : 'passive'
        }`}
        onClick={() => (tabs === 'Problem_Tab' ? setTabs('Accepted_Tab') : '')}
      >
        <CheckCircleOutlined
          className={`requestDetailCard_popup_tabs_buttons_btn_icon ${
            tabs === 'Problem_Tab' ? 'passive_icon' : ''
          }`}
        />
        <div className="requestDetailCard_popup_tabs_buttons_btn_text">
          İşleme Alınan Karekodlar
        </div>
      </button>
      <button
        className={`requestDetailCard_popup_tabs_buttons_btn ${decideProblemBtnStyle()}`}
        disabled={problemProducts.length === 0}
        onClick={() => handleDisableClick()}
      >
        <br />
        <CloseCircleOutlined
          className={`requestDetailCard_popup_tabs_buttons_btn_icon ${
            tabs === 'Accepted_Tab' ? 'passive_icon' : ''
          }`}
        />
        <div className="requestDetailCard_popup_tabs_buttons_btn_text">
          {decideBadge()}
          İşleme Alınmayan Karekodlar
        </div>
      </button>
    </div>
  );
}
