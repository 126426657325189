import { Divider } from 'antd';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

// modal content for PharmacyRequestAddProduct

function ModalContent({ accepted, rejected }) {
  return (
    <div>
      <h4>Kontrolden geçen karekodların İTS doğrulaması için devam ediniz.</h4>
      <p>Karekodların kontrol kriterleri aşağıdaki gibidir;</p>
      <ul>
        <li>Karekod formatı</li>
        <li>Ürün-GTIN eşleşmesi</li>
      </ul>
      {accepted.length > 0 && (
        <>
          <Divider />
          <h3>Karekod Kontrolünü Geçen Karekodlar</h3>
          <div
            style={{
              fontSize: '13px',
              borderRadius: '8px',
              backgroundColor: '#82ce34',
              padding: '10px',
              color: '#444',
              maxHeight: '350px',
              overflow: 'auto',
            }}
          >
            {accepted.map((barcode) => {
              return <p key={uuidv4()}>{barcode}</p>;
            })}
          </div>
        </>
      )}

      {rejected.length > 0 && (
        <>
          <Divider />
          <h3>Karekod Kontrolü Sırasında Reddedilen Karekodlar</h3>
          <div
            style={{
              fontSize: '13px',
              borderRadius: '8px',
              backgroundColor: '#f15e5e',
              padding: '10px',
              color: '#444',
              maxHeight: '350px',
              overflow: 'auto',
            }}
          >
            {rejected.map((barcode) => {
              return <p key={uuidv4()}>{barcode}</p>;
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default ModalContent;
