import { useConfirmationDialog } from '@src/components/shared/ConfirmationPopup/ConfirmationDialog';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import { axios_patch } from '@utils/rest_axios';
import { Switch, message } from 'antd';

/**
 * TODO
 * Attribute of active or deactive is not in the body prob that and set default
 * Check before handlestatus confirmation pupup
 * In case, check status active => INACTIVE || deactive => active
 */
function ModuleSwitchButton({ moduleId, current_status, setReload, reload }) {
  const { getConfirmation } = useConfirmationDialog();
  const { getNotification } = useNotificationPopup();

  const handleStatus = async () => {
    const confirmed = await getConfirmation({
      title: `Modül durumu ${
        current_status === 'ACTIVE' ? 'Deaktif' : 'Aktif'
      } olarak değiştirilecektir.`,
      message: '',
      cancelText: 'Vazgeç',
      confirmText: 'Onayla'
    });

    if (confirmed) {
      const data = {
        status: current_status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
      };
      try {
        const getUpdatedModule = await axios_patch(
          `/modules/${moduleId}`,
          data
        );
        if (getUpdatedModule.status === 200) {
          message.success('Modül durumu başarıyla güncellendi', 5);
          setReload(!reload);
        }
      } catch ({ response }) {
        if (response && response.status !== 401) {
          getNotification({
            ...getErrorMessage(response)
          });
        }
      }
    }
  };
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Switch
        checkedChildren="Aktif"
        unCheckedChildren="Deaktif"
        checked={current_status === 'ACTIVE' ? true : false}
        onClick={handleStatus}
      />
    </div>
  );
}

export default ModuleSwitchButton;
