import React from 'react';
import { Table } from 'antd';
import { axios_get } from '@src/utils/rest_axios';

const columns = [
  {
    title: 'Firma',
    dataIndex: 'companyName',
    key: 'companyName',
  },
  {
    title: 'İşlem Tipi',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'İşlem',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Eczane Sayısı',
    key: 'pharmacyCount',
    dataIndex: 'pharmacyCount',
  },
  {
    title: 'MTD Kutu',
    key: 'MtdPharmacyProductCount',
    render: (item) => {
      if (item['type'] === 'KKI') {
        return item['MtdPharmacyKkiProductCount'];
      } else {
        return item['MtdPharmacyProductCount'];
      }
    },
  },
  {
    title: 'YTD Kutu',
    key: 'YtdPharmacyProductCount',
    render: (item) => {
      if (item['type'] === 'KKI') {
        return item['YtdPharmacyKkiProductCount'];
      } else {
        return item['YtdPharmacyProductCount'];
      }
    },
  },
];
export const CompanyStats = () => {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    async function fetchData() {
      const result = await axios_get('/admin/module-stats');
      if (result.status !== 200) {
        return;
      }
      setData(result.data);
    }
    fetchData();
  }, []);
  return (
    <div className="CompanyStats">
      <Table rowKey="moduleId" columns={columns} dataSource={data} />
    </div>
  );
};
