import '@assets/sharedStyles/formItems.scss';
import '@assets/sharedStyles/pageItems.scss';
import { Tabs } from 'antd';
import { useEffect, useState } from 'react';
import './Notifications.scss';

import { ExistingNotifications } from './ExistingNotifications';

const { TabPane } = Tabs;

const AdminNotifications = () => {
  const [callback, setCallback] = useState(false);

  useEffect(() => {
    return () => {
      setCallback(!callback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback]);

  return (
    <div className="notifications kemi_pages_tabs">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Geçmiş Duyurular" key="2">
          <div>
            <ExistingNotifications
              callback={callback}
              setCallback={setCallback}
            />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};
export default AdminNotifications;
