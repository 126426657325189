import { useConfirmationDialog } from '@src/components/shared/ConfirmationPopup/ConfirmationDialog';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import {
  PHONE_REGEX,
  REQUIRED_INPUT_MESSAGE
} from '@src/utils/formRules/formRules';
import { axios_patch } from '@src/utils/rest_axios';
import { Button, Card, Col, Form, Input, Row, message } from 'antd';
import { useEffect } from 'react';

const UpdatePharmacyForm = ({ name, email, phone, gsm, gln }) => {
  const [form] = Form.useForm();
  const { getConfirmation } = useConfirmationDialog();
  const { getNotification } = useNotificationPopup();

  useEffect(() => {
    form.setFieldsValue({
      name: name,
      email: email,
      phone: phone,
      gln: gln,
      gsm: gsm
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, email, phone, gsm, gln]);

  const onFinish = async (values) => {
    const confirmed = await getConfirmation({
      title: 'Profil Bilgileriniz Güncellenecek',
      message: '',
      cancelText: 'Vazgeç',
      confirmText: 'Onayla'
    });
    if (confirmed) {
      const updatePayload = {
        name: values.name,
        email: values.email,
        phone: values.phone,
        gsm: values.gsm
      };
      try {
        const pharmacyUpdateRes = await axios_patch(
          `/pharmacypages/pharmacy`,
          updatePayload
        );
        if (pharmacyUpdateRes.status === 200) {
          message.success(
            'Güncelleme Başarılı! Hesap bilgileriniz başarılı bir şekilde güncellendi!',
            5
          );
        }
      } catch ({ response }) {
        if (response && response.status !== 401) {
          getNotification({
            ...getErrorMessage(response)
          });
        }
      }
    }
  };

  return (
    <Card className="pharmacyProfile__card">
      <Form
        layout="vertical"
        form={form}
        name="profile_infos"
        onFinish={onFinish}
      >
        <Row justify="space-around">
          <Col className="pharmacyProfile__card__col">
            <h3 className="pharmacyProfile__card__header">Eczane Bilgileri</h3>

            <Form.Item name="gln" label="Eczane GLN Numarası:">
              <Input disabled={true} className="kemi_form_input" />
            </Form.Item>

            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: REQUIRED_INPUT_MESSAGE
                }
              ]}
              label="Eczane İsmi:"
            >
              <Input
                onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                className="kemi_form_input"
              />
            </Form.Item>

            {/* <Form.Item
              name="email"
              rules={[
                {
                  ...EMAIL_REQUIRED,
                  message: EMAIL_MESSAGE,
                },
              ]}
              label="E-posta Adresi:"
            >
              <Input className="kemi_form_input" />
            </Form.Item> */}

            {/* <Form.Item
              name="gsm"
              label="Gsm:"
              rules={[
                {
                  required: true,
                  message: 'Bu alan boş bırakılamaz ve rakam içermelidir!',
                },
                {
                  pattern: GSM_REGEX,
                  message: 'Lütfen geçerli bir telefon numarası giriniz.',
                },
              ]}
            >
              <Input className="kemi_form_input" />
            </Form.Item> */}

            <Form.Item
              name="phone"
              label="Telefon:"
              rules={[
                {
                  required: true,
                  message: 'Bu alan boş kalamaz ve rakam içermelidir!'
                },
                {
                  pattern: PHONE_REGEX,
                  message: 'Lütfen geçerli bir telefon numarası giriniz.'
                }
              ]}
            >
              <Input className="kemi_form_input" />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end" style={{ justifyContent: 'center' }}>
          <Form.Item>
            <Button
              className="kemi_form_btn_purple pharmacyProfile__card__update__btn"
              htmlType="submit"
            >
              Güncelle
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Card>
  );
};

export default UpdatePharmacyForm;
