import '@assets/sharedStyles/formItems.scss';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import { EMAIL_MESSAGE, EMAIL_REQUIRED } from '@src/utils/formRules/formRules';
import { axios_post } from '@src/utils/rest_axios';
import { Button, Card, Form, Input, message } from 'antd';
import './NewCompanyWorker.scss';
import SelectCompanyInput from './SelectCompanyInput';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
  tail: { span: 24 }
};
function NewCompanyWorker() {
  const [newCompanyUserForm] = Form.useForm();

  const { getNotification } = useNotificationPopup();

  const SubmitForm = async (values) => {
    try {
      const data = {
        name: values.name,
        surname: values.surname,
        email: values.email,
        companyId: values.companyId
      };
      const createCompanyWorkerRes = await axios_post(
        '/admin/create-worker',
        data
      );
      if (createCompanyWorkerRes.status === 200) {
        newCompanyUserForm.resetFields();
        message.success('Firma Kullanıcısı Oluşturma Başarılı', 5);
      }
    } catch ({ response }) {
      if (response && response.status !== 401) {
        getNotification({
          ...getErrorMessage(response)
        });
      }
    }
  };
  // Form Parts
  function CreateModuleForm() {
    return (
      <Form
        form={newCompanyUserForm}
        name="new_company_user_form"
        onFinish={SubmitForm}
        autoComplete="off"
        colon={true}
        layout={'horizontal'}
        className="Form"
        wrapperCol={{
          md: { span: 16 }
        }}
      >
        <h3 className="kemi_form_highlighted_header">
          Firma Kullanıcı Bilgileri
        </h3>
        <Form.Item
          name="name"
          label="İsim"
          rules={[
            {
              required: true,
              message: 'Admin Kullanıcı ismi giriniz.'
            }
          ]}
        >
          <Input
            className="kemi_form_input_grey fixed_width_input"
            placeholder="Kullanıcı İsmi"
          />
        </Form.Item>

        <Form.Item
          name="surname"
          label="Soyisim"
          rules={[
            {
              required: true,
              message: 'Admin Kullanıcı soyismi giriniz.'
            }
          ]}
        >
          <Input
            className="kemi_form_input_grey fixed_width_input"
            placeholder="Admin Kullanıcı Soyismi"
          />
        </Form.Item>

        <SelectCompanyInput />

        <Form.Item
          name="email"
          label="E-posta"
          rules={[
            {
              required: true,
              message: 'Admin Kullanıcı e-postası giriniz.'
            },
            {
              ...EMAIL_REQUIRED,
              message: EMAIL_MESSAGE
            }
          ]}
        >
          <Input
            className="kemi_form_input_grey fixed_width_input"
            placeholder="E-posta"
            type="email"
          />
        </Form.Item>
        <Form.Item {...layout.tail}>
          <Button className="kemi_form_btn_dark" htmlType="submit">
            Oluştur
          </Button>
        </Form.Item>
      </Form>
    );
  }
  return (
    <Card className="newCompany">
      <CreateModuleForm />
    </Card>
  );
}

export default NewCompanyWorker;
