import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import {
  EMAIL_MESSAGE,
  EMAIL_REQUIRED,
  REQUIRED_INPUT_MESSAGE
} from '@src/utils/formRules/formRules';
import { axios_patch } from '@src/utils/rest_axios';
import { Button, Form, Input, Modal, message } from 'antd';
import _ from 'lodash';

export const ModalContent = ({ pharmacy, closeModal, setRefresh }) => {
  const { getNotification } = useNotificationPopup();

  const { confirm } = Modal;

  const [updatePharmacyForm] = Form.useForm();

  function confirmUpdate(values) {
    confirm({
      title: 'Eczane Bilgileri Güncellenecek',
      icon: <ExclamationCircleOutlined />,
      content: 'Emin misiniz?',
      onOk() {
        handleUpdatePharmacy(values);
      }
    });
  }

  async function handleUpdatePharmacy(values) {
    try {
      const valuesWithoutNull = _.omitBy(values, (v) => v === null);
      const pharmacyUpdateRes = await axios_patch(
        `/admin/pharmacy/${pharmacy.pharmacyId}`,
        valuesWithoutNull
      );
      if (pharmacyUpdateRes.status === 200) {
        message.success('Eczane Güncelleme Başarılı!', 5);
        closeModal();
        setRefresh(true);
      }
    } catch ({ response }) {
      if (response && response.status !== 401) {
        getNotification({
          ...getErrorMessage(response)
        });
      }
    }
  }

  function SubmitForm(values) {
    confirmUpdate(values);
  }

  return (
    <Form
      form={updatePharmacyForm}
      name="updatePharmacyForm"
      initialValues={{
        name: pharmacy.name,
        phone: pharmacy.phone,
        email: pharmacy.email,
        gln: pharmacy.gln,
        gsm: pharmacy.gsm
      }}
      onFinish={SubmitForm}
      autoComplete="off"
      colon={true}
      layout={'horizontal'}
      className="Form"
      wrapperCol={{
        md: { span: 16 }
      }}
    >
      <Form.Item
        name="name"
        label="İsim"
        rules={[
          {
            required: true,
            message: REQUIRED_INPUT_MESSAGE
          }
        ]}
        style={{ justifyContent: 'space-between' }}
      >
        <Input className="kemi_form_input_grey fixed_width_input" />
      </Form.Item>

      <Form.Item
        name="phone"
        label="Telefon"
        rules={[
          {
            required: true,
            message: REQUIRED_INPUT_MESSAGE
          }
        ]}
        style={{ justifyContent: 'space-between' }}
      >
        <Input className="kemi_form_input_grey fixed_width_input" />
      </Form.Item>
      <Form.Item
        name="gsm"
        label="GSM"
        style={{ justifyContent: 'space-between' }}
      >
        <Input className="kemi_form_input_grey fixed_width_input" />
      </Form.Item>
      <Form.Item
        name="gln"
        label="GLN"
        rules={[
          {
            required: true
          }
        ]}
        style={{ justifyContent: 'space-between' }}
      >
        <Input
          disabled={true}
          className="kemi_form_input_grey fixed_width_input"
        />
      </Form.Item>

      <Form.Item
        name="email"
        label="E-posta"
        rules={[
          {
            ...EMAIL_REQUIRED,
            message: EMAIL_MESSAGE
          }
        ]}
        style={{ justifyContent: 'space-between' }}
      >
        <Input
          className="kemi_form_input_grey fixed_width_input"
          placeholder="E-posta"
          type="email"
        />
      </Form.Item>

      <Form.Item style={{ marginBottom: '0px' }}>
        <Button className="kemi_form_btn_dark" htmlType="submit">
          Güncelle
        </Button>
      </Form.Item>
    </Form>
  );
};
