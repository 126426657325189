import React from 'react';
import { Alert, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './Spinner.scss';

function Spinner({ loadingText }) {
  const antIcon = <LoadingOutlined className="Spinner_spin_icon" spin />;

  function decideSpinnerType() {
    if (!loadingText) {
      return (
        <Row justify="center">
          <Spin indicator={antIcon} />
        </Row>
      );
    }
    return (
      <div className="Spinner_alert">
        <Alert
          message={loadingText}
          description={
            <Row justify="center">
              <Spin indicator={antIcon} />
            </Row>
          }
        />
      </div>
    );
  }
  return <div className="Spinner_container">{decideSpinnerType()}</div>;
}

export default Spinner;
