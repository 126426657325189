import TrackerQueryExcelUpload from '@src/components/shared/TrackerQueryComponents/TrackerQueryExcelUpload';
import TrackerQueryLogin from '@src/components/shared/TrackerQueryComponents/TrackerQueryLogin';
import TrackerQueryResultTable from '@src/components/shared/TrackerQueryComponents/TrackerQueryResultTable';
import { Card, Row } from 'antd';

function AdminTrackerQueries() {
    return (
        <div className="kemi_pages_cards">
            <Card className="PharmacyNotifications__card">
                <h3 className="kemi_form_highlighted_header">Sorgu Oluştur</h3>
                <Row justify="start">
                    <TrackerQueryExcelUpload />
                    <TrackerQueryLogin />
                </Row>

                <h3 className="kemi_form_highlighted_header">Sorgu Listesi</h3>
                <TrackerQueryResultTable />
            </Card>
        </div>
    );
}

export default AdminTrackerQueries;
