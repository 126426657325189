import { Card, Col, Row } from 'antd';
import './InfoMessageCard.scss';

function InfoMessageCard({ icon, title, description, name }) {
  return (
    <div>
      <Card className="InfoMessageCard">
        <Row justify="space-between">
          <Col span={18}>
            <h2>
              {title} {name}
            </h2>
            <h3>{description}</h3>
          </Col>
          <Col span={4} xs={0} sm={4}>
            {icon && (
              <img
                className="InfoMessageCard__img"
                src={icon}
                alt="kemi_welcome_img"
              />
            )}
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default InfoMessageCard;
