import ApexChart from '@src/components/shared/ApexChart';
import { Card, Row } from 'antd';
import React from 'react';

const AdminGraphCards = () => {
  return (
    <Row justify="space-around">
      <div className="adminGraphCard">
        <Card>
          <ApexChart series={options.series} options={options} />
        </Card>
      </div>

      <div className="adminGraphCard">
        <Card>
          <ApexChart series={options2.series} options={options2} />
        </Card>
      </div>
    </Row>
  );
};

var options = {
  series: [
    {
      name: 'Kutu',
      type: 'column',
      data: [
        44440, 55505, 44414, 66671, 22227, 44413, 22201, 33352, 77752, 33320,
        22257, 11160,
      ],
    },
    {
      name: 'Gelir',
      type: 'line',
      data: [233, 422, 355, 277, 433, 222, 177, 311, 222, 222, 122, 166],
    },
  ],
  stroke: {
    width: [0, 3],
  },
  title: {
    text: 'Firma Filtresi',
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: [1],
  },
  labels: [
    'Ocak',
    'Şubat',
    'Mart',
    'Nisan',
    'Mayıs',
    'Haziran',
    'Temmuz',
    'Ağustos',
    'Eylül',
    'Ekim',
    'Kasım',
    'Aralık',
  ],
  xaxis: {
    type: 'bar',
  },
  yaxis: [
    {
      title: {
        text: 'Gelir',
      },
    },
    {
      opposite: true,
      title: {
        text: 'Kutu Sayısı',
      },
    },
  ],
};

var options2 = {
  series: [
    {
      name: 'Kullanıcı',
      type: 'line',
      data: [440, 1555, 2227, 1444, 2220, 840, 1555, 2227, 1144, 2866],
    },
  ],
  stroke: {
    curve: 'straight',
  },
  title: {
    text: 'Günlük Kullanıcı',
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: [1],
  },
  labels: [
    '1/12/21',
    '2/12/21',
    '3/12/21',
    '4/12/21',
    '5/12/21',
    '6/12/21',
    '7/12/21',
    '8/12/21',
    '9/12/21',
    '10/12/21',
  ],
  yaxis: [
    {
      title: {
        text: '',
      },
    },
    {
      opposite: true,
    },
  ],
};

export default AdminGraphCards;
