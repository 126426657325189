import { Form, Select, Spin } from 'antd';
const { Option } = Select;

function FilterFormPharmacy({ pharmacies }) {
  return (
    <Form.Item name="pharmacyGlns" className="stockloss_active_filter_input">
      <Select
        mode="multiple"
        allowClear
        showSearch
        placeholder="Eczane GLN - İsim"
        maxTagCount="responsive"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.gln.toString().toLowerCase().indexOf(input.toLowerCase()) >=
            0 ||
          option.name.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        className="kemi_form_select"
        popupClassName="kemi_form_select_wide_dropdown"
      >
        {pharmacies.length > 0 ? (
          pharmacies.map((pharmacy) => {
            return (
              <Option
                key={pharmacy.gln}
                value={pharmacy.gln}
                gln={pharmacy.gln}
                name={pharmacy.name}
              >
                {pharmacy.gln} - {pharmacy.name}
              </Option>
            );
          })
        ) : (
          <Option disabled>
            <Spin />
          </Option>
        )}
      </Select>
    </Form.Item>
  );
}

export default FilterFormPharmacy;
