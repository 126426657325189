import '@assets/sharedStyles/modal.scss';
import { useGet, usePost } from '@src/api/methods';
import { EMAIL_MESSAGE, EMAIL_REQUIRED } from '@src/utils/formRules/formRules';
import { Button, Form, Input, Modal, Select, message } from 'antd';
import { useMemo } from 'react';
import { GrFormClose } from 'react-icons/gr';

function NewUserModal({
  isModalVisible,
  handleModalVisible,
  refecthCompanyUsers
}) {
  const [newCompanyUserForm] = Form.useForm();

  const { data: companies, loading: loadingCompanies } = useGet('companies');
  const { loading: loadingCreateWorkerResponse, mutate: createWorker } =
    usePost('admin/create-worker');

  const companiesOptions = useMemo(() => {
    return (
      companies?.map((company) => ({
        value: company.companyId,
        label: company.name
      })) || []
    );
  }, [companies]);

  const handleNewUserFormOnFinish = (values) => {
    createWorker(values, {
      onSuccess: () => {
        message.success('Yeni kullanıcı oluşturuldu!', 5);
        refecthCompanyUsers({
          userType: 'WORKER'
        });
        handleModalVisible();
        newCompanyUserForm.resetFields();
      },
      onError: () => {
        message.error('Yeni kullanıcı oluşturma başarısız!', 5);
      }
    });
  };

  const CloseIcon = () => (
    <div className="close-icon-container">
      <GrFormClose size={25} color="#fffafe" />
    </div>
  );

  return (
    <Modal
      className="modal"
      centered
      open={isModalVisible}
      closeIcon={<CloseIcon />}
      footer={null}
      title={'Yeni Firma Kullanıcısı Oluştur'}
      onCancel={() => handleModalVisible()}
    >
      <div className="modal-context-container">
        <Form
          className="modal-context-form"
          form={newCompanyUserForm}
          onFinish={handleNewUserFormOnFinish}
        >
          <Form.Item
            name="name"
            rules={[{ required: true, message: 'Lütfen isminizi girin!' }]}
          >
            <Input placeholder={'İsim'} />
          </Form.Item>
          <Form.Item
            name="surname"
            rules={[{ required: true, message: 'Lütfen soyisminizi girin!' }]}
          >
            <Input placeholder={'Soyisim'} />
          </Form.Item>
          <Form.Item
            name="companyId"
            rules={[{ required: true, message: 'Lütfen firma seçiniz!' }]}
          >
            <Select
              options={companiesOptions}
              loading={loadingCompanies}
              className="kemi_form_select"
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                ...EMAIL_REQUIRED,
                message: EMAIL_MESSAGE
              }
            ]}
          >
            <Input placeholder="example@domain.com" />
          </Form.Item>
        </Form>
        <div className="modal-context-footer">
          <Button
            className="modal-button"
            onClick={() => newCompanyUserForm.submit()}
            loading={loadingCreateWorkerResponse}
          >
            Oluştur
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default NewUserModal;
