import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { fetchUserDetails } from '../thunks/userDetails';

const authReducer = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    isAuthenticated: false,
    user: null,
    role: null
  },
  reducers: {
    AUTH_SUCCESS: (state, action) => {
      const tokenObj = jwtDecode(action.payload);
      state.token = action.payload;
      state.isAuthenticated = true;
      state.role = tokenObj.role;
    },
    LOGGED: (state, action) => {
      const tokenObj = jwtDecode(action.payload);
      state.role = tokenObj.role;
      state.token = action.payload;
      state.isAuthenticated = true;
    },
    LOGOUT: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.role = null;
      state.user = null;
    },
    UNLOGGED: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.role = null;
      state.user = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        throw action.error.message;
      });
  }
});

export const { AUTH_SUCCESS, LOGGED, LOGOUT, UNLOGGED } = authReducer.actions;

export default authReducer.reducer;
