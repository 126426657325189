import { Form, Select, Spin } from 'antd';
const { Option } = Select;

function FilterFormModuleId({ modules }) {
  return (
    <Form.Item name="moduleIds" className="stockloss_active_filter_input">
      <Select
        mode="multiple"
        allowClear
        showSearch
        maxTagCount="responsive"
        placeholder="Modül Adı"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.name.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        className="kemi_form_select"
        popupClassName="kemi_form_select_wide_dropdown"
      >
        {modules.length > 0 ? (
          modules.map((module) => {
            return (
              <Option
                className="kemi_form_option"
                key={module.moduleId}
                value={module.moduleId}
                name={module.name}
              >
                {module.name}
              </Option>
            );
          })
        ) : (
          <Option disabled>
            <Spin />
          </Option>
        )}
      </Select>
    </Form.Item>
  );
}

export default FilterFormModuleId;
