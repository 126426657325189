import kemi_welcome from '@src/assets/img/kemi_welcome.png';
import InfoMessageCard from '@src/components/shared/InfoMessageCard/InfoMessageCard';
import { useSelector } from 'react-redux';

function InfoCard({ stats }) {
  const { user } = useSelector((state) => state.auth);
  function checkValue(val) {
    if (val == null) {
      return 0;
    }
    return val;
  }

  function decideInfoCardSentence(statObj) {
    if (statObj.kkiPendingRequests > 0 || statObj.stockPendingRequests > 0) {
      const text = `Onayınız için bekleyen ${
        statObj.pendingKkiPayment
      } TL tutarında ${checkValue(
        statObj.kkiPendingRequests
      )} Kamu Kurum Ödemesi ve
      ${checkValue(statObj.pendingStockPayment)} TL tutarında ${checkValue(
        statObj.stockPendingRequests
      )} Stok Zararı ödemesi bulunmaktadır `;
      return text;
    } else {
      return `Mevcut bekleyen ödeme bulunmamaktadır.`;
    }
  }

  return (
    <InfoMessageCard
      icon={kemi_welcome}
      title="Kemi Portal'a Hoş Geldiniz,"
      name={user?.name + ' ' + user?.surname + '!'}
      description={`${decideInfoCardSentence(stats)} İlgili modül
              sayfalarından taleplerin onay işlemlerini tamamlayabilir, eczacılara
              bildirim gönderebilirsiniz.`}
    />
  );
}

export default InfoCard;
