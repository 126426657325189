import { Form, Select, Spin } from 'antd';
const { Option } = Select;

function FilterFormWholesaler({ wholesalers }) {
  return (
    <Form.Item name="wholesalers" className="stockloss_active_filter_input">
      <Select
        mode="multiple"
        allowClear
        showSearch
        placeholder="Depo GLN - Depo İsim"
        maxTagCount="responsive"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.gln.toString().toLowerCase().indexOf(input.toLowerCase()) >=
            0 ||
          option.name.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        className="kemi_form_select"
        popupClassName="kemi_form_select_wide_dropdown"
      >
        {wholesalers.length > 0 ? (
          wholesalers.map((wholesaler) => {
            return (
              <Option
                key={wholesaler.wholesalerId}
                value={wholesaler.wholesalerId}
                gln={wholesaler.gln}
                name={wholesaler.name}
              >
                {wholesaler.gln} - {wholesaler.name}
              </Option>
            );
          })
        ) : (
          <Option disabled>
            <Spin />
          </Option>
        )}
      </Select>
    </Form.Item>
  );
}

export default FilterFormWholesaler;
