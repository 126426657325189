import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import Spinner from '../Spinner/Spinner';
import AdminNavbar from './AdminNavbar';
import CompanyNavbar from './CompanyNavbar';
import './Navbar.scss';
import PharmacyNavbar from './PharmacyNavbar';

const { Sider } = Layout;

const Navbar = () => {
  const { role } = useSelector((state) => state.auth);

  if (role !== undefined) {
    if (role === 'Worker') {
      return <CompanyNavbar />;
    } else if (role === 'Pharmacist') {
      return <PharmacyNavbar />;
    } else if (role === 'Admin') {
      return <AdminNavbar />;
    }
  } else {
    return <LoadingSider />;
  }
};

const LoadingSider = () => {
  return (
    <Sider className="navbar" breakpoint="lg" theme="light" collapsedWidth="0">
      <Spinner />
    </Sider>
  );
};

export default Navbar;
