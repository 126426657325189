import { Form, Select, Spin } from 'antd';
import { statuses } from '@utils/staticJsonObjects/status';
import React from 'react';
const { Option } = Select;

function FilterFormStatus() {
  return (
    <Form.Item name="statuses" className="stockloss_active_filter_input">
      <Select
        mode="multiple"
        allowClear
        showSearch
        placeholder="Durum"
        maxTagCount="responsive"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.name.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        className="kemi_form_select"
      >
        {statuses.length > 0 ? (
          statuses.map((status) => {
            return (
              <Option key={status.id} value={status.status} name={status.text}>
                {status.text}
              </Option>
            );
          })
        ) : (
          <Option disabled>
            <Spin />
          </Option>
        )}
      </Select>
    </Form.Item>
  );
}

export default FilterFormStatus;
