/**
 * Barcode Validation Functions
 * Used in TextReader and -
 *  */

export const barcodeHelpers = {
  /**
   *
   * */
  // TODO CHANGE
  deleteEmptyLines: async function (lines) {
    let barcodes = [];
    await lines.map((line) => {
      if (line.trim()) {
        barcodes.push(line);
      }
      return line;
    });

    return barcodes;
  },

  checkBarcodes: async function (barcodes, module_gtins) {
    const acceptedSet = new Set();
    const rejectedSet = new Set();

    await barcodes.map((barcode) => {
      let trimedBarcode = this.deleteEmptySpaces(barcode);
      trimedBarcode = this.removeMinusFromBarcode(trimedBarcode);
      if (this.validateBarcode(trimedBarcode, module_gtins)) {
        return acceptedSet.add(trimedBarcode);
      } else {
        return rejectedSet.add(trimedBarcode);
      }
    });

    return [acceptedSet, rejectedSet];
  },
  removeMinusFromBarcode(barcode) {
    return barcode.replace('-', '');
  },

  validateBarcode: function (barcode, module_gtins) {
    if (
      this.isCodeLongEnough(barcode) &&
      this.isCodeShortEnough(barcode) &&
      !this.checkSpecialChars(barcode)
    ) {
      const gtin = this.extractPharmacyGtin(barcode);
      return module_gtins.includes(gtin);
    } else {
      return false;
    }
  },

  isCodeLongEnough: function (barcode) {
    return barcode.length >= 30;
  },

  isCodeShortEnough: function (barcode) {
    return barcode.length <= 76;
  },

  deleteEmptySpaces: function (barcode) {
    return barcode.replaceAll(/\s/g, '');
  },

  extractPharmacyGtin: function (barcode) {
    return barcode.trim().slice(2, 16);
  },

  checkSpecialChars: function (barcode) {
    // eslint-disable-next-line no-useless-escape
    const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~ĞğÜüŞşÇçİÖö]/;
    return format.test(barcode);
  },
};
