import SelectCompanies from '@Components/pharmacy_components/pharmacy_requests/SelectCompanies/SelectCompanies';

import '@assets/sharedStyles/formItems.scss';
import { Steps } from 'antd';
import { useState } from 'react';
import ItsFormPage from './ItsFormPage';
import './PharmacyNewRequest.scss';
import ResultPage from './ResultPage';
import SelectModulePage from './SelectModulePage';
import UploadExcelPage from './UploadExcelPage';

const PharmacyNewRequest = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [requestData, setRequestData] = useState({}); // todo add attr as null but just to be cleanbarcodes: []
  const [itsResult, setITSResult] = useState(undefined);

  const steps = [
    {
      title: 'Firma Seçimi',
      content: (
        <SelectCompanies
          setRequestData={setRequestData}
          requestData={requestData}
          setCurrentStep={setCurrentStep}
        />
      )
    },
    {
      title: 'İşlem Seçimi',
      content: (
        <SelectModulePage
          currentStep={currentStep}
          requestData={requestData}
          setCurrentStep={setCurrentStep}
          setRequestData={setRequestData}
        />
      )
    },
    {
      title: 'Karekod Yükleme',
      content: (
        <UploadExcelPage
          setRequestData={setRequestData}
          setCurrentStep={setCurrentStep}
          requestData={requestData}
          currentStep={currentStep}
        />
      )
    },
    {
      title: 'Doğrulama',
      content: (
        <ItsFormPage
          setRequestData={setRequestData}
          setCurrentStep={setCurrentStep}
          requestData={requestData}
          currentStep={currentStep}
          setITSResult={setITSResult}
        />
      )
    },
    {
      title: 'Sonuç',
      content: <ResultPage requestData={requestData} itsResult={itsResult} />
    }
  ];

  return (
    <div className="pharmacyNewRequest">
      <div className="steps-container">
        <div className="content-container">{steps[currentStep].content}</div>
        <Steps
          direction="horizontal"
          size="medium"
          current={currentStep}
          items={steps}
          rootClassName="steps"
        />
      </div>
    </div>
  );
};

export default PharmacyNewRequest;
