import ModulesList from '@Components/company_components/stockloss_kki/ModuleList/ModulesList';
import NewRecord from '@Components/company_components/stockloss_kki/NewRecord/NewRecord';
import '@assets/sharedStyles/pageItems.scss';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import { axios_get } from '@utils/rest_axios';
import { Tabs } from 'antd';
import { useEffect, useState } from 'react';

const { TabPane } = Tabs;

const Stockloss = () => {
  const type = 'STOCK';
  const [modules, setModules] = useState([]);
  const [reload, setReload] = useState(false);

  const { getNotification } = useNotificationPopup();

  useEffect(() => {
    async function fetchModules() {
      try {
        const result = await axios_get(`/companypages/modules`);
        const stockModules = result.data.filter((module) => {
          return module.type === type;
        });
        setModules(stockModules);
      } catch ({ response }) {
        if (response && response.status !== 401) {
          getNotification({
            ...getErrorMessage(response)
          });
        }
      }
    }
    fetchModules();
    return () => {
      setReload(!reload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <div className="kemi_pages_tabs">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Yeni Kayıt" key="1">
          <NewRecord setReload={setReload} reload={reload} type={type} />
        </TabPane>
        <TabPane tab="Mevcut Kayıtlar" key="2">
          <ModulesList
            modules={modules}
            setReload={setReload}
            reload={reload}
            type={type}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Stockloss;
