// For option mapping in request filter pages
export const statuses = [
  { id: 1, status: 'ACCEPTED', text: 'Ödendi' },
  { id: 2, status: 'REJECTED', text: 'Reddedildi' },
  { id: 3, status: 'PENDING', text: 'Doğrulandı' },
  // { id: 4, status: 'PROBLEM', text: 'Kontrol Gerekli' }
  { id: 4, status: 'CANCELED', text: 'İptal Edildi' },
  { id: 5, status: 'WAITING', text: 'Tamamlanmadı' }
];
// for listing requests and detail card
export const statusMap = new Map([
  ['PENDING', 'Doğrulandı'],
  ['ACCEPTED', 'Ödendi'],
  ['REJECTED', 'Reddedildi'],
  ['PROBLEM', 'Kontrol Gerekli'],
  ['CANCELED', 'İptal Edildi'],
  ['WAITING', 'Tamamlanmadı']
]);

export const statusOptions = [
  { value: 'PENDING', label: 'Doğrulandı' },
  { value: 'ACCEPTED', label: 'Ödendi' },
  { value: 'REJECTED', label: 'Reddedildi' },
  { value: 'CANCELED', label: 'İptal Edildi' },
  { value: 'WAITING', label: 'Tamamlanmadı' }
  // { value: "PROBLEM", label: "Kontrol Gerekli" },
];
