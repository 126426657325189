import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useCheckboxPopup } from '@src/components/shared/CheckboxPopup/CheckboxPopup';
import { barcodeHelpers } from '@src/components/shared/ReadTextFromTxt/helper';
import { Button, Card, Form, Input, Modal, Space, message } from 'antd';
import { useState } from 'react';
import SelectWholeSaler from '../SelectWholeSaler/SelectWholeSaler';
import ModalContent from './ModalContent';
import './PharmacyRequestAddProduct.scss';

function isStocklossWarehouseId(obj) {
  return obj.type === 'STOCK' && !obj.wholesalerId ? false : true;
}

function barcodesArrayWithoutAttr(barcodes) {
  return barcodes.map((obj) => `${obj.barcode}`);
}

const PharmacyRequestAddProduct = ({
  setRequestData,
  requestData,
  setCurrentStep
}) => {
  const [accepted, setAccepted] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { getCheckboxPopup } = useCheckboxPopup();

  async function SubmitForm(values) {
    if (isStocklossWarehouseId(requestData)) {
      if (requestData.barcodes) {
        // txt reader
        if (values.barcodes !== undefined && values.barcodes.length > 0) {
          message.error(
            'Aynı anda hem dosya yüklemesi hem de tekli karekod girişi yapılamaz. Lütfen tek bir yöntemle ilerleyiniz.',
            5
          );
        } else {
          if (requestData.barcodes.length > 0) {
            message.info(
              'İstek Bilgileri Başarılı. İTS doğrulaması sonrası başvurunuz iletilecektir.',
              5
            );
            setCurrentStep(3);
          } else {
            message.error(
              'Kabul Edilen Karekod Bulunmamaktadır. Txt dosyası olarak ya da karekod formu üzerinden karekodlarınızı giriniz!',
              5
            );
          }
        }
      } else {
        // barcode form
        if (values.barcodes) {
          if (values.barcodes.length > 0 && values.barcodes.length <= 20) {
            const barcodesArr = barcodesArrayWithoutAttr(values.barcodes);

            const checkedBarcodesSets = await barcodeHelpers.checkBarcodes(
              barcodesArr,
              requestData.moduleGtins
            );
            setAccepted([...checkedBarcodesSets[0]]);
            setRejected([...checkedBarcodesSets[1]]);
            // show output
            showModal();
          } else {
            message.error(
              'En az 1 karekod girilmelidir.Karekodlar txt dosyası olarak ya da karekod formu üzerinden girilmeli. Sadece bir türde yükleme yapınız.',
              5
            );
          }
        } else {
          message.error(
            'En az 1 karekod girilmelidir. Karekodlar txt dosyası olarak ya da karekod formu üzerinden girilmeli. Sadece bir türde yükleme yapınız.',
            5
          );
        }
      }
    } else {
      message.error('Lütfen Depo Seçip Tekrar Deneyiniz.', 5);
    }
  }

  // modal functions

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setIsModalVisible(false);

    if (requestData.type !== 'STOCK' && accepted.length > 0) {
      const confirmed = await getCheckboxPopup({
        title: "Yüklediğim ürünlerin İTS'ye satış bildirimini tamamladım.",
        confirmText: 'Onaylıyorum'
      });
      if (confirmed) {
        message.info(
          'İstek Bilgileri Başarılı. İTS doğrulaması sonrası başvurunuz iletilecektir.',
          5
        );
        setCurrentStep(3);
        const data = {
          acceptedBarcodes: accepted,
          rejectedBarcodes: rejected
        };
        setRequestData({
          ...requestData,
          barcodes: data.acceptedBarcodes
        });
      }
    } else if (accepted.length > 0) {
      message.info(
        'İstek Bilgileri Başarılı. İTS doğrulaması sonrası başvurunuz iletilecektir.',
        5
      );
      setCurrentStep(3);
      const data = {
        acceptedBarcodes: accepted,
        rejectedBarcodes: rejected
      };
      setRequestData({
        ...requestData,
        barcodes: data.acceptedBarcodes
      });
    } else {
      message.info(
        'Kabul Edilen Karekod Bulunmamaktadır. Txt dosyası olarak ya da karekod formu üzerinden karekodlarınızı giriniz!',
        5
      );
    }
  };

  return (
    <Card>
      <h4 className="pharmacyRequestAddProduct_margin">
        Buradan Karekodları Tek Tek Girebilirsiniz.
      </h4>
      <Form onFinish={SubmitForm}>
        <Form.List name="barcodes">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  className="pharmacyRequestAddProduct__space"
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'barcode']}
                    rules={[
                      {
                        required: true,
                        message: 'İlaç karekodu giriniz.'
                      }
                    ]}
                    label="İlaç Karekodu"
                  >
                    <Input
                      className="pharmacyRequestAddProduct_input"
                      type="string"
                    />
                  </Form.Item>

                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  className="pharmacyRequestAddProduct_margin pharmacyRequestAddProduct_add_row_btn"
                  onClick={() => {
                    fields.length < 20
                      ? add()
                      : message.error(
                          'Tek tek karekod yükleme limiti 20 karekodla sınırlıdır. Daha fazla karekod için dosya yükleyiniz.',
                          5
                        );
                  }}
                  icon={<PlusOutlined />}
                >
                  Karekod Ekle
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <div>
          {requestData.type === 'STOCK' && (
            <div>
              <h3 className="pharmacyNewRequest__desc">
                Aşağıdaki firma tarafından oluşturulmuş depo şubesi listesinden,
                ödemenizin yapılmasını istediğiniz şubeyi seçiniz.
              </h3>
              <SelectWholeSaler
                setRequestData={setRequestData}
                requestData={requestData}
                setCurrentStep={setCurrentStep}
              />
            </div>
          )}
        </div>
        <Form.Item>
          <Button
            className="kemi_form_btn_purple pharmacyRequestAddProduct__ConfirmButton"
            htmlType="submit"
          >
            Başvuru Oluştur
          </Button>
        </Form.Item>
      </Form>

      <Modal
        title="Yükleme Sonuç Raporu"
        visible={isModalVisible}
        onOk={handleOk}
        maskClosable={false}
        closable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <ModalContent accepted={accepted} rejected={rejected} />
      </Modal>
    </Card>
  );
};

export default PharmacyRequestAddProduct;
