import { usePost } from '@src/api/methods';
import { getColumnSearchProps } from '@src/utils/getColumnSearchProps';
import { Button, DatePicker, Table } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import './UsersActivityTab.scss';

const { RangePicker } = DatePicker;

function UsersActivityTab() {
  const defaultEndDate = moment();
  const defaultStartDate = moment().subtract(7, 'days');
  const [dateRange, setDateRange] = useState([
    defaultStartDate.valueOf(),
    defaultEndDate.valueOf()
  ]);

  const { data, loading, mutate } = usePost('admin/user-actions');

  useEffect(() => {
    mutate({
      userType: 'WORKER',
      beginDate: dateRange[0],
      endDate: dateRange[1]
    });
  }, [mutate, dateRange]);

  const handleDateChange = (dates) => {
    setDateRange(dates ? [dates[0].valueOf(), dates[1].valueOf()] : []);
  };

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  // for now it creates all columns which in existing in data, not just table stuff
  const handleXLSXDownloadButton = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
    XLSX.writeFile(
      workbook,
      `${new Date().toLocaleDateString('tr')} CompanyUsersActivity.xlsx`
    );
  };

  const columns = [
    {
      title: 'İşlem',
      dataIndex: 'actionType',
      key: 'actionType',
      ...getColumnSearchProps(
        'actionType',
        handleSearch,
        handleReset,
        searchInput,
        searchText,
        searchedColumn
      )
    },
    {
      title: 'E-Posta',
      dataIndex: 'userEmail',
      key: 'email',
      ...getColumnSearchProps(
        'userEmail',
        handleSearch,
        handleReset,
        searchInput,
        searchText,
        searchedColumn
      )
    },
    {
      title: 'Tarih',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => moment(createdAt).format('DD.MM.YYYY HH:mm'),
      filters: [
        { text: 'Bugün', value: 'today' },
        { text: 'Bu Hafta', value: 'thisWeek' },
        { text: 'Bu Ay', value: 'thisMonth' }
      ],
      onFilter: (value, record) => {
        const date = moment(record.createdAt);
        if (value === 'today') {
          return date.isSame(moment(), 'day');
        } else if (value === 'thisWeek') {
          return date.isSame(moment(), 'week');
        } else if (value === 'thisMonth') {
          return date.isSame(moment(), 'month');
        }
        return true;
      }
    }
  ];

  return (
    <div className="company-users-activity-tab__">
      <div className="form-container">
        <div>
          <RangePicker
            format="DD.MM.YYYY"
            className="kemi_form_select"
            style={{ width: 275 }}
            placeholder={['Başlangıç Tarihi', 'Bitiş Tarihi']}
            onChange={handleDateChange}
            defaultValue={[defaultStartDate, defaultEndDate]}
          />
        </div>
        <div>
          <Button
            style={{ width: '7rem' }}
            className="kemi_form_btn_green"
            onClick={handleXLSXDownloadButton}
          >
            .xlsx İndir
          </Button>
        </div>
      </div>

      <Table
        scroll={{ y: 480 }}
        columns={columns}
        dataSource={data}
        loading={loading}
      />
    </div>
  );
}

export default UsersActivityTab;
