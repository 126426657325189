import { usePost } from '@src/api/methods';
import CloseIcon from '@src/components/shared/CloseIcon/CloseIcon';
import { env } from '@src/env';
import { Button, Input, Modal } from 'antd';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './PromotionCodeQuerySideBox.scss';
const PromotionCodeQuerySideBox = () => {
  const [isQueryBoxOpen, setIsQueryBoxOpen] = useState(false);
  const [recaptchaCompleted, setRecaptchaCompleted] = useState(false);
  const [recaptchaKey, setRecaptchaKey] = useState(0);

  const [papCode, setPapCode] = useState('');
  const [resultPapCodeQuery, setResultPapCodeQuery] = useState('');

  const { isPending: papCodeCheckMutateisPending, mutate: papCodeCheckMutate } =
    usePost('pap-code-check');
  const { isPending: recaptchaVerifyisPending, mutate: recaptchaVerifyMutate } =
    usePost('verify-recaptcha');

  const showQueryBox = () => {
    setIsQueryBoxOpen(true);
  };

  const onClose = () => {
    setIsQueryBoxOpen(false);
    setRecaptchaCompleted(false);
    setPapCode('');
    setResultPapCodeQuery('');
    setRecaptchaKey((prevKey) => prevKey + 1);
  };

  const handleRecaptchaChange = async (token) => {
    if (token) {
      recaptchaVerifyMutate(
        { recaptchaToken: token },
        {
          onSuccess: () => {
            setRecaptchaCompleted(true);
          },
          onError: (error) => {
            setRecaptchaCompleted(false);
          }
        }
      );
    } else {
      setRecaptchaCompleted(false);
    }
  };

  const handleCheckButtonOnClick = () => {
    if (recaptchaCompleted) {
      papCodeCheckMutate(
        {
          papCode
        },
        {
          onSuccess: () => {
            setResultPapCodeQuery(
              'Kod geçerlidir, bu kodla başvuru yapılması halinde indirim yansıtılacaktır.'
            );
          },
          onError: (error) => {
            console.log(error);
            if (error.response.data.name === 'NoPapCodeFoundError') {
              setResultPapCodeQuery('Böyle bir kod bulunmamaktadır!');
            } else if (error.response.data.message === 'PapCodeAlreadyUsed') {
              setResultPapCodeQuery('Bu kod daha önce kullanılmıştır!');
            } else if (error.response.data.message === 'PapCodeExpired') {
              setResultPapCodeQuery('Bu kodun kullanım süresi bitmiştir!');
            } else if (error.response.statusText === 'Too Many Requests') {
              setResultPapCodeQuery(
                'Bir dakika içinde en fazla 5 kez sorgulama yapabilirsiniz.'
              );
            }
          }
        }
      );
    }
  };

  return (
    <>
      {isQueryBoxOpen ? null : (
        <Button className="promotion-code-query-button" onClick={showQueryBox}>
          İndirim kodu sorgulamak için tıklayınız
        </Button>
      )}
      <Modal
        open={isQueryBoxOpen}
        onCancel={onClose}
        closeIcon={<CloseIcon className="h-7 w-7 cursor-pointer" />}
        footer={null}
        mask={false}
        wrapClassName=""
        className="promotion-code-query-box-container"
        width={'450px'}
      >
        <div className="promotion-code-query-box">
          {resultPapCodeQuery ? (
            <div className="promotion-code-query-box__result">
              <h2>{resultPapCodeQuery}</h2>
            </div>
          ) : (
            <>
              <div className="promotion-code-query-box__title">
                <h2>İndirim kodunu aşağıdaki kutucuğa giriniz.</h2>
                <h2>
                  Bu aşamada kod kullanılmayacaktır, sadece sizin için
                  geçerliliği kontrol edilecektir.
                </h2>
              </div>

              <div className="promotion-code-query-box__form">
                <div className="promotion-code-query-box__input kemi_form_input_grey">
                  <Input
                    placeholder="Kodunuzu giriniz"
                    onChange={(event) => setPapCode(event.currentTarget.value)}
                  />
                </div>
                <ReCAPTCHA
                  key={recaptchaKey}
                  sitekey={env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={handleRecaptchaChange}
                  hidden={recaptchaCompleted}
                  onExpired={() => setRecaptchaCompleted(false)}
                />
                <div className="promotion-code-query-box__button">
                  <Button
                    type="primary"
                    loading={papCodeCheckMutateisPending}
                    onClick={handleCheckButtonOnClick}
                    disabled={!recaptchaCompleted}
                  >
                    Kontrol Et
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default PromotionCodeQuerySideBox;
