import '@assets/sharedStyles/formItems.scss';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import {
  EMAIL_MESSAGE,
  EMAIL_REQUIRED,
  PASSWORD_MESSAGE,
  REQUIRED_INPUT_MESSAGE
} from '@src/utils/formRules/formRules';
import { axios_post } from '@src/utils/rest_axios';
import { Button, Card, Form, Input, message } from 'antd';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
  tail: { span: 24 }
};

function NewAdmin() {
  const [newAdminForm] = Form.useForm();
  const { getNotification } = useNotificationPopup();

  const SubmitForm = async (values) => {
    try {
      const createAdminRes = await axios_post('/admin/create-admin', values);
      if (createAdminRes.status === 200) {
        newAdminForm.resetFields();
        message.success('Admin Oluşturuldu', 5);
      }
    } catch ({ response }) {
      if (response && response.status !== 401) {
        getNotification({
          ...getErrorMessage(response)
        });
      }
    }
  };

  return (
    <Card className="newCompany">
      <Form
        form={newAdminForm}
        name="newAdminForm"
        onFinish={SubmitForm}
        autoComplete="off"
        colon={true}
        layout={'horizontal'}
        className="Form"
        wrapperCol={{
          xs: { span: 12 },
          sm: { span: 24 },
          md: { span: 12 },
          lg: { span: 8 }
        }}
      >
        <h3 className="kemi_form_highlighted_header">Yönetici Bilgileri</h3>
        <Form.Item
          name="name"
          label="İsim"
          rules={[
            {
              required: true,
              message: 'Admin Kullanıcı ismi giriniz.'
            }
          ]}
        >
          <Input
            className="kemi_form_input_grey Form_input"
            placeholder="Admin Kullanıcı İsmi"
          />
        </Form.Item>

        <Form.Item
          name="surname"
          label="Soyisim"
          rules={[
            {
              required: true,
              message: 'Admin Kullanıcı soyismi giriniz.'
            }
          ]}
        >
          <Input
            className="kemi_form_input_grey Form_input"
            placeholder="Admin Kullanıcı Soyismi"
          />
        </Form.Item>

        <Form.Item
          name="email"
          label="E-posta"
          rules={[
            {
              required: true,
              message: 'Admin Kullanıcı e-postası giriniz.'
            },
            {
              ...EMAIL_REQUIRED,
              message: EMAIL_MESSAGE
            }
          ]}
          hasFeedback
        >
          <Input
            className="kemi_form_input_grey Form_input"
            placeholder="E-posta"
            type="email"
          />
        </Form.Item>

        <Form.Item
          name="password"
          label="Şifre"
          rules={[
            {
              required: true,
              message: REQUIRED_INPUT_MESSAGE
            },
            {
              min: 6,
              message: PASSWORD_MESSAGE
            }
          ]}
          hasFeedback
        >
          <Input
            className="kemi_form_input_grey Form_input"
            name="password"
            type="password"
            placeholder="Şifre"
            required
          />
        </Form.Item>

        <Form.Item {...layout.tail}>
          <Button className="kemi_form_btn_dark" htmlType="submit">
            Oluştur
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default NewAdmin;
