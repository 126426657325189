import { getErrorMessage } from '@src/utils/errors';
import { axios_get } from '@src/utils/rest_axios';
import { createContext, useEffect, useState } from 'react';
import { useNotificationPopup } from '../NotificationPopup/NotificationPopup';

const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const [user, setuser] = useState({});
  const { getNotification } = useNotificationPopup();

  useEffect(() => {
    async function getUser() {
      try {
        const result = await axios_get('/me');
        setuser(result.data);
      } catch ({ response }) {
        if (response && response.data.statusCode !== 403) {
          getNotification({
            ...getErrorMessage(response),
          });
        }
      }
    }

    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
export { UserContext };
