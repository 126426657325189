import { DeleteOutlined } from '@ant-design/icons';
import { axios_delete, axios_patch } from '@src/utils/rest_axios';
import { Button, Collapse, Input, Modal, Result } from 'antd';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import { useState } from 'react';
import RichTextEditor from '@src/components/shared/TextEditor/RichTextEditor';

const { Panel } = Collapse;
async function deleteNotification(
  event,
  notificationId,
  setCallback,
  callback,
  getNotification
) {
  event.stopPropagation();
  try {
    await axios_delete('/notifications/', notificationId);
    Modal.info({
      icon: '',
      content: (
        <div>
          <Result status="success" title="Duyurunuz başarıyla Silinmiştir" />{' '}
        </div>
      ),
    });
    setCallback(!callback);
  } catch ({ response }) {
    if (response && response.status !== 401) {
      getNotification({
        ...getErrorMessage(response),
      });
    }
  }
}
async function updateNotification(
  markdownText,
  title,
  notification,
  setCallback,
  callback,
  getNotification
) {
  try {
    const payload = {
      title,
      context: markdownText,
    };
    await axios_patch(`/notifications/${notification.notificationId}`, payload);
    Modal.info({
      icon: '',
      content: (
        <div>
          <Result status="success" title="Duyurunuz başarıyla Güncelleniştir" />{' '}
        </div>
      ),
    });
    setCallback(!callback);
  } catch ({ response }) {
    if (response && response.status !== 401) {
      getNotification({
        ...getErrorMessage(response),
      });
    }
  }
}
const getDeleteButton = (
  notificationId,
  setCallback,
  callback,
  getNotification
) => (
  <DeleteOutlined
    onClick={(event) =>
      deleteNotification(
        event,
        notificationId,
        setCallback,
        callback,
        getNotification
      )
    }
  />
);
export const CollapseNotificationCompany = ({
  notification,
  callback,
  setCallback,
}) => {
  const { getNotification } = useNotificationPopup();
  const [markdownText, setMarkdownText] = useState(notification.context);
  const [title, setTitle] = useState(notification.title);

  return (
    <Collapse>
      <Panel
        header={`${notification.title} - ${notification?.module.name}`}
        key={notification.notificationId}
        extra={getDeleteButton(
          notification.notificationId,
          setCallback,
          callback,
          getNotification
        )}
      >
        <div>
          <Input
            defaultValue={title}
            onChange={(e) => setTitle(e.target.value)}
            className="kemi_form_input"
          />
          <RichTextEditor
            editorText={markdownText}
            setEditorText={setMarkdownText}
          />
          <Button
            onClick={() =>
              updateNotification(
                markdownText,
                title,
                notification,
                setCallback,
                callback
              )
            }
          >
            Güncelle
          </Button>
        </div>
      </Panel>
    </Collapse>
  );
};
