import { Checkbox, Row } from 'antd';
import React from 'react';
import './CheckboxPopup.scss';

/**
 * Checkbox Confirmation Popup
 * @usage const { getCheckboxPopup } = useCheckboxPopup();
 * @param title text
 * @param message text
 * @param confirmText text
 * @param type success, default, danger, info, warning
 * @example getCheckboxPopup({
            title: `Bu işlem için kutucuğu işaretlemeniz gerekmektedir`,
            message: '',
            confirmText: 'Onaylıyorum',
            type: 'success',
          }); 
 */

const CheckboxPopup = ({
  open,
  title,
  confirmText,
  message,
  onConfirm,
  type,
}) => {
  return (
    <>
      {open && (
        <div className="checkbox__container">
          <div className={`checkbox_popup_container popup_default`}>
            <div className="confirmation_popup_headline">
              <Row justify="end">
                <div className="popup-bar-empty-space"></div>
              </Row>
            </div>

            <Row justify="center">
              <h3 className="popup__title">{title}</h3>
            </Row>

            <Row justify="center">
              <p className="popup__message">{message}</p>
            </Row>

            <Row justify="end">
              {confirmText && (
                <Checkbox className="popup_checkbox" onChange={onConfirm}>
                  {confirmText}
                </Checkbox>
              )}
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

const CheckboxPopupContext = React.createContext({});

const CheckboxPopupProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogConfig, setDialogConfig] = React.useState({});

  const openDialog = ({
    title,
    message,
    confirmText,
    actionCallback,
    type,
  }) => {
    setDialogOpen(true);
    setDialogConfig({
      title,
      message,
      confirmText,
      actionCallback,
      type,
    });
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig({});
  };

  const onConfirm = () => {
    resetDialog();
    dialogConfig.actionCallback(true);
  };

  return (
    <CheckboxPopupContext.Provider value={{ openDialog }}>
      <CheckboxPopup
        open={dialogOpen}
        title={dialogConfig?.title}
        message={dialogConfig?.message}
        cancelText={dialogConfig?.cancelText}
        confirmText={dialogConfig?.confirmText}
        type={dialogConfig?.type}
        onConfirm={onConfirm}
      />
      {children}
    </CheckboxPopupContext.Provider>
  );
};

const useCheckboxPopup = () => {
  const { openDialog } = React.useContext(CheckboxPopupContext);

  const getCheckboxPopup = ({ ...options }) =>
    new Promise((res) => {
      openDialog({ actionCallback: res, ...options });
    });

  return { getCheckboxPopup };
};

export default CheckboxPopup;
export { CheckboxPopupProvider, useCheckboxPopup };
