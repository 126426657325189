import { axios_delete } from '@src/utils/rest_axios';
import { Modal, message } from 'antd';
import WholesalerUpdateModal from './WholesalerUpdateModal';
const {
  DeleteOutlined,
  ExclamationCircleOutlined
} = require('@ant-design/icons');
const { confirm } = Modal;

export const WholesalerRow = ({ text, row, callback, setCallback }) => {
  async function handleDelete() {
    try {
      await axios_delete(`/companypages/wholesaler/`, row.wholesalerId);
      setCallback(!callback);
      message.success('Depo başarıyla silindi!', 5);
    } catch (err) {
      message.error('Depo silme başarısız!', 5);
    }
  }

  function confirmDelete() {
    confirm({
      title: 'Depo Silinecek',
      icon: <ExclamationCircleOutlined />,
      content: 'Emin misiniz?',
      onOk() {
        handleDelete();
      }
    });
  }

  return (
    <div className="manageWarehouse_table_action_btns">
      <button className="manageWarehouse_table_action_btns_btn kemi_form_icon_button_ghost">
        <WholesalerUpdateModal wholesaler={row} setCallback={setCallback} />
      </button>
      <button className="manageWarehouse_table_action_btns_btn kemi_form_icon_button_ghost">
        <DeleteOutlined onClick={confirmDelete} />
      </button>
    </div>
  );
};
