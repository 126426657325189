import '@assets/sharedStyles/formItems.scss';
import '@assets/sharedStyles/pageItems.scss';

import { Tabs } from 'antd';
import UsersActivity from './Tabs/UsersActivityTab';
import Users from './Tabs/UsersTab';

function Company() {
  const tabItems = [
    {
      key: 'company1',
      label: 'Kullanıcılar',
      children: <Users />
    },
    {
      key: 'company2',
      label: 'Firma Hareketleri',
      children: <UsersActivity />
    }
  ];

  return (
    <div className="kemi_pages_tabs">
      <Tabs defaultActiveKey="company1" items={tabItems} />
    </div>
  );
}

export default Company;
