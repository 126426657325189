import { Checkbox, Form } from 'antd';

export function CheckKVKK() {
  return (
    <Form.Item
      rules={[
        {
          required: true,
          message: 'Onay kutucuklarını işaretleyerek tekrar deneyiniz.',
        },
      ]}
      name="kvkk"
      valuePropName="checked"
    >
      <Checkbox>
        <a href="/uyelik-sozlesmesi" className="kemi_form_link" target="_blank">
          Üyelik Sözleşmesi
        </a>
        ’ni,&nbsp;
        <a href="/kvkk-metni" className="kemi_form_link" target="_blank">
          Kişisel Verilerin İşlenmesine İlişkin Aydınlatma
        </a>
        &nbsp;kapsamında &nbsp;
        <a href="/acık-riza-metni" className="kemi_form_link" target="_blank">
          Açık Rıza Metni
        </a>
        ’ni ve&nbsp;
        <a href="/cerez-politikasi" className="kemi_form_link" target="_blank">
          Çerez Politikası
        </a>
        ’nı okudum ve kabul ediyorum.
      </Checkbox>
    </Form.Item>
  );
}
export function CheckClarification() {
  return (
    <Form.Item name="clarificationCheck" valuePropName="checked">
      <Checkbox>
        <a
          href="/ticari-elektronik-ileti-metni"
          className="kemi_form_link"
          target="_blank"
        >
          Ticari Elektronik İleti İzni Aydınlatma ve Onay Metni
        </a>
        ’ni okudum, tarafıma ticari elektronik ileti gönderilmesini onaylıyorum.
      </Checkbox>
    </Form.Item>
  );
}
