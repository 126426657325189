import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import '@assets/sharedStyles/formItems.scss';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { addLocalOffsetToDateObject } from '@src/utils/date.helpers';
import { getErrorMessage } from '@src/utils/errors';
import {
  DATE_REQUIRED_MESSAGE,
  PRICE_REQUIRED_MESSAGE
} from '@src/utils/formRules/formRules';
import { axios_post } from '@utils/rest_axios';
import {
  Button,
  Card,
  DatePicker,
  Form,
  InputNumber,
  Row,
  Space,
  message
} from 'antd';
import { useState } from 'react';
import './AddModulePrice.scss';
const AddModulePrice = ({ module, callBack, reload }) => {
  const [form] = Form.useForm();
  const [popup, setPopup] = useState(false);
  const { getNotification } = useNotificationPopup();

  const SubmitForm = async (values) => {
    if (values.moduleControls.length === 0) {
      message.error(
        'En az bir fiyat girilmelidir! Fiyat bilgisi bulunamadi',
        5
      );
    }
    if (values.moduleControls.length > 0) {
      let errors = [];
      for (let moduleControl of values.moduleControls) {
        const moduleControlPriceBeginningEpoch =
          moduleControl.priceBeginning.valueOf();

        const priceBeginningDateWithOffset = addLocalOffsetToDateObject(
          moduleControlPriceBeginningEpoch
        );

        const priceBeginningEpochWithOffset =
          priceBeginningDateWithOffset.valueOf();

        const payload = {
          moduleId: module.moduleId,
          price: +moduleControl.price,
          priceBeginning: priceBeginningEpochWithOffset
        };
        try {
          await axios_post('/module-controls', payload);
        } catch ({ response }) {
          if (response && response.status !== 401) {
            errors.push(response);
            getNotification({
              ...getErrorMessage(response)
            });
          }
        }

        callBack(!reload);
      }
      if (errors.length === 0) {
        message.success('Güncelleme Başarılı! Fiyat bilgisi eklendi', 5);
      }
    }
  };

  function getForm() {
    return (
      <Form
        form={form}
        onFinish={SubmitForm}
        initialValues={{ moduleControls: [''] }}
      >
        <Form.List name="moduleControls">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  className="addModulePrice__card__form__space"
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'price']}
                    label="İlaç Fiyatı"
                    rules={[
                      {
                        required: true,
                        message: PRICE_REQUIRED_MESSAGE
                      }
                    ]}
                  >
                    <InputNumber className="kemi_form_input" type="number" />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    label="Başlangıç Tarihi:"
                    name={[name, 'priceBeginning']}
                    rules={[
                      {
                        required: true,
                        message: DATE_REQUIRED_MESSAGE
                      }
                    ]}
                  >
                    <DatePicker
                      className="kemi_form_input"
                      placeholder={['Başlangıç Tarihi:']}
                      format={'DD.MM.YYYY'}
                    />
                  </Form.Item>

                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  className="kemi_form_btn_purple"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Fiyat bilgisi ekle
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button
            className="kemi_form_btn_dark"
            type="primary"
            htmlType="submit"
          >
            Kaydet
          </Button>
        </Form.Item>
      </Form>
    );
  }
  return (
    <>
      {popup === false && (
        <Button
          className="addModulePrice__button kemi_form_btn_purple"
          onClick={() => setPopup(true)}
        >
          Fiyat Ekle
        </Button>
      )}

      {popup === true && (
        <Card className="addModulePrice__card">
          <Row justify="space-between" className="addModulePrice__card__row">
            <h3 className="addModulePrice__card__row__title">Fiyat Ekle</h3>
            <Button danger onClick={() => setPopup(false)}>
              X
            </Button>
          </Row>
          {getForm()}
        </Card>
      )}
    </>
  );
};

export default AddModulePrice;
