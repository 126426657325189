import { Layout, Menu } from 'antd';
import { useState } from 'react';
import { AiFillNotification, AiOutlineUser } from 'react-icons/ai';
import { FaCubes, FaHome, FaPercent } from 'react-icons/fa';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import kemi_logo from '../../../assets/img/kemi_light_logo.png';

const { Sider } = Layout;

function CompanyNavbar() {
  const { user } = useSelector((state) => state.auth);
  const [collapsed, setCollapsed] = useState(window.screen.width <= 1600);

  const [openKeys, setOpenKeys] = useState([]);
  const handleOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const menuItems = [
    {
      key: '/',
      icon: (
        <FaHome size={26} className="sidebar__menu-icon">
          <Link to="/" />
        </FaHome>
      ),
      label: <Link to="/">Ana Sayfa</Link>
    },
    {
      key: 'sub1',
      icon: <FaCubes size={26} className="sidebar__menu-icon" />,
      label: 'Stok Zararı',
      children: [
        {
          key: '/stokzarari-kayitlarim',
          label: <Link to="/stokzarari-kayitlarim">Kayıtlarım</Link>
        },
        {
          key: '/stokzarari-eczanebasvurulari',
          label: (
            <Link to="/stokzarari-eczanebasvurulari">Eczane Başvuruları</Link>
          )
        },
        {
          key: '/depo-yonetimi-stock',
          label: <Link to="/depo-yonetimi">Depo Yönetimi</Link>
        }
      ]
    },
    {
      key: 'alternative-kki',
      icon: <FaPercent size={23} className="sidebar__menu-icon" />,
      label: 'Alternatif KKİ',
      children: [
        {
          key: '/kki-kayitlarim',
          label: <Link to="/kki-kayitlarim">Kayıtlarım</Link>
        },
        {
          key: '/kki-eczanebasvurulari',
          label: <Link to="/kki-eczanebasvurulari">Eczane Başvuruları</Link>
        }
      ]
    },
    {
      key: 'PAP',
      icon: <AiOutlineUser size={23} className="sidebar__menu-icon" />,
      label: 'PAP',
      children: [
        {
          key: '/pap-kayitlarim',
          label: <Link to="/pap-kayitlarim">Kayıtlarım</Link>
        },
        {
          key: '/pap-eczanebasvurulari',
          label: <Link to="/pap-eczanebasvurulari">Eczane Başvuruları</Link>
        },
        {
          key: '/depo-yonetimi-pap',
          label: <Link to="/depo-yonetimi">Depo Yönetimi</Link>
        }
      ]
    },
    {
      key: '/duyurular',
      icon: <AiFillNotification size={26} className="sidebar__menu-icon" />,
      label: <Link to="/duyurular">Duyurular</Link>
    }
  ];

  return (
    <Sider
      onBreakpoint={(broken) =>
        broken ? setCollapsed(true) : setCollapsed(false)
      }
      className="sidebar__"
      theme="light"
      width="15%"
      breakpoint="xxl"
      trigger={null}
      collapsed={collapsed}
    >
      {collapsed ? null : (
        <>
          <div className="sidebar__brand">
            <a className="flex items-center justify-center" href="/">
              <img src={kemi_logo} style={{ width: '35%' }} alt="navbar-logo" />
            </a>
          </div>
          <div className="__user_information">
            <h3>{user?.name + ' ' + user?.surname}</h3>
          </div>
        </>
      )}

      <Menu
        className="sidebar__menu"
        openKeys={openKeys}
        onOpenChange={handleOpenChange}
        selectedKeys={window.location.pathname}
        items={menuItems}
        mode="inline"
      />
    </Sider>
  );
}

export default CompanyNavbar;
