import { InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';
import { Link } from 'react-router-dom';
import './TrackerQueryExcelUpload.scss';

function TrackerQueryExcelUpload() {
  function readExcel(file) {
    // TODO this for reading file, if read excel is necessary or convert to arr
    return false;
  }

  function removeExcel() {
    // TODO if state is keeping the data setState to initial
  }

  function showModal() {
    // TODO for triggering preview modal or popup after upload
  }

  function ExampleXlsDownload() {
    return (
      <Link
        className="exapmletxt--link"
        to="./files/barkod_yukleme_ornek_dosya.txt"
        target="_blank"
        download
      >
        Örnek .xls formatı için tıklayınız.
      </Link>
    );
  }

  return (
    <div className="TrackerQueryExcelUpload">
      <Dragger
        accept=".xlsx, .xls"
        action=""
        type="file"
        name="xls"
        multiple={false}
        beforeUpload={readExcel}
        onRemove={removeExcel}
        onPreview={showModal}
        maxCount={1}
        listType="picture"
        className="TrackerQueryExcelUpload--dragger "
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Yüklemek İçin Sürükle Bırak veya Tıkla Seç
        </p>
      </Dragger>
      <ExampleXlsDownload />
    </div>
  );
}

export default TrackerQueryExcelUpload;
