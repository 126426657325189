import kemi_logo from '@src/assets/img/kemi_logo.png';
import { Layout, Row } from 'antd';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useLocation } from 'react-router-dom';
import './ContractPage.scss';
import CommercialNotificationContract from './contracts/CommercialNotificationContract.md';
import ConsentContract from './contracts/ConsentContract.md';
import CookiePolicy from './contracts/CookiePolicy.md';
import KVKKContract from './contracts/KVKKContract.md';
import MembershipAgreement from './contracts/MembershipAgreement.md';

function ContractPage() {
  const location = useLocation();
  let [content, setContent] = useState({ md: '' });

  useEffect(() => {
    const contractType = decideContract();
    fetch(contractType)
      .then((res) => res.text())
      .then((md) => {
        setContent({ md });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function decideContract() {
    if (location.pathname === '/uyelik-sozlesmesi') {
      return MembershipAgreement;
    } else if (location.pathname === '/kvkk-metni') {
      return KVKKContract;
    } else if (location.pathname === '/acık-riza-metni') {
      return ConsentContract;
    } else if (location.pathname === '/cerez-politikasi') {
      return CookiePolicy;
    } else if (location.pathname === '/ticari-elektronik-ileti-metni') {
      return CommercialNotificationContract;
    }
  }
  return (
    <div>
      <Layout>
        <div className="kemi_login_tab">
          <Row justify="end">
            <a href="/">
              <img src={kemi_logo} className="kemi_logo" alt="kemi_logo" />
            </a>
          </Row>
          <div className="ContractPage_markdownCanvas">
            <div>
              <ReactMarkdown
                children={content.md}
                components={{
                  img: ({ node, ...props }) => (
                    <img
                      style={{ maxWidth: '100%' }}
                      {...props}
                      alt="markdownCanvas"
                    />
                  )
                }}
              />
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default ContractPage;
