import { Card } from 'antd';
import './StatsCard.scss';

import React from 'react';

function StatsCard({ icon, title, children, type }) {
  return (
    <div>
      <Card
        className={`graph__card ${
          type === 'small'
            ? 'graph__card_type_small'
            : 'graph__card_type_default'
        }`}
      >
        {icon && type !== 'small' && (
          <img src={icon} className="graph__card__image" alt="graph_card_img" />
        )}

        <h1 className="graph__card__title">{title ? title : ''}</h1>
        {children ? children : ''}

        {type === 'small' && (
          <img src={icon} className="graph__card__small_image" alt="stat_img" />
        )}
      </Card>
    </div>
  );
}

export default StatsCard;
