import '@assets/sharedStyles/formItems.scss';
import '@assets/sharedStyles/pageItems.scss';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import RichTextEditor from '@src/components/shared/TextEditor/RichTextEditor';
import { getErrorMessage } from '@src/utils/errors';
import { axios_get, axios_post } from '@src/utils/rest_axios';
import {
  Button,
  Form,
  Input,
  Modal,
  Result,
  Select,
  Spin,
  Tabs,
  message
} from 'antd';
import { useEffect, useState } from 'react';
import { ExistingNotifications } from './ExistingNotifications';
import './Notifications.scss';

const { TabPane } = Tabs;
const { Option } = Select;

const Notifications = () => {
  const [notificationForm] = Form.useForm();
  const [modules, setModules] = useState([]);
  const [markdownText, setMarkdownText] = useState('');
  const [callback, setCallback] = useState(false);

  const { getNotification } = useNotificationPopup();

  useEffect(() => {
    async function getKkiModules() {
      try {
        const result = await axios_get(`/companypages/modules`);
        setModules(result.data);
      } catch ({ response }) {
        if (response && response.status !== 401) {
          getNotification({
            ...getErrorMessage(response)
          });
        }
      }
    }
    getKkiModules();

    return () => {
      setCallback(!callback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback]);

  async function SubmitForm(values) {
    values.markdown_text = markdownText;
    const payload = {
      moduleId: values.moduleId,
      title: values.title,
      context: values.markdown_text
    };
    try {
      await axios_post(`/companypages/notifications`, payload);
      Modal.info({
        icon: '',
        content: (
          <div>
            <Result
              status="success"
              title="Duyurunuz başarıyla eczanelere iletilmiştir."
            />
          </div>
        )
      });
      setCallback(!callback);
    } catch (err) {
      message.error('Duyuru oluşturulamadı!', 5);
    }

    // clear fields
    notificationForm.resetFields();
    setMarkdownText('');
  }

  return (
    <div className="notifications kemi_pages_tabs">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Yeni Duyuru" key="1">
          <div className="notifications__select form_tab">
            <Form
              form={notificationForm}
              name="notification_form"
              onFinish={SubmitForm}
              autoComplete="off"
              className="Form"
            >
              <Form.Item
                name="moduleId"
                className="notification_select "
                labelCol={{ span: 24 }}
                label="İlgili İşlem"
                rules={[{ required: true, message: 'İşlem Seçiniz' }]}
              >
                <Select
                  allowClear
                  maxTagCount="responsive"
                  showSearch
                  placeholder="İşlem Adı"
                  filterOption={(input, option) =>
                    option.name
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  className="kemi_form_select"
                >
                  {modules.length > 0 ? (
                    modules.map((module) => {
                      return (
                        <Option
                          key={module.moduleId}
                          value={module.moduleId}
                          name={module.name}
                        >
                          {module.name}
                        </Option>
                      );
                    })
                  ) : (
                    <Option disabled>
                      <Spin />
                    </Option>
                  )}
                </Select>
              </Form.Item>

              <Form.Item
                name="title"
                className="notification_input "
                labelCol={{ span: 24 }}
                label="Başlık"
                rules={[{ required: true, message: 'Başlık Giriniz' }]}
              >
                <Input
                  placeholder="Duyuru Başlığı"
                  className="kemi_form_input"
                />
              </Form.Item>

              <RichTextEditor
                editorText={markdownText}
                setEditorText={setMarkdownText}
              />

              <Form.Item className="notifications_btn">
                <Button className="kemi_form_btn_dark" htmlType="submit">
                  Gönder
                </Button>
              </Form.Item>
            </Form>
          </div>
        </TabPane>
        <TabPane tab="Geçmiş Duyurular" key="2">
          <div>
            <ExistingNotifications
              callback={callback}
              setCallback={setCallback}
            />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};
export default Notifications;
