import { LOGOUT } from '@src/redux/reducers/auth';
import { Header } from 'antd/lib/layout/layout';
import { FiLogOut } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useConfirmationDialog } from '../ConfirmationPopup/ConfirmationDialog';
import { HelpMenu } from './HelpMenu/HelpMenu';
import './LayoutHeader.scss';

const LayoutHeader = () => {
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.auth);
  const { getConfirmation } = useConfirmationDialog();

  async function handleLogout() {
    const confirmed = await getConfirmation({
      title: `Çıkış yapmak istediğinizden emin misiniz?`,
      cancelText: 'Vazgeç',
      confirmText: 'Onayla'
    });
    if (confirmed) {
      dispatch(LOGOUT());
    }
  }
  return (
    <Header className="header">
      <div className="header__exit" onClick={handleLogout}>
        <FiLogOut className="header__exit__logo" />
        <span className="header__exit__text">Çıkış</span>
      </div>
      {role === 'Pharmacist' && (
        <div className="header_helpmenu">
          <HelpMenu />
        </div>
      )}
    </Header>
  );
};

export default LayoutHeader;
