export function formatTurkishCurrency(number, fractionDigit = 0) {
  return new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    maximumFractionDigits: fractionDigit,
  })
    .format(number)
    .replace('₺', '');
}

export function formatTurkishNumber(number) {
  return new Intl.NumberFormat('tr-TR', {
    maximumFractionDigits: 2,
  }).format(number);
}
