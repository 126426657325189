import { LOGOUT } from '@src/redux/reducers/auth';
import { Spin } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(LOGOUT());
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Spin />
    </div>
  );
};

export default Logout;
