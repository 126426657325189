import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import { axios_get } from '@utils/rest_axios';
import { Select } from 'antd';
import { useEffect, useState } from 'react';
import './SelectWholeSaler.scss';

const { Option } = Select;

const SelectWholeSaler = ({ setRequestData, requestData }) => {
  const [wholesalers, setWholesalers] = useState([]);

  const { getNotification } = useNotificationPopup();

  useEffect(() => {
    async function getWholesalers() {
      try {
        const wholesalersRes = await axios_get(
          `/pharmacypages/companywholesaler/${requestData.companyId}`
        );
        if (wholesalersRes.status === 200) {
          setWholesalers(wholesalersRes.data);
        }
      } catch ({ response }) {
        if (response && response.status !== 401) {
          getNotification({
            ...getErrorMessage(response)
          });
        }
      }
    }
    getWholesalers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Select
        defaultValue="Ecza Deposu Seçiniz"
        size="large"
        className="kemi_form_select_purple"
        onChange={(value) => {
          if (value !== 'Ecza Deposu Seçiniz') {
            setRequestData({
              ...requestData,
              wholesalerId: value
            });
          }
        }}
      >
        {wholesalers.length > 0 &&
          wholesalers
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((wholesaler) => {
              return (
                <Option
                  key={wholesaler.wholesalerId}
                  value={wholesaler.wholesalerId}
                >
                  {wholesaler.name}
                </Option>
              );
            })}
      </Select>
    </div>
  );
};
export default SelectWholeSaler;
