import '@assets/sharedStyles/pageItems.scss';
import NewAdmin from '@src/components/admin_components/newUser/NewAdmin/NewAdmin';
import NewCompany from '@src/components/admin_components/newUser/NewCompany/NewCompany';
import NewCompanyWorker from '@src/components/admin_components/newUser/NewCompanyWorker/NewCompanyWorker';
import { Tabs } from 'antd';
import CreateStakeholdersForm from './CreateStakeholderForm';
import GetStakeholderForm from './GetStakeholderForm';
import { PharmacyTable } from './PharmacyTable/PharmacyTable';
import UsersTable from './UsersTable/UsersTable';
const { TabPane } = Tabs;

function AdminUserDashboard() {
  return (
    <div className="admin-new-user kemi_pages_tabs">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Yeni Firma" key="1">
          <NewCompany />
        </TabPane>
        <TabPane tab="Yeni Firma Kullanıcısı" key="2">
          <NewCompanyWorker />
        </TabPane>
        <TabPane tab="Yeni Admin" key="3">
          <NewAdmin />
        </TabPane>
        <TabPane tab="Kullanıcılar" key="4">
          <UsersTable />
        </TabPane>
        <TabPane tab="Eczaneler" key="5">
          <PharmacyTable />
        </TabPane>
        <TabPane tab="Its Eczaneler" key="6">
          <GetStakeholderForm />
        </TabPane>
        <TabPane tab="Paydas Olustur" key="7">
          <CreateStakeholdersForm />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default AdminUserDashboard;
