import React, { useState } from "react";
import { Upload, message, Divider, Modal } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import "./TextReader.scss";
import { Link } from "react-router-dom";
import { barcodeHelpers } from "./helper";
import _ from "lodash";
const { Dragger } = Upload;

function ReadTextFromTxt({ requestData, setRequestData }) {
  const [accepted, setAccepted] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // File is read here (beforeUpload)
  function readText(file) {
    // clear previous states
    setAccepted([]);
    setRejected([]);

    // fileReader
    const reader = new FileReader();
    let barcodes = [];

    reader.onload = async (e) => {
      // make array line by line
      barcodes = e.target.result.split("\n");

      if (barcodes.length > 200) {
        message.error("Bir defada en fazla 200 Karekod/Satır okutulabilir.");
      } else {
        // start editing with empty lines
        const linesWithoutEmpty = await barcodeHelpers.deleteEmptyLines(
          barcodes
        );
        // validate - first index is accepted, second one is rejected
        const checkedBarcodesSets = await barcodeHelpers.checkBarcodes(
          linesWithoutEmpty,
          requestData.moduleGtins
        );
        setAccepted([...checkedBarcodesSets[0]]);
        setRejected([...checkedBarcodesSets[1]]);
        // show output
        showModal();
      }
    };
    reader.readAsText(file);

    // Prevent upload
    return false;
  }

  // clear useStates
  function removeTxt() {
    setAccepted([]);
    setRejected([]);
    setRequestData(_.omit(requestData, ["barcodes"]));
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  // modal functions

  const handleOk = () => {
    setIsModalVisible(false);
    const data = {
      acceptedBarcodes: accepted,
      rejectedBarcodes: rejected,
    };

    setRequestData({
      ...requestData,
      barcodes: data.acceptedBarcodes,
    });
  };

  function modalContent() {
    return (
      <div>
        <h4>Kontrolden geçen karekodların İTS doğrulaması için devam ediniz.</h4>
        <p>Karekodların kontrol kriterleri aşağıdaki gibidir;</p>
        <ul>
          <li>Karekod formatı</li>
          <li>Ürün-GTIN eşleşmesi</li>
        </ul>
        {accepted.length > 0 && (
          <>
            <Divider />
            <h3>Karekod Kontrolünü Geçen Karekodlar</h3>
            <div
              style={{
                fontSize: "13px",
                borderRadius: "8px",
                backgroundColor: "#82ce34",
                padding: "10px",
                color: "#444",
                maxHeight: "350px",
                overflow: "auto",
              }}
            >
              {accepted.map((barcode) => {
                return <p key={uuidv4()}>{barcode}</p>;
              })}
            </div>
          </>
        )}

        {rejected.length > 0 && (
          <>
            <Divider />
            <h3>Karekod Kontrolü Sırasında Reddedilen Karekodlar</h3>
            <div
              style={{
                fontSize: "13px",
                borderRadius: "8px",
                backgroundColor: "#f15e5e",
                padding: "10px",
                color: "#444",
                maxHeight: "350px",
                overflow: "auto",
              }}
            >
              {rejected.map((barcode) => {
                return <p key={uuidv4()}>{barcode}</p>;
              })}
            </div>
          </>
        )}
      </div>
    );
  }

  // example txt
  function ExampleTxtDownload() {
    return (
      <Link
        className="exapmletxt--link"
        to="./files/barkod_yukleme_ornek_dosya.txt"
        target="_blank"
        download
      >
        Örnek .txt formatı için tıklayınız.
      </Link>
    );
  }

  return (
    <div className="TextReader">
      <Dragger
        accept=".txt"
        name="file"
        multiple={false}
        beforeUpload={readText}
        onRemove={removeTxt}
        onPreview={showModal}
        maxCount={1}
        listType="picture"
        className="TextReader--dragger "
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Yüklemek İçin Sürükle Bırak veya Tıkla Seç
        </p>
        <p className="ant-upload-hint">
          Tek dosya olarak sadece karekodların bulunduğu bir yazı dosyası(.txt)
          yüklenmelidir.
        </p>
      </Dragger>
      <Modal
        title="Yükleme Sonuç Raporu"
        visible={isModalVisible}
        onOk={handleOk}
        maskClosable={false}
        closable={false}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        {modalContent()}
      </Modal>

      <ExampleTxtDownload />
    </div>
  );
}

export default ReadTextFromTxt;
