import { getErrorMessage } from '@src/utils/errors';
import { axios_delete, axios_patch, axios_post } from '@utils/rest_axios';
import { Table, message } from 'antd';
import { useEffect, useState } from 'react';
import ApplicationDetailModal from '../ApplicationDetailModal/ApplicationDetailModal';
import { useConfirmationDialog } from '../ConfirmationPopup/ConfirmationDialog';
import { useNotificationPopup } from '../NotificationPopup/NotificationPopup';
import './TableComponent.scss';
import TableDecideButtons from './TableDecideButtons/TableDecideButtons';
import TableRejectPopup from './TableRejectPopup/TableRejectPopup';
function TableComponent({
  columns,
  data_arr,
  detailCardType,
  page_type,
  moduleType,
  fetchRequests,
  requestLoading,
  filterParameters
}) {
  // for Rows Rejected or Accepted
  const selectionType = 'checkbox';
  const [isBulkUpdateEnable, setIsBulkUpdateEnable] = useState(false);
  const [selectedRequestData, setSelectedRequestData] = useState([]);
  const [selectedRowRequestIds, setSelectedRowRequestIds] = useState([]);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  // for RowDetailCard
  const [currentRequest, setCurrentRequest] = useState([]);
  const [rowModalVisible, setRowModalVisible] = useState(false);

  const { getNotification } = useNotificationPopup();
  const { getConfirmation } = useConfirmationDialog();

  // If table data change then remove all selected rows
  useEffect(() => {
    setSelectedRowRequestIds([]);
  }, [data_arr]);

  const rowSelection = {
    selectedRowKeys: selectedRowRequestIds,
    type: selectionType,
    onChange: (selectedRowKeys, selectedRows) => {
      if (isBulkUpdateEnable && selectedRowKeys.length === 0) {
        setIsBulkUpdateEnable(false);
        setSelectedRowRequestIds([]);
      } else {
        // for selected req's ids
        setSelectedRequestData(selectedRows);
        // for checking
        setSelectedRowRequestIds(selectedRowKeys);
      }
    },

    selections: [
      {
        key: 'bulkUpdate',
        text: `Tüm Sonuçları Seç. (${data_arr?.count} Adet Başvuru)`,
        onSelect: (changeableRowKeys) => {
          if (
            !filterParameters.statuses ||
            (!filterParameters.statuses.includes('PENDING') &&
              !filterParameters.statuses.includes('PROBLEM')) ||
            filterParameters.statuses.includes('ACCEPTED') ||
            filterParameters.statuses.includes('REJECTED')
          ) {
            message.error(
              'Toplu bildirim için eksik/yanlış filtre değeri! Yalnızca "Doğrulandı" statüsündeki başvurulara bildirim gönderilebilir. Lütfen kontrol ediniz!',
              5
            );
          } else {
            setIsBulkUpdateEnable(true);
            setSelectedRowRequestIds(changeableRowKeys);
          }
        }
      },
      {
        key: 'currentPage',
        text: `Mevcut Sayfayı Seç (${data_arr.rows?.length} Adet Başvuru)`,
        onSelect: (changeableRowKeys) => {
          const filterSelectedRowData = data_arr.rows?.filter((row) => {
            return changeableRowKeys.includes(row.requestId);
          });

          setSelectedRequestData(filterSelectedRowData);
          setSelectedRowRequestIds(changeableRowKeys);
        }
      }
    ]
  };

  /**
   * Requests Bulk Update
   */
  const handleRequestsBulkUpdate = async (status) => {
    try {
      if (status === 'ACCEPTED') {
        const confirmed = await getConfirmation({
          title: `Seçili ${data_arr?.count} eczane başvurusuna onay bildirimi gönderilecek!`,
          message: '',
          cancelText: 'Vazgeç',
          confirmText: 'Tamam'
        });

        if (confirmed) {
          const requestsBulkUpdateRes = await axios_patch(
            `${
              page_type === 'ADMIN'
                ? '/admin/pharmacy-requests-bulk'
                : '/companypages/pharmacy-requests-bulk'
            }`,
            {
              ...filterParameters,
              status: 'ACCEPTED',
              moduleType: moduleType,
              message: {
                title: `Başvurunuz Onaylandı!`,
                context: 'Başvurunuz onaylandı. İyi günler dileriz.'
              }
            }
          );
          if (requestsBulkUpdateRes.status === 200) {
            message.success(
              'Seçili istekler başarıyla onaylandı! Onay bildirimi ilgili Eczaneye gönderildi! Şimdi sayfa yenilenecek!',
              5
            );
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          }
        }
      }
      if (status === 'REJECTED') {
        showModal();
      }
    } catch ({ response }) {
      if (response && response.status !== 401) {
        getNotification({
          ...getErrorMessage(response)
        });
      }
    }
  };
  const handleRequestsBulkRejectConfirm = async (rejectedMessageData) => {
    const requestsBulkRejectRes = await axios_patch(
      `${
        page_type === 'ADMIN'
          ? '/admin/pharmacy-requests-bulk'
          : '/companypages/pharmacy-requests-bulk'
      }`,
      {
        ...filterParameters,
        status: 'REJECTED',
        moduleType: moduleType,
        message: {
          title: `Başvurunuz Reddedildi!`,
          context:
            rejectedMessageData?.rejectedMessage || `Başvurunuz reddedilmiştir!`
        }
      }
    );
    if (requestsBulkRejectRes.status === 200) {
      message.error(
        'Ret bildirimi başarılı! İlgili eczaneye ret bildirimi gönderildi. Şimdi sayfa yenilenecek!',
        5
      );
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }
  };

  /**
   * Requests Accept
   */
  const handlePostSelectedRowsAccepted = async () => {
    if (selectedRequestData.length === 0) {
      message.error(
        'Lütfen işlem yapılmamış talepleri seçiniz .Seçili talepler arasında daha önce işlem yapılmış talep bulunmaktadır.',
        5
      );
    } else {
      if (areRowsNotRejectedOrAccepted(selectedRequestData)) {
        const confirmed = await getConfirmation({
          title: `Seçili ${selectedRequestData.length} eczane başvurusuna onay bildirimi gönderilecek!`,
          message: '',
          cancelText: 'Vazgeç',
          confirmText: 'Tamam'
        });

        if (confirmed) {
          const data = {
            status: 'ACCEPTED',
            requestIds: selectedRowRequestIds,
            message: {
              title: `Başvurunuz Onaylandı!`,
              context: 'Başvurunuz Onaylandı. İyi Günler Dileriz.'
            }
          };
          handleBackendCommunication(data);
        }
      } else {
        message.error(
          'Lütfen işlem yapılmamış talepleri seçiniz. Seçili talepler arasında daha önce işlem yapılmış talep bulunmaktadır.',
          5
        );
      }
    }
  };

  async function handleBackendCommunication(data) {
    try {
      const acceptRequestsUpdateRes = await axios_patch(
        `/pharmacy-requests`,
        data
      );
      if (acceptRequestsUpdateRes.status === 200) {
        message.success(
          'Seçili istekler başarıyla onaylandı! Onay bildirimi ilgili eczaneye gönderildi! Şimdi sayfa yenilenecek!',
          5
        );
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      }
    } catch ({ response }) {
      if (response && response.status !== 401) {
        getNotification({
          ...getErrorMessage(response)
        });
      }
    }
  }

  /**
   * Requests Rejection
   */
  const handlePostSelectedRowsRejected = () => {
    if (selectedRequestData.length > 0) {
      if (areRowsNotRejectedOrAccepted(selectedRequestData)) {
        showModal();
      } else {
        message.error(
          'Lütfen işlem yapılmamış talepleri seçiniz. Seçili talepler arasında daha önce işlem yapılmış talep bulunmaktadır.',
          5
        );
      }
    }
  };

  const showModal = () => {
    setIsRejectModalVisible(true);
  };

  async function handleRequestRejectConfirm(rejectedMessageData) {
    const data = {
      status: 'REJECTED',
      requestIds: selectedRowRequestIds,
      message: {
        title: `Başvurunuz Reddedildi!`,
        context:
          rejectedMessageData?.rejectedMessage || `Başvurunuz reddedilmiştir!`
      }
    };

    try {
      const rejectRequestsUpdateRes = await axios_patch(
        `/pharmacy-requests`,
        data
      );
      if (rejectRequestsUpdateRes.status === 200) {
        message.error(
          'Ret bildirimi başarılı! İlgili eczaneye ret bildirimi gönderildi. Şimdi sayfa yenilenecek!',
          5
        );
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      }
    } catch ({ response }) {
      if (response && response.status !== 401) {
        getNotification({
          ...getErrorMessage(response)
        });
      }
    }
  }

  /**
   * Request Deletion
   */

  async function handleDeleteSelectedRows() {
    if (selectedRequestData.length === 1) {
      const requestId = selectedRowRequestIds[0];
      const confirmed = await getConfirmation({
        title: `${requestId} Numaralı İstek Silinecek`,
        message: '',
        cancelText: 'Vazgeç',
        confirmText: 'Onayla'
      });
      if (confirmed) {
        try {
          await axios_delete(`/admin/requests/`, requestId);
          message.success('İstek Silme Başarılı! Şimdi sayfa yenilenecek!', 5);
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        } catch ({ response }) {
          message.error('İstek Silinemedi', 5);
        }
      }
    } else {
      message.error(
        'Lütfen istekleri tek tek siliniz. Sadece tek bir işlem seçilmelidir.',
        5
      );
    }
  }

  /**
   * Restart all pending requests
   */
  async function handleAllPendingRequests() {
    try {
      // eslint-disable-next-line no-unused-vars
      const pendingRequestsResponse = await axios_post(
        `/admin/pending-requests`
      );
    } catch ({ response }) {
      message.error('İstek başarısız oldu!', 5);
    }
  }

  /**
   * Decide Accept or Reject for PATCH
   */

  function areRowsNotRejectedOrAccepted(selectedReqRows) {
    const filtered = selectedReqRows.filter(
      (request) =>
        request.requestStatus !== 'REJECTED' &&
        request.requestStatus !== 'ACCEPTED'
    );
    return filtered.length > 0 ? true : false;
  }

  function handleRowClick(rowData) {
    setCurrentRequest(rowData);
    setRowModalVisible(true);
  }
  return (
    <>
      <div className="tablecomponent">
        <div className={`${moduleType !== 'KKI' ? 'tableDecideButtons' : ''}`}>
          <TableDecideButtons
            detailCardType={detailCardType}
            filterParameters={filterParameters}
            allRequestsCount={data_arr.count}
            isBulkUpdateEnable={isBulkUpdateEnable}
            handleRequestsBulkUpdate={handleRequestsBulkUpdate}
            setSelectedRowRequestIds={setSelectedRowRequestIds}
            setIsBulkUpdateEnable={setIsBulkUpdateEnable}
            handlePostSelectedRowsAccepted={handlePostSelectedRowsAccepted}
            handlePostSelectedRowsRejected={handlePostSelectedRowsRejected}
            handleDeleteSelectedRows={handleDeleteSelectedRows}
            handleAllPendingRequests={handleAllPendingRequests}
          />
        </div>
        <Table
          rowKey="requestId"
          rowSelection={data_arr.rows ? rowSelection : undefined}
          columns={columns}
          dataSource={data_arr.rows}
          loading={requestLoading}
          onRow={(rowData) => {
            return {
              onClick: () => handleRowClick(rowData),
              style: { cursor: 'pointer' }
            };
          }}
          pagination={{
            simple: true,
            total: data_arr.count,
            onChange: async (page) => {
              fetchRequests(undefined, page - 1);
            },
            defaultPageSize: 100
          }}
        />
        <TableRejectPopup
          handleRequestRejectConfirm={
            isBulkUpdateEnable
              ? handleRequestsBulkRejectConfirm
              : handleRequestRejectConfirm
          }
          isModalVisible={isRejectModalVisible}
          setIsModalVisible={setIsRejectModalVisible}
          requestsCount={
            isBulkUpdateEnable ? data_arr?.count : selectedRowRequestIds.length
          }
        />
      </div>

      {rowModalVisible && (
        <ApplicationDetailModal
          application={currentRequest}
          isModalVisible={rowModalVisible}
          onCloseModal={setRowModalVisible}
          pageType={page_type}
          moduleType={moduleType}
        />
      )}
    </>
  );
}

export default TableComponent;
