import StatsCard from '@src/components/shared/StatsCard/StatsCard';

import kemi_dashboard_img from '../../../assets/img/kemi_dashboard_img.png';

export const TotalUserStatCard = ({ stats }) => {
  return (
    <StatsCard title="Üye Eczane Sayısı" type="small" icon={kemi_dashboard_img}>
      <h1 className="userNumberHeader">
        <b className="memberNumber">{stats.numberOfPharmacies}</b>
      </h1>
    </StatsCard>
  );
};
