import { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

export const HelpMenu = () => {
  const location = useLocation();

  useEffect(() => {}, [location.pathname]);

  return (
    <div>
      <NavLink to="/qa">
        <span className={decideActive(location.pathname, '/qa')}>
          Soru &#38; Cevap
        </span>
      </NavLink>
      <NavLink to="/guide">
        <span className={decideActive(location.pathname, '/guide')}>
          Kemi Kullanım Videosu
        </span>
      </NavLink>
      {/* <NavLink to="/rehber">
        <span className={decideActive(location.pathname, '/rehber')}>
          Kemi Kullanım Turu
        </span>
      </NavLink> */}
    </div>
  );
};

function decideActive(path, key) {
  return path === key ? 'kemi_info_btn_purple_active' : 'kemi_info_btn_purple';
}
