import { Layout } from 'antd';
import './Layouts.scss';
// components
import Footer from '@src/components/public_components/Footer';
import UserContextProvider from '@src/components/shared/UserContextProvider/UserContextProvider';
import { FloatButton } from 'antd';
import LayoutHeader from '../components/shared/LayoutHeader/LayoutHeader';
import Navbar from '../components/shared/Navbar/Navbar';
import './Layouts.scss';

const CompanyLayout = ({ children }) => {
  return (
    <UserContextProvider>
      <Layout className="layout">
        <Navbar />
        <Layout className="layout__background">
          <LayoutHeader />
          <div id="page-container">
            {children}
            <FloatButton.BackTop />
          </div>
          <Footer isAuthenticated={true} />
        </Layout>
      </Layout>
    </UserContextProvider>
  );
};

export default CompanyLayout;
