import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import { axios_get } from '@utils/rest_axios';
import { Card } from 'antd';
import { useEffect, useState } from 'react';
import './SelectCompanies.scss';

const SelectCompanies = ({ setRequestData, requestData, setCurrentStep }) => {
  const [companies, setCompanies] = useState([]);
  const { getNotification } = useNotificationPopup();

  useEffect(() => {
    async function getCompanies() {
      try {
        const companiesRes = await axios_get('/companies');
        if (companiesRes.status === 200) {
          setCompanies(companiesRes.data);
        }
      } catch ({ response }) {
        if (response && response.status !== 401) {
          getNotification({
            ...getErrorMessage(response)
          });
        }
      }
    }
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="selectCompanies">
        {companies.length > 0 &&
          companies.map((company) => {
            return (
              <div key={company.companyId} className="selectCompanies__company">
                <Card
                  hoverable
                  className="selectCompanies__company__card"
                  onClick={() => {
                    setRequestData({
                      ...requestData,
                      companyId: company.companyId,
                      companyName: company.name
                    });
                    setCurrentStep(1);
                  }}
                >
                  {company.name}
                </Card>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SelectCompanies;
