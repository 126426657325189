import { FormOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useState } from 'react';
import { ModalContent } from './UpdateUserForm';

function UserUpdate({ user, setRefresh }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  function closeModal() {
    setIsModalVisible(false);
  }

  function showModal() {
    setIsModalVisible(true);
  }
  return (
    <>
      <FormOutlined onClick={showModal} />
      <Modal
        title="Kullanıcı Güncelleme"
        visible={isModalVisible}
        onCancel={closeModal}
        maskClosable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <ModalContent
          user={user}
          closeModal={closeModal}
          setRefresh={setRefresh}
        />
      </Modal>
    </>
  );
}

export default UserUpdate;
