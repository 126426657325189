import Chart from 'react-apexcharts';

import tr from './ApexChartLocalization.json';

const ApexChart = ({ options, series }) => {
  // Turkish localization
  options.chart = {
    locales: [tr],
    defaultLocale: 'tr',
  };

  options.colors = ['#6259ca', '#a29ece'];

  return (
    <>
      <div className="row">
        <div className="mixed-chart">
          <Chart options={options} series={series} type="bar" />
        </div>
      </div>
    </>
  );
};

export default ApexChart;
