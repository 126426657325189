import { getErrorMessage } from '@src/utils/errors';
import { axios_get } from '@src/utils/rest_axios';
import { createContext, useEffect, useState } from 'react';
import { useNotificationPopup } from '../NotificationPopup/NotificationPopup';

const PharmacyNotificationContext = createContext();

const PharmacyNotificationContextProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const { getNotification } = useNotificationPopup();

  useEffect(() => {
    async function getNotifications() {
      try {
        const result = await axios_get('/pharmacypages/notifications');
        setNotifications(result.data);
      } catch ({ response }) {
        if (response && response.status !== 401) {
          getNotification({
            ...getErrorMessage(response),
          });
        }
      }
    }
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PharmacyNotificationContext.Provider
      value={{ notifications, setNotifications }}
    >
      {children}
    </PharmacyNotificationContext.Provider>
  );
};

export default PharmacyNotificationContextProvider;
export { PharmacyNotificationContext };
